export default {
    setCurrentOption({ commit }, option) {
        commit('setCurrentOption', option);
    },
    setRoofType({ commit }, type) {
        commit('setRoofType', type)
    },
    setRoofColor({ commit }, color) {
        commit('setRoofColor', color)
    },
    setWallColor({ commit }, color) {
        commit('setWallColor', color)
    },
    setRWSColor({ commit }, color) {
        commit('setRWSColor', color)
    },
    setHouse({ commit }, house) {
        commit('setHouse', house)
    },
    setSelectedProductType({ commit }, productType) {
        commit('setSelectedProductType', productType)
    },
    setRoof({ commit }, roof) {
        commit('setRoof', roof)
    }
}
