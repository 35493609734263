
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    includePanel: true,
    roofSlope: 45,
    roofDirection: "s",
    annualHouseholdConsumption: 20000,
    electricityPrice: 0.25,
    inverterPower: "",
    panelPower: 450,
    nrOfPanels: "",
    areaNeededForPanels: "",
    ownConsumption: 40,
    governmentSupport: 0,
    userInput: {
        inverterPower: undefined,
        nrOfPanels: undefined
    },
    divisionOfProduction: {
        investmentNeeded: "-",
        annualConsumptionCost: "-",
        annualConsumptionCostWithPanel: "-",
        roi: '-',
        roiInYears: '-'
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
