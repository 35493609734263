<template>
    <div class="elering-section container-fluid py-6 px-4 shadow-md mt-6">
        <div class="elering-header flex flex-row items-center cursor-pointer" :class="showFormFields ? 'expand' : 'collapse'" @click="expandOrCollapseFields">
            <div class="form-completion-indicator flex items-center justify-center relative mr-3">
                <i class="pi pi-check"></i>
            </div>
            <p class="subheading uppercase mr-3">{{ title }}</p>
            <i class="pi pi-chevron-down ml-auto" />
        </div>

        <div v-if="showFormFields" class="content mt-4">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'EleringFormItem',
    props: {
        title: {
            type: String,
            required: true,
        },
        open: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return  {
            showFormFields: false,
        }
    },
    created() {
        this.showFormFields = this.open;
    },
    methods: {
        expandOrCollapseFields() {
            this.showFormFields = !this.showFormFields;
        },
    },
}
</script>

<style lang="scss" scoped>
.elering-section {
    border-radius: 4px;

    &.form-valid {
        .elering-header {
            .form-completion-indicator {
                background-color: #4BAF4F;
            }
            p {
                color: #4BAF4F;
            }
        }
    }
}

.form-completion-indicator {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #777777;

    .pi-check {
        font-size: 7px;
        font-weight: bold;
        color: #ffffff;
    }
}

.collapse{
    :deep(.pi-chevron-down) {
        transform: rotate(0deg);
        transition: transform .2s linear;
    }
}

.expand {
    :deep(.pi-chevron-down) {
        transform: rotate(-180deg);
        transition: transform .2s linear;
    }
}
</style>
