import { createRouter, createWebHistory } from "vue-router"
import i18n from '@/translations/'

import Home from "@/pages/homepage"
import ThankYouPage from "@/pages/thank-you-page"
import LanguageSupport from "@/pages/language-support"
import ApplyPage from "@/pages/apply-page"

const routes = [
    {
        path: '/',
        // eslint-disable-next-line no-unused-vars
        redirect: () => {
            let locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'et';
            i18n.locale = locale;
            return locale;
        },
    },
    {
        path: '/:locale',
        component: LanguageSupport,
        children: [
            {
                path: "",
                name: "homepage",
                component: Home,
                meta: {
                    pageType: "landingPage",
                },
            },
            {
                path: ":productType",
                name: "product-page",
                component: Home,
            },
            {
                path: "thank-you",
                name: "thank-you",
                component: ThankYouPage,
                meta: {
                    pageType: "thankYouPage",
                    cssClass: "thank-you-page"
                },
            },
            {
                path: "liitu",
                name: "apply",
                component: ApplyPage,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior() {
        return { top: 0 }
    },
    routes
});

router.beforeEach((to, from, next) => {
    if (to.params.locale === from.params.locale) {
        next()
        return
    }
  
    const { locale } = to.params
    const supported_locales = ['en', 'et']
    if (!supported_locales.includes(locale)) return next('et');
    if (i18n.locale !== locale) {
        i18n.locale = locale;
        localStorage.setItem('locale', locale);
    }
    // return next()
    next()
  })

export default router;
