<template>
    <div class="steps-wrapper flex flex-row justify-between py-4 px-6">
        <div v-for="step in accessedSteps" :key="step.index" class="steps-item flex items-center gap-4" :class="{ 'current': currentStep === step.index, 'complete': step.complete === true }">
            <div class="step-counter flex items-center justify-center relative">
                <h3 v-if="step.complete" class="heading"><i class="pi pi-check"></i></h3>
                <h3 v-else class="heading">{{ step.index }}</h3>
            </div>
            <p class="step-title text-medium hidden md:block">{{ step.title }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    props: {
        currentStep: {
            type: Number,
            default: 0,
        },
        steps: {
            type: Array,
            default: () => [],
        }
    },
    computed: {
        accessedSteps() {
            return this.steps.filter((step) => {
                if (!step.accessibleFor || step.accessibleFor.includes(this.$i18n.locale)) {
                    return step;
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.steps-wrapper {
    background-color: #f0f0fa;
    border-radius: 4px;
}

.steps-item.current {
    .step-title {
        color: #5D5FCB;
        font-family: Roboto SemiBold;
    }
    .step-counter {
        color: #ffffff;
        background-color: #5D5FCB;
        border-color: #5D5FCB;
    }
    &:not(:last-child):after {
        background-color: #5D5FCB;
    }
}

.steps-item.complete {
    .step-title {
        color: #4BAF4F;
    }
    .step-counter {
        color: #ffffff;
        background-color: #4BAF4F;
        border-color: #4BAF4F;
    }
    &:not(:last-child):after {
        background-color: #4BAF4F;
    }
}

.step-counter {
    border-radius: 50%;
    border: 1px solid #777777;
    width: 48px;
    height: 48px;
    color: #777777;
    background-color: transparent;
}

.steps-item:not(:last-child) {
    flex: 1;
}

.steps-item:not(:last-child):after {
    content: "";
    height: 4px;
    border-radius: 4px;
    margin-right: 1rem;
    display: flex;
    flex: 1;
    background-color: #777777;
}
</style>
