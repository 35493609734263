<template>
    <div class="container-fluid w-full py-6 mx-auto">
        <div class="rqf-description mb-4">
            <h4 class="heading mb-4">{{ $t('rfqForm.receiveQuote.title') }}</h4>
            <p>{{ $t('rfqForm.receiveQuote.subtitle') }}</p>
        </div>
        <div class="rfq-form-wrapper">
            <div class="flex flex-col mb-6 gap-2">
                <label class="subheading" for="name">{{ $t('generic.name') }}</label>
                <InputText
                    id="name"
                    type="text"
                    :placeholder="$t('generic.fullName')"
                    v-bind:class="{
                    'p-invalid': contactName == '' && validationStatus == 'error',
                    }"
                    :modelValue="contactName"
                    @update:modelValue="updateContactName"
                />
            </div>

            <div class="grid grid-cols-2 gap-4">
                <div class="flex flex-col mb-6 gap-2">
                    <label class="subheading" for="email">{{ $t('generic.email') }}</label>
                    <InputText
                        id="email"
                        type="text"
                        placeholder="Email"
                        v-bind:class="{
                        'p-invalid':
                            (contactEmail == '' || !validEmail(contactEmail)) &&
                            validationStatus == 'error',
                        }"
                        :modelValue="contactEmail"
                        @update:modelValue="updateContactEmail"
                    />
                </div>
                <div class="flex flex-col mb-6 gap-2">
                    <label class="subheading" for="phone">{{ $t('generic.phone') }}</label>
                    <InputText
                        id="phone"
                        type="text"
                        placeholder="Phone"
                        :class="{
                        'p-invalid':
                            (phone == '' || !validPhoneNr(phone)) &&
                            validationStatus == 'error',
                        }"
                        :modelValue="phone"
                        @update:modelValue="updateContactPhone"
                    />
                </div>
            </div>
            
            <div class="flex flex-col mb-6 gap-2">
                <label class="subheading" for="comments">{{ $t('generic.comments') }}</label>
                <Textarea
                    id="comments"
                    type="text"
                    :autoResize="true" rows="4" cols="30"
                    :placeholder="$t('interestForm.comments')"
                    :modelValue="comment"
                    @update:modelValue="updateQuoteComment"
                />
            </div>

            <div class="grid grid-cols-3 gap-2">
                <Button @click="goToPreviousStep" class="p-button-secondary col-span-1">
                    {{ $t('generic.back') }}
                </Button>
                <Button class="col-span-2" @click="validateForm">
                    <span v-if="!generatingPDF">{{ $t('generic.next') }}</span>
                    <div v-else class="lds-dual-ring"></div>
                </Button>
            </div>

            <modal v-if="show" @close="show = false">
                <template v-slot:header>
                <h2>{{ $t('modal.title') }}</h2>
                </template>

                <template v-slot:body>
                    <p>{{ $t('modal.description') }}</p>
                </template>

                <template v-slot:footer>
                    <div v-if="generatingPDF" class="loader-wrapper">
                        <div class="lds-dual-ring purple"></div>
                    </div>
                    <Button v-else class="mr-3" @click="show = !show" v-bind:class="{ hideText: generatingPDF }">
                        {{ $t('modal.done') }}
                    </Button>
                </template>
            </modal>
        </div>
        <div class="breakdown-wrapper">
            <house-breakdown ref="breakdownComponent" :key="houseBreakdownKey" @houseBreakdownMounted="onHouseBreakdownMounted"></house-breakdown>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Button from "primevue/button";
import HouseBreakdown from "@/components/house/pdf/HouseBreakdown.vue";
import * as PDFGenerationService from "@/services/pdf-generation";
import Modal from "@/components/quoteForm/Modal.vue";

export default {
    name: "InterestForm",
    components: {
        Modal,
        Button,
        InputText,
        Textarea,
        HouseBreakdown,
    },
    computed: {
        ...mapState('quoteFormModule', ['contactName', 'contactEmail', 'comment', 'phone', 'roofArea']),
        ...mapGetters('solarPanelCalculatorModule', ['calculated']),
        ...mapState('solarPanelCalculatorModule', ['includePanel']),
        ...mapGetters('quoteFormModule', [
            "address", 'latlng'
        ]),
        ...mapGetters('houseBuilderModule', ['includeRoof']),
    },
    data() {
        return {
            generatingPDF: false,
            show: false,
            validationStatus: "pending",
            houseBreakdownKey: 0,
            houseBreakdown: null,
        };
    },
    emits: ['nextStep', 'previousStep'],
    mounted() {
        // remount house-breakdown (debugging map address svg not updating)
        this.houseBreakdownKey++;
    },
    methods: {
        ...mapActions('quoteFormModule', [
            "setName",
            "setEmail",
            "setPhone",
            "setComment",
        ]),
        updateContactName(contactName) {
            this.setName(contactName);
        },
        updateContactEmail(contactEmail) {
            this.setEmail(contactEmail);
        },
        updateQuoteComment(comment) {
            this.setComment(comment);
        },
        updateContactPhone(phone) {
            this.setPhone(phone);
        },
        async validateForm() {
            if (
                this.contactName == "" ||
                this.contactEmail == "" ||
                !this.validEmail(this.contactEmail) ||
                !this.validPhoneNr(this.phone)
            ) {
                this.validationStatus = "error";
            } else {
                await this.generatePDF();
                this.validationStatus = "pending";
            }
        },
        validEmail(contactEmail) {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(contactEmail);
        },
        validPhoneNr(phone) {
            return /^\d+$/.test(phone);
        },
        goToPreviousStep() {
            this.$emit('previousStep');
        },
        onHouseBreakdownMounted() {
            // this.houseBreakdown = this.$refs.breakdownComponent.$refs.houseBreakdown;
        },
        getCurrentRouteName() {
            let routeName = this.$route.params.productType;
            return routeName = typeof routeName === 'string' ?
                routeName.replace(/-[a-z]/g, m => m.toUpperCase()).replaceAll("-", "")
                : null;
        },
        createEmailSubject() {
            let subject = "";
            const locale = this.$i18n.locale.toUpperCase();
            const interestSubject = this.$t('emailSender.interest.subject');

            if (this.getCurrentRouteName()) {
                const routeMap = {
                    roofWithPanels: "TERVIKLAHENDUS",
                    roof: "KATUS",
                    solarPanels: "PANEELID"
                };
                subject = `[${routeMap[this.getCurrentRouteName()]}] [${locale}] - ${interestSubject}`;
            } else {
                subject = `[${locale}] - ${interestSubject}`;
            }

            return subject
        },
        async generatePDF() {
            try {
                this.generatingPDF = true;
                this.show = true;
                // const houseBreakdown = this.$refs.breakdownComponent.$refs.houseBreakdown;
                const { breakdownComponent: { $refs: { houseBreakdown } = {} } = {} } =
                this.$refs;
                if (!houseBreakdown) {
                    return;
                }
                const pagesCollection = houseBreakdown.getElementsByClassName("page");
                if (!pagesCollection || !pagesCollection.length) {
                    return;
                }

                const pages = Array.from(pagesCollection);
                const includePanel = this.includePanel;
                const includeRoof = this.includeRoof;

                const pdfBlob = await PDFGenerationService.generateProjectBreakdownPDF({ pages, includePanel, includeRoof });
                
                var d = new Date();
                let fileName = "RFQ_" + this.contactName + "_" + d.getTime();

                const downloadURL = await PDFGenerationService.getDownloadUrl({ 
                    pdfBlob: pdfBlob,
                    fileName: fileName,
                })

                const subject = this.createEmailSubject();
                const messageBody = this.$t('emailSender.interest.messageBody', {
                    name: this.contactName, 
                    email: this.contactEmail,
                    address: this.address,
                    area: this.roofArea,
                    consumption: this.calculated.ownConsumptionPerHour,
                    comments: this.comment,
                    downloadURL: downloadURL,
                });

                const locale = this.$i18n.locale;
                const to = {
                    'PRODUCTION': process.env[`VUE_APP_CONTACT_EMAIL_${ locale.toUpperCase() }`],
                    'STAGING': process.env.VUE_APP_CONTACT_EMAIL_STAGING,
                    'DEV': process.env.VUE_APP_CONTACT_EMAIL_TEST,
                }[process.env.VUE_APP_ENV]

                this.generatingPDF = false;
                this.$emit('nextStep');
                
                await PDFGenerationService.sendLeadEmail({ to: to, subject: subject, messageBody: messageBody });
            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>

<style lang="scss">
.container-fluid {
    text-align: left;
}

@media (min-width: 1200px) {
    .rfq-form-wrapper {
        max-width: 550px;
    }
}

.loader-wrapper {
    position: relative;
    padding: 23px;
}

.hideText {
    color: #23246c !important;
    background: #23246c !important;
    transition: none;
}

.rainsystem-option-btn,
.slope-option-btn {
    transition: 0.3s;
}

.rainsystem-option-btn:hover,
.slope-option-btn:hover {
    border-color: #2f3192;
}

.rainsystem-option-btn:focus,
.slope-option-btn:focus {
    border-color: #2f3192;
    box-shadow: 0 0 0 0.2rem rgba(#2f3192, 0.4) !important;
}

.p-button:focus {
    box-shadow: none !important;
}

.rfq-form {
    margin-bottom: 68px;
}

@media (min-width: 1200px) {
    .row.rfq-form,
    .row.visual-editor {
        padding-left: 160px !important;
        padding-right: 160px !important;
    }
}

@media (max-width: 763px) {
    .rqf-description {
        text-align: center;
        margin-bottom: 32px;
    }
}

@media (max-width: 576px) {
    .rqf-description {
        text-align: center;
        margin-bottom: 32px;
    }
    .row.rfq-form,
    .row.visual-editor {
        margin-right: -64px;
        margin-left: -64px;
    }
    .bottom-border {
        border: none;
    }
    .p-button {
        width: 100%;
        margin: 0;
    }
    .navbar {
        padding: 14px 16px;
    }
}

.rfq-description {
    padding: 4rem 0px;
}

.section-separator {
    border-left: 1px solid #d2d2d2;
}

.breakdown-wrapper {
    display: flex;
    height: 0;
    overflow: hidden;
}

.rws-image {
    height: 90%;
    width: auto;
}

.lds-dual-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 48px;
    height: 48px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring.purple:after {
    border: 6px solid #2f3192;
    border-color: #2f3192 transparent #2f3192 transparent;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

button {
    justify-content: center;
}
</style>
