<template>
    <div class="product-option-card">
        <div v-if="title" class="title-wrapper">
            <h1 class="option-text">{{ $t(title) }}</h1>
        </div>
        <div v-if="href" class="option-image cursor-pointer" @click="openUrl()">
            <img v-if="img" :src="img" />
        </div>
        <div v-else class="option-image cursor-pointer" @click="$emit('changeProduct', productType)">
            <img v-if="img" :src="img" />
        </div>
    </div>
</template>

<script>
export default {
    name: "CardProductOption",
    props: {
        productType: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        img: {
            type: String,
            default: null,
        },
        href: {
            type: String,
            default: null
        },
    },
    emits: ['changeProduct'],
    methods: {
        openUrl() {
            window.open(this.href);
        }
    }
}
</script>

<style lang="scss" scoped>
// 1024px - 1439px
.product-option-card {
    flex: 1 1 auto;
    max-width: 370px;

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: "Poppins Bold" !important;
    }

    p {
        font-family: "Poppins" !important;
    }

    .option-image {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $neutral-white;
        margin-bottom: 43px;
        margin-top: 8px;
        max-height: 486px;
        height: auto;
        width: auto;
        object-fit: cover;

        img {
            box-shadow: 0 0px 8px 0px rgb(0 0 0 / 0.2);
            height: 100%;
            width: 100%;
            border-radius: 52px;
        }

        img:hover {
            box-shadow: 0 0px 8px 0px rgb(0 0 0 / 0.5);
        }
    }

    h1.option-text {
        font-size: 1.15rem !important;
    }

    .title-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        justify-content: center;
    }

    // 0px - 1023 px
    @media screen and (max-width: 1023px) {
        max-width: 252px;

        h1.option-text {
            font-size: 0.8rem !important;
        }
        .option-image {
            img {
                border-radius: 44px;
            }
        }
    }

    // 1440px+
    @media screen and (min-width: 1440px) {
        max-width: 430px;
        h1.option-text {
            font-size: 1.3rem !important;
        }
        .option-image {
            img {
                border-radius: 60px;
            }
        }
    }
}
</style>