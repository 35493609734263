import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
    houseTypes: ["type1", "type2", "type3", "type4"],
    selectedHouse: 0,
    selectedRoof: "classic",
    roofColor: 5,
    rwsColor: 2,
    wallColor: null,
    roofType: 0,
    solarPanel: false,
    currentOption: 0,
    selectedProductType: "", // roofWithPanels, roof, solarPanels,
    includeRoof: true,
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
