<template>
  <div></div>
  <ul class="navbar-nav hidden md:flex gap-5">
    <li class="nav-item">
      <a :href="$t('links.installations')" target="_blank" >
        {{$t('nav.installations')}}
      </a>
    </li>
    <li v-if="isEstonianLocale()" class="nav-item">
      <a :href="$t('links.payment')" target="_blank" >
        {{$t('nav.payment')}}
      </a>
    </li>
    <li class="nav-item">
      <a :href="$t('links.gallery')" target="_blank" >
        {{$t('nav.gallery')}}
      </a>
    </li>
    <li class="nav-item">
      <a :href="$t('links.products')" target="_blank" >
        {{$t('nav.products')}}
      </a>
    </li>
    <!-- <li class="nav-item">
      <a :href="$t('links.baseMaterial')" target="_blank" >
        {{$t('nav.baseMaterial')}}
      </a>
    </li>
    <li class="nav-item">
      <a :href="$t('links.techInfo')" target="_blank" >
        {{$t('nav.techInfo')}}
      </a>
    </li> -->
    <li class="nav-item">
      <a :href="$t('links.about')" target="_blank" > {{$t('nav.about')}} </a>
    </li>
    <li class="nav-item">
      <span>|</span>
    </li>
  </ul>
</template>

<script>

export default {
  methods: {
    isEstonianLocale() {
      return this.$i18n.locale == "et"
    }
  }
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.nav-item a {
    font-size: 18px;
    font-weight: 400;
}

</style>
