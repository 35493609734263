<template>
    <div class="apply-container">
        <header>
            <i class="icon icon-round">
                <Scroll color="primary-hover" />
            </i>
            <h1>{{ $t('application.title') }}</h1>
            <p>{{ $t('application.subtitle') }}</p>
        </header>
        <div class="form grid grid-cols-1 md:grid-cols-2 gap-10">
            <ApplyForm />
            <FAQSection />
        </div>
    </div>
</template>

<script>
import Scroll from "@/components/icons/Scroll";
import ApplyForm from "@/pages/apply-page/ApplyForm";
import FAQSection from '@/components/quoteForm/steps/FAQSection';

export default {
    name: "ApplyPage",
    components: { Scroll, ApplyForm, FAQSection }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";

.apply-container {
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    
    .form {
        max-width: 1140px !important;
        margin-left: auto;
        margin-right: auto;
    }

    header {
        margin-top: 89px;
        padding-top: 104px;
        padding-bottom: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(180deg, rgba(93, 95, 203, 0.08) 0%, rgba(196, 196, 196, 0) 100%);
        
        h1,
        p {
            color: $primary-main;
            text-align: center;
        }

        h1 {
            margin: 48px auto;
        }
    }

    .icon {
        border: 1px solid $primary-main;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon-round {
        border-radius: 40px;
    }

}
</style>