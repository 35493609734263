import * as constants from './constants';
import i18n from '@/translations/'

const ELECTRICITY_PRICE_PER_KW = 1200;

const getters = {
    includePanel: (state) => { return state.includePanel },
    actualElectricityPrice(state) {
        return parseFloat(state.electricityPrice) - 0.066
    },
    getRoofSlope: (state) => { return state.roofSlope },
    getRoofDirection: (state) => { return state.roofDirection },
    getElectricityPrice: (state) => { return state.electricityPrice },
    getAnnualHouseholdConsumption: (state) => { return state.annualHouseholdConsumption },
    getPanelPower: (state) => { return state.panelPower },
    getInverterPower(state) {
        if (state.userInput.inverterPower) {
            return state.userInput.inverterPower;
        }
        return calculateInverterPower(state.annualHouseholdConsumption);
    },
    getNrOfPanels: (state) => {
        if (state.userInput.nrOfPanels) {
            return state.userInput.nrOfPanels;
        }
        return Math.ceil(getters.getInverterPower(state) * 1000 / state.panelPower);
    },
    getArea: (state) => {
        if (!getters.getNrOfPanels(state)) {
            return "-";
        }
        return getters.getNrOfPanels(state) * 1 * 2;
    },
    getRoofSlopeImpact: (state) => { return constants.SLOPE_IMPACT_MAP[state.roofSlope] },
    getRoofDirectionImpact: (state) => { return constants.DIRECTION_IMPACT_MAP[state.roofDirection] },
    getOwnConsumption: (state) => { return state.ownConsumption },
    actualPowerOutput(state) {
        return Math.round(getters.getInverterPower(state) * getters.getRoofSlopeImpact(state) * getters.getRoofDirectionImpact(state) * 100) / 100;
    },
    calculated(state, getters) {
        const salesBackToNet = 100 - state.ownConsumption;
        const ownConsumptionPerHour = Math.round(getters.actualPowerOutput * state.ownConsumption * 1000) / 100;
        const salesBackToNetPerHour = Math.round(getters.actualPowerOutput * salesBackToNet * 1000) / 100;
        const totalPerHour = ownConsumptionPerHour + salesBackToNetPerHour;
        const ownConsumptionPrice = Math.round(ownConsumptionPerHour * state.electricityPrice * 100) / 100;
        let salesBackToNetPrice = Math.round(salesBackToNetPerHour * getters.actualElectricityPrice * 100) / 100;

        if (['lt', 'lv'].includes(i18n.locale)) {
            salesBackToNetPrice = 0;
        }
        const totalPrice = Math.round((ownConsumptionPrice + salesBackToNetPrice) * 100) / 100;
        return {
            ownConsumption: state.ownConsumption,
            salesBackToNet: salesBackToNet,
            total: 100,
            ownConsumptionPerHour: ownConsumptionPerHour,
            salesBackToNetPerHour: salesBackToNetPerHour,
            totalPerHour: totalPerHour,
            ownConsumptionPrice: ownConsumptionPrice,
            salesBackToNetPrice: salesBackToNetPrice,
            totalPrice: totalPrice,
        }
    },
    divisionOfProduction(state, getters) {
        let investmentNeeded = getters.getInverterPower * ELECTRICITY_PRICE_PER_KW;
        const annualConsumptionCost = Math.ceil(getters.getAnnualHouseholdConsumption * getters.getElectricityPrice)
        const annualConsumptionCostWithPanel = Math.ceil(annualConsumptionCost - ((getters.calculated.ownConsumption / 100) * getters.actualPowerOutput * 1000 * getters.getElectricityPrice))
        const billAfterInvestment = Math.ceil(annualConsumptionCostWithPanel - getters.calculated.salesBackToNetPrice)
        const profitInYear = annualConsumptionCost - billAfterInvestment

        let totalInvestment = investmentNeeded;

        if (['lt', 'lv'].includes(i18n.locale)) {
            // investmentNeeded -= state.governmentSupport;
            if (state.governmentSupport > totalInvestment) {
                totalInvestment = 0;
            } else {
                totalInvestment -= state.governmentSupport;
            }
        }

        let roiInYears = (Math.round(totalInvestment / getters.calculated.totalPrice * 100) / 100).toFixed(1)
        let roi = (Math.round(1 / parseFloat(roiInYears) * 100 * 100) / 100).toFixed(1)

        return {
            investmentNeeded: totalInvestment,
            annualConsumptionCost: annualConsumptionCost,
            annualConsumptionCostWithPanel: annualConsumptionCostWithPanel,
            billAfterInvestment: billAfterInvestment,
            profitInYear: profitInYear,
            roiInYears: roiInYears,
            roi: roi,
        }
    },
    isInverterPowerValid: (state) => { return getters.getInverterPower(state) != null }
}

function calculateInverterPower(ahc) {
    if (10000 <= ahc && ahc <= 12000) {
        return 6;
    } else if (12000 < ahc && ahc <= 15000) {
        return 8;
    } else if (15000 < ahc && ahc <= 18000) {
        return 10;
    } else if (18000 < ahc && ahc <= 22000) {
        return 12;
    } else if (22000 < ahc && ahc <= 30000) {
        return 15;
    } else {
        return 18;
    }
}

export default getters;
