const metaInfo = {
  metaInfo() {
    return {
      title: `Toode - ${this.title}`,
      meta: [
        {
          name: "description",
          content: this.meta.description,
        },
        // any other metas
      ],
    };
  },
}

export default metaInfo;
