<template>
    <div class="delete-button-wrapper flex cursor-pointer">
        <i class="pi pi-times"></i>
    </div>
</template>

<script>
export default {
    name: "DeleteButton",
}
</script>

<style lang="scss" scoped>
.delete-button-wrapper {
    border: 1px solid #777777;
    border-radius: 50%;
    padding: 8px;
    width: fit-content;
    height: fit-content;

    i {
        color: #777777;
    }

    &:hover {
        border-color: #5D5FCB;
        background: #D1D2F0;
        transition: .3s;

        i {
            color: #5D5FCB;
        }
    }
}
</style>
