import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store"
import PrimeVue from 'primevue/config'
// import './services/gtm.js'
// import { createI18n } from 'vue-i18n/dist/vue-i18n.esm-bundler.js'

import translations from './translations';

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
// import 'primeflex/primeflex.css'

import Echarts from 'vue-echarts'
import { use } from "echarts/core"
import { GaugeChart } from "echarts/charts"
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart } from "echarts/charts";
import VueGoogleMaps from '@fawmi/vue-google-maps'

import * as onLoad from 'vue-onload'

// import dotenv from 'dotenv'

require('dotenv').config()

import firebase from 'firebase';
require('firebase/firestore');
require("firebase/functions");

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID
};
firebase.initializeApp(firebaseConfig);

use([
    CanvasRenderer,
    GaugeChart,
    PieChart
])

const app = createApp(App)
app.use(PrimeVue)
app.use(router)
app.use(translations)
app.use(store)
app.use(firebase)
app.use(onLoad.plugin)
app.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_FIREBASE_API_KEY,
        libraries: "places",
        // language: 'de',
    }
})
app.component('chart', Echarts);

app.mount('#app')
