export default {
    setIsLiableForApplication(state, isLiable) {
        state.isLiableForApplication = isLiable;
    },
    setApplicantsIdCode(state, idCode) {
        state.applicantData.idCode = idCode;
    },
    setApplicantsContactAddress(state, address) {
        state.applicantData.contactAddress = address;
    },
    setRepresentsItself(state, representsItself) {
        state.applicantData.representsItself = representsItself;
    },
    setIsRegisteredAtCenter(state, isRegisteredAtCenter) {
        state.locationDetails.isRegisteredAtCenter = isRegisteredAtCenter;
    },
    setCadastralUnitName(state, cadastralUnitName) {
        state.locationDetails.cadastralUnitName = cadastralUnitName;
    },
    setCadastralId(state, cadastralId) {
        state.locationDetails.cadastralId = cadastralId;
    },
    setLestX(state, x) {
        state.locationDetails.lestCoordinates.x = x;
    },
    setLestY(state, y) {
        state.locationDetails.lestCoordinates.y = y;
    },
    setValidContractNr(state, validContractNr) {
        state.locationDetails.validContractNr = validContractNr;
    },
    setEicCode(state, eicCode) {
        state.locationDetails.eicCode = eicCode;
    },
    setMeterCode(state, meterCode) {
        state.locationDetails.meterCode = meterCode;
    },
    setJoining(state, joining) {
        state.desiredConnection.joining = joining;
    },
    setCapacityInConsumption(state, capacity) {
        state.desiredConnection.capacityInConsumption = capacity;
    },
    setCapacityInProduction(state, capacity) {
        state.desiredConnection.capacityInProduction = capacity;
    },
    addNetworkEquipment(state, equipment) {
        state.networkEquipment.push(equipment);
    },
    removeNetworkEquipment(state, index) {
        state.networkEquipment.splice(index, 1);
    },
    updateNetworkDeviceName(state, { index, name }) {
        state.networkEquipment[index].name = name;
    },
    updateNetworkDeviceQuantity(state, { index, quantity }) {
        state.networkEquipment[index].quantity = quantity;
    },
    updateDefaultDeviceQuantity(state, quantity) {
        state.defaultDeviceQuantity = quantity;
    },
    setManufacturerName(state, manufacturerName) {
        state.generationModuleData.manufacturerName = manufacturerName;
    },
    setPower(state, power) {
        state.generationModuleData.power = power;
    },
    setGeneratorType(state, type) {
        state.generationModuleData.type = type;
    },
    setPrimaryEnergySource(state, primaryEnergySource) {
        state.generationModuleData.primaryEnergySource = primaryEnergySource;
    },
    setApplicationComments(state, comments) {
        state.comments = comments;
    },
    setApplicationBlob(state, blob) {
        state.applicationBlob = blob;
    }
}
