export default {
    setCurrentOption(state, option) {
        state.currentOption = option;
    },
    setRoofColor(state, color) {
        state.roofColor = color
    },
    setRoofType(state, type) {
        state.roofType = type
    },
    setRWSColor(state, color) {
        state.rwsColor = color
    },
    setWallColor(state, color) {
        state.wallColor = color
    },
    setHouse(state, house) {
        state.selectedHouse = house
        state.wallColor = null
        state.roofColor = 5
        state.rwsColor = 2
    },
    setSelectedProductType(state, productType) {
        state.selectedProductType = productType
    },
    setRoof(state, roof) {
       state.includeRoof =  roof
    }
}
