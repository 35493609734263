<template>
    <div id="rain-system" class="flex flex-col mx-auto gap-2">
        <label class="subheading" for="rain-system">{{ $t('rfqForm.rwsType') }}</label>
        <div class="flex flex-wrap">
            <div v-for="(rainSystem, i) in rainSystemTypes" :key="i" class="rainsystem-option-btn mr-3 mb-3"
                v-bind:class="{ active: selectedRainSystem == i }" @click="updateRainSystemType(i)">
                <img class="rws-image" :src="'' + rainSystem.img + ''" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

export default ({
    name: "RainWaterSystemSelector",
    computed: {
        ...mapState('quoteFormModule', ['selectedRainSystem']),
        ...mapGetters('quoteFormModule', [
            "rainSystemTypes",
        ]),
    },
    methods: {
        ...mapActions('quoteFormModule', [
            "setRainSystemType",
        ]),
        updateRainSystemType(rainSystemType) {
            this.setRainSystemType(rainSystemType);
        }
    },
})
</script>