export default {
    setName({ commit }, contactName) {
        commit('setName', contactName);
    },
    setEmail({ commit }, email) {
        commit('setEmail', email);
    },
    setComment({ commit }, comment) {
        commit('setComment', comment);
    },
    setRainSystemType({ commit }, selectedRainSystem) {
        commit('setRainSystemType', selectedRainSystem);
    },
    setRoofArea({ commit }, roofArea) {
        commit('setRoofArea', roofArea)
    },
    setSolarPanel({ commit }, includeSolarPanel) {
        commit('setSolarPanel', includeSolarPanel);
    },
    setAddress({ commit }, address) {
        commit('setAddress', address);
    },
    setPhone({ commit }, phone) {
        commit('setPhone', phone);
    },
    setLatLng({ commit }, latLng) {
        commit('setLatLng', latLng);
    },
    resetQuoteFormState({ commit }) {
        commit('resetState');
    }
};
