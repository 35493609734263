<template>
    <div :class="dropdownShow ? 'expand' : 'collapse'">
        <p class="nav-item cursor-pointer px-3" @click="toggleDropdown">{{ currentLanguage.name }}<i class="pi pi-chevron-down ml-3"></i></p>
        <ul v-if="dropdownShow" :class="languageListClasses">
            <li v-for="lang of languages" :key="lang.name" class="cursor-pointer" :class="{'underline' : lang.name === currentLanguage.name}" @click.prevent="changeLanguage(lang.value)">
                {{ lang.name }}
            </li>
        </ul>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'LanguageList',
    data() {
        return {
            dropdownShow: false,
            languages: [
                { name: "EN", value: "en" },
                { name: "ET", value: "et" },
            ],
        };
    },
    computed: {
        currentLanguage() {
            if (this.$i18n.locale == "en") {
                return { name: "EN", value: "en" };
            } else if (this.$i18n.locale == "et") {
                return { name: "ET", value: "et" };
            } else {
                return { name: "EN", value: "en" };
            }
        },
        languageListClasses() {
            let cls = ['list-none', 'px-3', 'absolute', 'z-50']
            if (this.isDark) {
                cls.push(...['dark', 'pb-2', 'rounded-sm']);
            }
            return cls;
        }
    },
    methods: {
        ...mapActions('solarPanelCalculatorModule', ['setDefaultOwnConsumption']),
        toggleDropdown() {
            this.dropdownShow = !this.dropdownShow;
        },
        changeLanguage(locale) {
            this.dropdownShow = false;
            if (this.$i18n.locale !== locale) {
                const to = this.$router.resolve({ params: {locale} })
                localStorage.setItem("locale", locale);
                this.$i18n.locale = locale;
                this.$router.replace(to.fullPath);
            }
            this.setDefaultOwnConsumption(this.$i18n.locale);
        }
  },
  props: {
    isDark: {
        type: Boolean,
        default: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.collapse{
    :deep(.pi-chevron-down) {
        transform: rotate(0deg);
        transition: transform .2s linear;
    }
}

.expand {
    :deep(.pi-chevron-down) {
        transform: rotate(-180deg);
        transition: transform .2s linear;
    }
}

.nav-item {
    font-size: 18px;
    font-weight: 400;
}

.dark {
    background-color: #422E90;
}
</style>
