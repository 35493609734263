import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
    contactName: "",
    contactEmail: "",
    rainSystemTypes: [
        {
            name: "rectangular",
            img: "/img/rws/rect.jpg"
        },
        {
            name: "circular",
            img: "/img/rws/round.jpg"
        },
    ],
    selectedRainSystem: 1,
    roofArea: 0,
    includeSolarPanel: 'Yes',
    address: "",
    comment: "",
    phone: "",
    lat: null,
    lng: null,
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
