<template>
    <div class="container-fluid w-full py-6 mx-auto">
        <div class="rqf-description mb-4">
            <h4 class="heading mb-4">{{ $t('eleringApplication.application') }}</h4>
            <p>{{ $t('eleringApplication.applicationDescription') }}</p>
        </div>
        <div class="elering-form-wrapper mb-8">
            <EleringFormItem :open="true" :title="$t('eleringApplication.applicantInfo')" :class="{ 'form-valid' : isApplicantDataValid }">
                <div class="flex flex-col mb-6 gap-2">     
                    <label class="subheading-2" for="idCode">{{ $t('eleringApplication.idCode') }}</label>
                    <InputText
                        id="name"
                        type="text"
                        :placeholder="$t('eleringApplication.idCode')"
                        :modelValue="applicantData.idCode"
                        @update:modelValue="updateIdCode"
                    />
                </div>
                <div id="contact-address" class="flex flex-col mb-6 gap-2">
                    <label class="subheading-2" for="solar-panel">{{ $t('eleringApplication.sameAddress') }}</label>
                    <div class="flex flex-row gap-8">
                        <div class="flex flex-row gap-1">
                            <RadioButton id="yes" name="includeSolarPanels" :value="true" v-model="isContactAndHouseAddressSame" @change="toggleContactAddress(true)" />
                            <label class="text-medium" for="yes">{{ $t('generic.yes') }}</label>
                        </div>
                        <div class="flex flex-row gap-1">
                            <RadioButton id="no" name="includeSolarPanels" :value="false" v-model="isContactAndHouseAddressSame" @change="toggleContactAddress(false)" />
                            <label class="text-medium" for="no">{{ $t('generic.no') }}</label>
                        </div>
                    </div>
                </div>
                <div v-if="!isContactAndHouseAddressSame" class="flex flex-col mb-6 gap-2">     
                    <label class="subheading-2" for="idCode">{{ $t('eleringApplication.contactAddress') }}</label>
                    <InputText
                        id="name"
                        type="text"
                        :placeholder="$t('eleringApplication.contactAddress')"
                        :modelValue="applicantData.contactAddress"
                        @update:modelValue="updateContactAddress"
                    />
                </div>
                <div class="flex flex-col gap-2">
                    <label class="subheading-2" for="solar-panel">{{ $t('eleringApplication.representative') }}</label>
                    <div class="grid grid-cols-2 gap-6">
                        <div class="radio-button-wrapper col-span-1 flex flex-row gap-3 cursor-pointer" :class="{ 'selected': !applicantData.representsItself }" @click="updateRepresentation(false)">
                            <RadioButton id="yes" name="includeSolarPanels" :value="false" v-model="applicantData.representsItself" @change.prevent="updateRepresentation(false)"/>
                            <label class="text-medium" for="yes">AS Toode</label>
                        </div>
                        <div class="radio-button-wrapper col-span-1 flex flex-row gap-3 cursor-pointer" :class="{ 'selected': applicantData.representsItself }" @click="updateRepresentation(true)">
                            <RadioButton id="no" name="includeSolarPanels" :value="true" v-model="applicantData.representsItself" @change.prevent="updateRepresentation(true)" />
                            <label class="text-medium" for="no">{{ $t('eleringApplication.self') }}</label>
                        </div>
                    </div>
                </div>
            </EleringFormItem>

            <EleringFormItem :title="$t('eleringApplication.locationDetails')" :class="{ 'form-valid' : isLocationDetailsValid }">
                <div class="flex flex-row gap-2">
                    <!-- <div class="flex flex-col flex-1 mb-6 gap-2">     
                        <label class="subheading-2">{{ $t('eleringApplication.cadastralUnitName') }}</label>
                        <InputText
                            type="text"
                            class="w-full"
                            :placeholder="$t('eleringApplication.cadastralUnitName')"
                            :modelValue="locationDetails.cadastralUnitName"
                            @update:modelValue="updateCadastralUnitName"
                        />
                    </div> -->
                    <div class="flex flex-col flex-1 mb-6 gap-2">     
                        <label class="subheading-2">{{ $t('eleringApplication.cadastralId') }}</label>
                        <InputText
                            type="text"
                            class="w-full"
                            :placeholder="$t('eleringApplication.cadastralId')"
                            :modelValue="locationDetails.cadastralId"
                            @update:modelValue="updateCadastralId"
                        />
                    </div>

                    <div class="flex flex-col flex-1 mb-6 gap-2">     
                    <label class="subheading-2 flex"
                        >{{ $t('eleringApplication.eic') }}<tooltip
                            position="right"
                            class="information-tooltip"
                            :text="$t('eleringApplication.eicCode.tooltip')"
                            >
                            <i class="information-icon" />
                            <template v-slot:tooltip-heading>
                                {{ $t('eleringApplication.eic') }}
                            </template>
                            </tooltip></label
                        >
                    <InputText
                        type="text"
                        :placeholder="$t('eleringApplication.eic')"
                        :modelValue="locationDetails.eicCode"
                        @update:modelValue="updateEicCode"
                    />
                    </div>
    
                </div>
                <!-- <div class="flex flex-col flex-1 mb-6 gap-2">     
                    <label class="subheading-2">{{ $t('eleringApplication.lest') }}</label>
                    <div class="flex flex-row gap-2 w-full">
                        <InputText
                            type="text"
                            class="w-full"
                            placeholder="X"
                            :modelValue="locationDetails.lestCoordinates.x"
                            @update:modelValue="updateLestX"
                        />
                        <InputText
                            type="text"
                            class="w-full"
                            placeholder="Y"
                            :modelValue="locationDetails.lestCoordinates.y"
                            @update:modelValue="updateLestY"
                        />
                    </div>
                </div> -->
            </EleringFormItem>

            <EleringFormItem :title="$t('eleringApplication.desiredConnection')" :class="{ 'form-valid' : isDesiredConnectionValid }">
                <div class="flex flex-col mb-6 gap-2 hidden">
                    <label class="subheading-2" for="solar-panel">{{ $t('eleringApplication.joining') }}</label>
                    <div class="grid grid-cols-2 gap-6">
                        <div class="radio-button-wrapper col-span-2 flex flex-row gap-3 cursor-pointer" :class="{ 'selected': desiredConnection.joining === 'low' }" @click="updateJoining('low')">
                            <RadioButton id="low" name="joining" value="low" v-model="desiredConnection.joining" @change="updateJoining('low')" />
                            <label class="text-medium" for="low">{{ $t('eleringApplication.lowVoltage') }}</label>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row gap-2">
                    <div class="flex flex-col gap-2">
                        <label class="subheading-2 flex"
                        >{{ $t('eleringApplication.capacityConsuming') }}<tooltip
                            position="right"
                            class="information-tooltip"
                            :text="$t('eleringApplication.capacityInConsumption.tooltip')"
                            >
                            <i class="information-icon" />
                            <template v-slot:tooltip-heading>
                                {{ $t('eleringApplication.capacityConsuming') }}
                            </template>
                            </tooltip></label
                        >
                        <div class="p-inputgroup">
                            <InputText
                                id="capacityConsuming"
                                type="number"
                                :placeholder="$t('generic.insertNumber')"
                                :modelValue="desiredConnection.capacityInConsumption"
                                @update:modelValue="updateCapacityInConsumption"
                            />
                            <span class="p-inputgroup-addon">
                                <span class="label">A</span>
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-col gap-2 justify-between">
                        <label class="subheading-2">{{ $t('eleringApplication.capacityProducing') }}</label>
                        <div class="p-inputgroup">
                            <InputText
                                id="capacityProducing"
                                type="number"
                                :placeholder="$t('generic.insertNumber')"
                                :modelValue="capacityInProduction"
                                @update:modelValue="updateCapacityInProduction"
                            />
                            <span class="p-inputgroup-addon">
                                <span class="label">kWh</span>
                            </span>
                        </div>
                    </div>
                </div>   
            </EleringFormItem>

            <DeviceItem :isHidden="false" />
            
            <EleringFormItem :title="$t('eleringApplication.networkPower')" :class="{ 'form-valid': isInverterPowerValid }" class="hidden">
                <div class="flex flex-row gap-2">
                    <div class="flex flex-col mb-6 gap-2">
                        <label class="subheading-2">{{ $t('eleringApplication.inverterPower') }}</label>
                        <div class="p-inputgroup">
                            <InputText
                                id="networkPower"
                                type="number"
                                :modelValue="getInverterPower"
                                @input="inputInverterPower"
                            />
                            <span class="p-inputgroup-addon">
                                <span class="label">kW</span>
                            </span>
                        </div>
                    </div>
                </div>
            </EleringFormItem>

            <EleringFormItem :title="$t('eleringApplication.productionModuleData')" :class="{ 'form-valid' : isGenerationModuleValid }">
                <div class="flex flex-row gap-2">
                    <div class="flex flex-col flex-1 mb-6 gap-2">     
                        <label class="subheading-2" for="idCode">{{ $t('eleringApplication.manufacturersName') }}</label>
                        <InputText
                            id="manufacturersName"
                            type="text"
                            :placeholder="$t('generic.name')"
                            :modelValue="generationModuleData.manufacturersName"
                            @update:modelValue="updateManufacturersName"
                        />
                    </div>
                    <div class="flex flex-col flex-initial w-32 mb-6 gap-2">     
                        <label class="subheading-2" for="idCode">{{ $t('eleringApplication.power') }}</label>
                        <div class="p-inputgroup">
                            <InputText
                                id="power"
                                type="number"
                                :modelValue="capacityInProduction"
                                @update:modelValue="updateCapacityInProduction"
                            />
                            <span class="p-inputgroup-addon">
                                <span class="label">kWh</span>
                            </span>
                        </div>
                    </div>
                </div>
    
                <div class="flex flex-col mb-6 gap-2 hidden">
                    <label class="subheading-2" for="solar-panel">{{ $t('eleringApplication.type') }}</label>
                    <div class="grid grid-cols-2 gap-6">
                        <div class="radio-button-wrapper col-span-2 flex flex-row gap-3 cursor-pointer" :class="{ 'selected': generationModuleData.type === 'energyPark' }" @click="updateGeneratorType('energyPark')">
                            <RadioButton id="energyPark" value="energyPark" v-model="generationModuleData.type" @change="updateGeneratorType('energyPark')" />
                            <label class="text-medium" for="energyPark">{{ $t('eleringApplication.energyParkMode') }}</label>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col gap-2 hidden">
                    <label class="subheading-2" for="solar-panel">{{ $t('eleringApplication.primarySource') }}</label>
                    <!-- <Dropdown
                        :modelValue="generationModuleData.primaryEnergySource"
                        @update:modelValue="updatePrimaryEnergySource"
                        :options="sources"
                        optionLabel="translation"
                        :placeholder=" $t('genric.selectOne')" /> -->
                    <p>{{ $t('eleringApplication.sun') }}</p>
                </div>
            </EleringFormItem>
            
            <div class="w-full flex flex-col gap-2 mt-6">
                <label class="subheading-2" for="comments">{{ $t('eleringApplication.additionalNotes') }}</label>
                <Textarea
                    id="comments"
                    type="text"
                    :autoResize="true"
                    rows="4"
                    :placeholder="$t('eleringApplication.additionalNotesAndComments')"
                    :modelValue="comments"
                    @update:modelValue="updateComment"
                />
            </div>
        </div>

        <div class="grid grid-cols-3 gap-2">
            <Button @click="goToPreviousStep" class="p-button-secondary col-span-1">
                {{ $t('generic.back') }}
            </Button>
            <Button class="col-span-2" :class="{ 'p-disabled': !isFormValid }" @click="generateApplication">
                <span v-if="!generatingApplication">{{ $t('generic.next') }}</span>
                <div v-else class="lds-dual-ring"></div>
            </Button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
// import * as PDFGenerationService from "@/services/pdf-generation";
// import Dropdown from 'primevue/dropdown';
import * as ApplicationService from '@/services/eleringApplication';
import Button from "primevue/button";
import DeviceItem from "@/components/quoteForm/contentWrappers/DeviceItem";
import EleringFormItem from "@/components/quoteForm/contentWrappers/EleringFormItem";
import InputText from "primevue/inputtext";
import RadioButton from "primevue/radiobutton";
import Textarea from 'primevue/textarea';
import Tooltip from "@/components/generic/Tooltip.vue";


export default {
    name: "ProspectForm",
    components: {
        EleringFormItem,
        DeviceItem,
        // Dropdown,
        InputText,
        RadioButton,
        Textarea,
        Button,
        Tooltip,
    },
    computed: {
        ...mapState('eleringApplicationModule', [
            'applicantData',
            'locationDetails',
            'desiredConnection',
            'networkEquipment',
            'generationModuleData',
            'comments',
            'applicationBlob',
        ]),
        ...mapGetters('quoteFormModule', [
            "address",
        ]),
        ...mapGetters('eleringApplicationModule', [
            'isApplicantDataValid',
            'isLocationDetailsValid',
            'isDesiredConnectionValid',
            'isGenerationModuleValid',
            'isNetworkEquipmentValid',
            'applicationData',
            'capacityInProduction',
        ]),
        ...mapGetters('quoteFormModule', ['getQuoteForm']),
        ...mapGetters('solarPanelCalculatorModule', ['getInverterPower', 'isInverterPowerValid',]),
        isFormValid() {
            return this.isApplicantDataValid
                && this.isLocationDetailsValid
                && this.isDesiredConnectionValid
                && this.isGenerationModuleValid
                && this.isNetworkEquipmentValid
                && this.isInverterPowerValid
        },
    },
    data() {
        return {
            validationStatus: "pending",
            generatingApplication: false,
            isContactAndHouseAddressSame: true,
        };
    },
    emits: ['nextStep', 'previousStep'],
    mounted() {
        this.setApplicantsContactAddress(this.address);
    },
    methods: {
        ...mapActions('eleringApplicationModule', [
            'setApplicantsIdCode',
            'setRepresentsItself',
            'setCadastralUnitName',
            'setCadastralId',
            'setApplicantsContactAddress',
            // 'setLestX',
            // 'setLestY',
            'setValidContractNr',
            'setEicCode',
            'setMeterCode',
            'setJoining',
            'setCapacityInConsumption',
            'setCapacityInProduction',
            'setManufacturerName',
            'setPower',
            'setGeneratorType',
            'setPrimaryEnergySource',
            'setApplicationComments',
            'setApplicationBlob',
        ]),
        ...mapActions('solarPanelCalculatorModule', [
            "setInverterPower",
        ]),
        toggleContactAddress(value) {
            if (value) {
                this.setApplicantsContactAddress(this.address);
                this.isContactAndHouseAddressSame = true;
            } else {
                this.setApplicantsContactAddress('');
                this.isContactAndHouseAddressSame = false;
            }
        },
        goToPreviousStep() {
            this.$emit('previousStep');
        },
        updateIdCode(idCode) {
            this.setApplicantsIdCode(idCode);
        },
        updateContactAddress(address) {
            this.setApplicantsContactAddress(address);
        },
        updateRepresentation(representsItself) {
            this.setRepresentsItself(representsItself);
        },
        updateCadastralUnitName(name) {
            this.setCadastralUnitName(name);
        },
        updateCadastralId(id) {
            this.setCadastralId(id);
        },
        // updateLestX(x) {
        //     this.setLestX(x);
        // },
        // updateLestY(y) {
        //     this.setLestY(y);
        // },
        updateValidContractNr(contractNr) {
            this.setValidContractNr(contractNr);
        },
        updateEicCode(eicCode) {
            this.setEicCode(eicCode);
        },
        updateMeterCode(meterCode) {
            this.setMeterCode(meterCode);
        },
        updateJoining(joining) {
            this.setJoining(joining);
        },
        updateCapacityInConsumption(capacity) {
            this.setCapacityInConsumption(capacity);
        },
        updateCapacityInProduction(capacity) {
            this.setCapacityInProduction(capacity);
        },
        updateManufacturersName(name) {
            this.setManufacturerName(name);
        },
        updatePower(value) {
            this.setPower(value);
        },
        updateGeneratorType(type) {
            this.setGeneratorType(type);
        },
        updatePrimaryEnergySource(source) {
            this.setPrimaryEnergySource(source);
        },
        updateComment(comment) {
            this.setApplicationComments(comment);
        },
        getCurrentRouteName() {
            let routeName = this.$route.params.productType;
            return routeName = typeof routeName === 'string' ?
                routeName.replace(/-[a-z]/g, m => m.toUpperCase()).replaceAll("-", "")
                : null;
        },
        createEmailSubject() {
            let subject = "";
            const locale = this.$i18n.locale.toUpperCase();
            // const interestSubject = this.$t('emailSender.interest.subject');
            const interestSubject = "[Application] Uus liitumistaotlus";

            if (this.getCurrentRouteName()) {
                const routeMap = {
                    roofWithPanels: "TERVIKLAHENDUS",
                    roof: "KATUS",
                    solarPanels: "PANEELID"
                };
                subject = `[${routeMap[this.getCurrentRouteName()]}] [${locale}] - ${interestSubject}`;
            } else {
                subject = `[${locale}] - ${interestSubject}`;
            }

            return subject
        },
        async generateApplication() {
            const dataset = this.applicationData;
            const emailSubject = this.createEmailSubject();
            this.generatingApplication = true;
            ApplicationService.createApplication(dataset, '/files/liitumistaotlus.docx', { locale: this.$i18n.locale, emailSubject });
            setTimeout(() => {
                this.generatingApplication = false;
                this.$router.push(`/${this.$i18n.locale}/thank-you`);
            }, 3000);
        },
        inputInverterPower(e) {
            this.setInverterPower(e.target.value);
        },
    },
};
</script>

<style lang="scss">
.radio-button-wrapper {
    border: 1px solid #D2D2D2;
    padding: 12px 16px;
    border-radius: 4px;
    &.selected {
        background-color: #F0F0FA;
        border-color: #5D5FCB;
    }
}
</style>
