<template>
    <header class="flex justify-start sm:items-center flex-col"
        :class="{ 'hidden': isHidingHeader }">
        <div class="header-title-wrapper">
            <p class="title">{{ $t('header.welcomeMessage.1') }}</p>
            <p class="text-larger">{{ $t('header.welcomeMessage.2') }}</p>
            <p class="text-larger font-bold">{{ $t('header.welcomeMessage.3') }}</p>
        </div>
        <div class="card-wrapper">
            <div class="card-container" ref="card">
                <card-product-option v-for="(productOption, i) in productOptions" :key="i"
                    :productType="productOption.productType" :title="productOption.title" :img="productOption.img"
                    @changeProduct="changeProduct" :href="productOption.href" />
            </div>
        </div>
    </header>
</template>

<script>
import CardProductOption from "@/components/navbar/CardProductOption";
import { mapGetters } from 'vuex';

export default {
    name: "Header",
    components: {
        CardProductOption,
    },
    data() {
        return {
            productOptions: [
                {
                    productType: "fastenings",
                    title: 'header.fastening.title',
                    subtitle: "",
                    img: "/img/productOptions/fastening.png",
                    href: "https://www.toode.ee/tootekataloog/paikesepaneelide-kinnitused/",
                },
                // {
                //     productType: "roofWithPanels",
                //     title: 'header.roofWithPanelsButton.title',
                //     subtitle: "",
                //     img: "/img/productOptions/fullSolution.jpg",
                // },
                // {
                //     productType: "solarPanels",
                //     title: 'header.solarPanelsButton.title',
                //     subtitle: "",
                //     img: "/img/productOptions/paneliid.jpg",
                // },
                {
                    productType: "roof",
                    title: 'header.roofButton.title',
                    subtitle: "",
                    img: "/img/productOptions/katus.jpg",
                },
            ],
        }
    },
    computed: {
        ...mapGetters('houseBuilderModule', ['selectedProductType']),
        isHidingHeader() {
            return ['roof', 'solarPanels', 'roofWithPanels'].includes(this.selectedProductType) || this.$route.name != 'homepage';
        },
    },
    methods: {
        changeProduct(productType) {
            productType = productType.replace(/[A-Z]/g, m => '-' + m.toLowerCase());
            this.$router.push({ path: `/${this.$route.params.locale}/${productType}` })
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";

header {
    width: 100%;
    position: relative;
    min-height: 95vh;

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: "Poppins Bold" !important;
    }

    p {
        font-family: Poppins !important;
        margin-top: 10px;
    }

    // 1024px - 1439px
    .header-title-wrapper {
        max-width: 1024px;
        padding: 165px 25px 0 25px;
        color: $primary-main;

        .title {
            font-weight: 700;
            font-size: 36px;
            padding: 0px 60px;
        }

        // 0 - 767 px
        @media screen and (max-width: 767px) {
            padding: 165px 10px 0 10px;
            .title {
                font-weight: 700;
                font-size: 26px;
                padding: 0px 10px;
            }
        }

        // 768px - 1023 px
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            .title {
                font-weight: 700;
                font-size: 30px;
                padding: 0px 60px;
            }
            .text-larger {
                font-size: 16px;
            }
        }

        // 1440px+
        @media screen and (min-width: 1440px) {
            .title {
                font-weight: 700;
                font-size: 40px;
                padding: 0px 60px;
            }
            .text-larger {
                font-size: 20px;
            }
        }
    }

    .card-container {
        display: flex;
        flex-wrap: wrap;
        column-gap: 8vw;
        row-gap: 4vh;
        justify-content: center;
        align-items: flex-end;
    }

    .card-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 95px 8px 214px 8px;
    }

    .card-wrapper-title {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 32px;
        color: $neutral-black;
        text-transform: uppercase;
        line-height: 27px;
        letter-spacing: 0.25px;
        text-align: center;
    }
}
</style>