<template>
  <footer>
    <div class="container footer-content mx-auto">
      <div class="grid grid-cols-1 md:grid-cols-2">
        <div class="main-text">
            <div class="grid grid-cols-2 gap-16">
                <div>
                    <p class="text-medium font-bold">{{ $t('footer.informationLine1') }}</p>
                    <p class="text-medium">{{ $t("footer.informationLine2") }}</p>
                    <p class="text-medium">{{ $t("footer.informationLine3") }}</p>
                    <p class="text-medium">{{ $t("footer.informationLine4") }}</p>
                </div>
                <div class="text-right">
                    <p class="text-medium">{{ $t("footer.openHours") }}</p>
                    <p class="text-medium">{{ $t("footer.productGiveout") }}</p>
                </div>
                <div class="mt-auto">
                    <p class="text-medium">developed by <a class="cursor-pointer underline" href="https://wenture.io/">wenture.io</a></p>
                </div>
                <div class="socialLinks">
                    <a href="https://www.facebook.com/ASToode/" class="cursor-pointer underline font-bold">
                        <p class="text-medium">Facebook</p>
                    </a>
                    <a href="https://www.youtube.com/watch?v=gvzSX82lFgA" class="cursor-pointer underline font-bold">
                        <p class="text-medium">Youtube</p>
                    </a>
                    <a href="https://www.instagram.com/as_toode/" class="cursor-pointer underline font-bold">
                      <p class="text-medium">Instagram</p>
                    </a>
                </div>
            </div>
        </div>
        <div class="footer-logo ml-auto">
          <img src="/img/logo-2.png" alt="Toode" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "~@/assets/sass/base/variables";

footer {
  width: 100%;
  background-color: $primary-background;
  color: $primary-dark;
  font-size: 14px;

  .main-text {
    border-top: 1px solid $primary-dark;
    padding: 18px 0px 18px 12px;
  }

  .footer-logo {
      margin-bottom: 24px;
  }

  .footer-content {
    padding: 40px 64px;
    min-height: 230px;
    text-align: left;
  }

  .socialLinks {
    // flex justify-end gap-5
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    column-gap: 1.25rem;

    @media screen and (max-width: 580px) {
      flex-wrap: wrap;
      row-gap: 0.25rem;
    }
  }
}
</style>
