<template>
  <nav :class="navbarClasses">
    <div class="navbar-brand">
      <a :href="wwwSiteURL()" target="_blank"><img :src="navbarLogo" alt="Toode" class="navbar-logo cursor-pointer" /></a>
    </div>
    <div class="collapse navbar-collapse" :class="{'hidden': isOnLandingPage}" id="navbarSupportedContent">
      <nav-list class="text-nowrap" />
    </div>
    <div class="navbar-language">
      <language-list :isDark="isOnLandingPage" />
    </div>
  </nav>
</template>

<script>
import NavList from "@/components/navbar/NavList";
import LanguageList from "./LanguageList.vue";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    NavList,
    LanguageList,
  },
  computed: {
    ...mapGetters('houseBuilderModule', ['selectedProductType']),
    /*
    if selectedProductType == '', it means user on homepage with CardProductOption (see Header.vue)
    homepage with CardProductOption has different navbar setup
    */
    navbarClasses() {
      let cls = ['absolute', 'top-0', 'w-full', 'navbar', 'navbar-expand-lg', 'z-50'];
      if (this.isOnLandingPage) {
        cls.push('navbar-main');
      } else {
        cls.push('navbar-light');
      }
      return cls;
    },
    navbarLogo() {
      let img = '/img/logo-2.png';
      if (this.isOnLandingPage) {
        img = '/img/logo-3.svg';
      }
      return img;
    },
    isOnLandingPage() {
      return (this.selectedProductType == '' && this.$route.name == 'homepage')
    }
  },
  methods: {
    ...mapActions('houseBuilderModule', ['setSelectedProductType']),
    wwwSiteURL() {
      return this.$i18n.locale === "et" ? "https://www.toode.ee/" : "https://www.toode.ee/en/";
    },
  }
};
</script>
<style lang="scss">
.navbar-brand {
  display: flex;
  flex: 1;
}

.navbar {
  @media (max-width: 768px) {
    padding: 12px 16px !important;
  }
}

.navbar-logo {
  height: 59px;
  min-width: 77px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: rgba(#2f3192, 0.2) !important;
}

.p-dropdown {
  box-shadow: none !important;

  &:not(.p-disabled):hover {
    border-color: #2f3192 !important;
  }

  &:not(.p-disabled):focus,
  &:not(.p-disabled).p-focus {
    border-color: #2f3192 !important;
  }
}
</style>
