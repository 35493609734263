<template>
  <div :style="{ width: '192px', height: '100%', textAlign: 'center' }">
    <v-chart class="chart" :option="guage" :initOptions="{renderer: 'SVG'}" />
    <label class="subheading">{{ label }}</label>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { GaugeChart } from "echarts/charts";
import { SVGRenderer } from "echarts/renderers";
import VChart from "vue-echarts";
import { defineComponent } from "vue";

use([GaugeChart, SVGRenderer ]);

export default defineComponent({
  name: "Gauge",
  props: ["amount", "label", "val", "max", "min", "color", "mode"],
  components: {
    VChart,
  },
  computed: {
    guage() {
      return {
        series: [
          {
            type: "gauge",
            center: ["50%", "60%"],
            startAngle: 180,
            endAngle: 0,
            radius: "100%",
            min: this.min,
            max: this.max,
            splitNumber: null,
            itemStyle: {
              color: this.color,
            },
            progress: {
              show: true,
              roundCap: true,
              width: 15,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 15,
              },
            },
            anchor: {
              show: false,
            },
            title: {
              show: false,
            },
            detail: {
              valueAnimation: true,
              width: "60%",
              lineHeight: 40,
              height: "15%",
              borderRadius: 8,
              offsetCenter: [0, -15],
              fontSize: 32,
              fontWeight: "bolder",
              formatter: (item) => {
                return item + "\n{unit|" + this.val + "}" 
              },
              rich: {
                unit: {
                    fontSize: 14,
                    color: '#999',
                }
              },
              color: "#474747",
            },
            data: [
              {
                value: this.amount,
                name: "test",
              },
            ],
          },
        ],
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.chart {
  height: 200px;
}

.subheading {
    color: $neutral-grey-4 !important;
}
</style>
