<template>
  <div class="calculator__map" ref="mapContainer">
    <iframe
        class="second-row" 
        v-if="width && height" 
        ref="mapFrame" 
        :width="width" 
        :height="height" 
        src="https://xgis.maaamet.ee/xgis2/page/link/uXrJMPWv?plugin=true">
    </iframe>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: "Xgis",
    data() {
        return {
            searchValue: '',
            width: null,
            height: null,
        }
    },
    methods: {
        ...mapActions('quoteFormModule', ['setAddress']),
        findExternalSearch() {
            var mapSearch = document.getElementById("mapFrame_map_searchInput");
            if (mapSearch) {
                this.searchValue = mapSearch.value;
            }
        },
    },
    mounted() {
        // window.addEventListener("resize", this.resetFrameSize);
        const width = this.$refs.mapContainer.clientWidth;
        const height = this.$refs.mapContainer.clientHeight;
        this.width = width;
        this.height = height;
    },
    watch: {
        searchValue: function(newValue, oldValue) {
            if (newValue != oldValue) {
                this.setAddress(newValue);
            }
        }
    },
};
</script>

<style lang="scss">
.calculator__map {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: #32774E;
    overflow: hidden;
}

.second-row {
    flex-grow: 1;
    border: none;
    margin: 0;
    padding: 0;
    // width: 100%;
    // height: 100%;
}
</style>
