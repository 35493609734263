<template>
    <div class="container-fluid w-full py-6 mx-auto">
        <div class="rqf-description mb-4">
            <h4 class="heading mb-4">{{ $t('rfqForm.requirements.title') }}</h4>
            <p>{{ $t('rfqForm.requirements.subtitle') }}</p>
        </div>
        <div v-show="getCurrentRouteName() == 'roof'" class="flex flex-col justify-between mb-2">
            <label class="subheading mb-2" for="ahc">{{ $t('rfqForm.roofArea') }}</label>
            <div class="p-inputgroup roofArea-input">
            <InputText
                id="roofArea"
                type="number"
                :value="roofArea"
                @input="inputRoofArea"
            />
            <span class="p-inputgroup-addon"><span class="label">m2</span></span>
            </div>
        </div>
        <div class="rfq-form-wrapper">
            <div class="p-field flex flex-col mb-6 gap-2">
                <label class="subheading" for="address">{{ $t('generic.houseAddress') }}</label>
                <GMapAutocomplete id="address" type="text" :placeholder="$t('generic.houseAddress')" :class="{
                  'input-invalid': address == '' && validationStatus == 'error'
                }" class="address-search" @place_changed="updatePlace" ref="gMapAutoComplete" />
            </div>

            <Button class="w-full" @click="validateForm">
                {{ $t('generic.next') }}
            </Button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import Button from "primevue/button";
import * as PDFGenerationService from "@/services/pdf-generation";
import InputText from "primevue/inputtext";

export default {
    name: "QuoteForm",
    components: {
        Button,
        InputText
    },
    computed: {
        ...mapState('quoteFormModule', ['selectedRainSystem', 'roofArea']),

        ...mapGetters('quoteFormModule', [
            "getQuoteForm",
            "rainSystemTypes",
            "address",
        ]),
        ...mapGetters('solarPanelCalculatorModule', [
            "includePanel",
            "calculated",
        ]),
    },
    data() {
        return {
            validationStatus: "pending",
        };
    },
    emits: ['nextStep'],
    methods: {
        ...mapActions('quoteFormModule', [
            'setAddress', 'setLatLng', 'setRoofArea'
        ]),
        updatePlace(place) {
            this.setAddress(place.formatted_address);
            this.setLatLng(place.geometry.location);
        },
        getCurrentRouteName() {
            let routeName = this.$route.params.productType;
            return routeName = typeof routeName === 'string' ?
                routeName.replace(/-[a-z]/g, m => m.toUpperCase()).replaceAll("-", "")
                : null;
        },
        createEmailSubject() {
            let subject = "";
            const locale = this.$i18n.locale.toUpperCase();
            const interestSubject = this.$t('emailSender.lead.subject');

            if (this.getCurrentRouteName()) {
                const routeMap = {
                    roofWithPanels: "TERVIKLAHENDUS",
                    roof: "KATUS",
                    solarPanels: "PANEELID"
                };
                subject = `[${routeMap[this.getCurrentRouteName()]}] [${locale}] - ${interestSubject}`;
            } else {
                subject = `[${locale}] - ${interestSubject}`;
            }

            return subject
        },
        async validateForm() {
            if (this.address == "") {
                this.validationStatus = "error";
            } else {
                const panels = this.includeSolarPanels ? this.$t('generic.yes') : this.$t('generic.no');

                const subject = this.createEmailSubject();
                const messageBody = this.$t('emailSender.lead.messageBody', { address: this.address, includeSolarPanels: panels, roofType: this.rainSystemTypes[this.selectedRainSystem].name });
                
                const locale = this.$i18n.locale;
                const to = {
                    'PRODUCTION': process.env[`VUE_APP_CONTACT_EMAIL_${ locale.toUpperCase() }`],
                    'STAGING': process.env.VUE_APP_CONTACT_EMAIL_STAGING,
                    'DEV': process.env.VUE_APP_CONTACT_EMAIL_TEST,
                }[process.env.VUE_APP_ENV]
                
                await PDFGenerationService.sendLeadEmail({ to: to, subject: subject, messageBody: messageBody });
                this.$emit('nextStep');
            }
        },
        inputRoofArea(e) {
            this.setRoofArea(e.target.value);
        }
    },
    watch: {
        address(newAddress, oldAddress) {
            if (newAddress != oldAddress) {
                this.$refs.gMapAutoComplete.$refs.input.value = this.address;
            }
        }
    },
    mounted() {
        if (this.address != '' || this.address != null) {
            this.$refs.gMapAutoComplete.$refs.input.value = this.address;
        }
    },
};
</script>

<style lang="scss">
.address-search {
    padding: 14px 16px;
    border-radius: 4px;
    &:hover {
        border: none;
        border: 1px solid #2f3192;
    }
    box-shadow: 0px 2px 8px rgba(76, 80, 85, 0.16);
}
.input-invalid {
    border: 1px solid #f44336;
}
.roofArea-input {
    border: 1px solid #ffffff;
    transition: 0s;

    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(76, 80, 85, 0.16);
    &:hover {
        border: none;
        border: 1px solid #2f3192;
    }
}
</style>