export default {
    setName(state, contactName) {
        state.contactName = contactName;
    },
    setEmail(state, contactEmail) {
        state.contactEmail = contactEmail;
    },
    setComment(state, comment) {
        state.comment = comment;
    },
    setRainSystemType(state, selectedRainSystem) {
        state.selectedRainSystem = selectedRainSystem;
    },
    setRoofArea(state, roofArea) {
        state.roofArea = roofArea;
    },
    setSolarPanel(state, includeSolarPanel) {
        state.includeSolarPanel = includeSolarPanel;
    },
    setAddress(state, address) {
        state.address = address;
    },
    setPhone(state, phone) {
        state.phone = phone;
    },
    resetData(state) {
        state.contactName = "",
        state.contactEmail = "",
        state.selectedRainSystem = "",
        state.includeSolarPanel = false,
        state.address = "",
        state.phone = ""
    },
    setLatLng(state, latLng) {
        state.lat = latLng.lat();
        state.lng = latLng.lng();
    },
    resetState(state) {
        state.contactName = "";
        state.contactEmail = "";
        state.rainSystemTypes = [
            {
            name: "rectangular",
            img: "/img/rws/rect.jpg",
            },
            {
            name: "circular",
            img: "/img/rws/round.jpg",
            },
        ];
        state.selectedRainSystem = 1;
        state.roofArea = 0;
        state.includeSolarPanel = "Yes";
        state.address = "";
        state.comment = "";
        state.phone = "";
        state.lat = null;
        state.lng = null;

    }
};
