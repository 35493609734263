<template>
    <div class="min-h-screen">
        <div class="thank-you-page-gradient">
            <div
                class="thank-you-page-wrapper flex justify-center items-center flex-col gap-10 w-full py-16 px-4"
                :class="{'h-screen-96': !isShowApplicationStepsWrapper }"
            >
                <div class="complete-icon flex items-center justify-center relative mt-16">
                    <h3 class="heading"><i class="pi pi-check"></i></h3>
                </div>
                <h1 class="color-main">{{ $t('thankYouPage.intro') }}</h1>
                <p class="color-main text-large mb-10">{{ $t('thankYouPage.contactSoon') }}</p>
            </div>
        </div>

        <div v-if="(this.eleringSignature && this.isUserSelectPanel)" class="application-steps-wrapper">
            <div class="container grid grid-cols-1 gap-10 md:grid-cols-3 text-center">
                <div class="flex flex-col items-center gap-4 px-10">
                    <div class="step-icon mr-auto align-start flex items-center justify-center">
                        <h3 class="heading">1</h3>
                    </div>
                    <img class="application-icon" src="~@/assets/svg/document-download.svg" />
                    <h4>{{ applicationExists ? $t('thankYouPage.download') : $t('thankYouPage.create') }}</h4>
                    <p class="text-dimmed">{{ applicationExists ? $t('thankYouPage.download.description') : $t('thankYouPage.create.description') }}</p>
                    <Button @click="downloadBlob">{{ applicationExists ? $t('thankYouPage.download') : $t('thankYouPage.create') }}</Button>
                </div>

                <div class="flex flex-col items-center gap-4 px-10">
                    <div class="step-icon mr-auto align-start flex items-center justify-center">
                        <h3 class="heading">2</h3>
                    </div>
                    <img class="application-icon" src="~@/assets/svg/document-sign.svg" />
                    <h4>{{ $t('thankYouPage.sign') }}</h4>
                    <p class="text-dimmed">{{ $t('thankYouPage.sign.description') }}</p>
                </div>

                <div class="flex flex-col items-center gap-4 px-10">
                    <div class="step-icon mr-auto align-start flex items-center justify-center">
                        <h3 class="heading">3</h3>
                    </div>
                    <img class="application-icon" src="~@/assets/svg/document-apply.svg" />
                    <h4>{{ $t('thankYouPage.send') }}</h4>
                    <p class="text-dimmed">{{ $t('thankYouPage.sign.additional') }}</p>
                </div>
            </div>
        </div>
        <div v-else-if="isFromLiituPage" class="application-steps-wrapper">
            <div class="container grid grid-cols-1 gap-10 md:grid-cols-2 text-center mx-auto w-3/4">
                <div class="flex flex-col items-center gap-4 px-10">
                    <div class="step-icon mr-auto align-start flex items-center justify-center">
                        <h3 class="heading">1</h3>
                    </div>
                    <img class="application-icon" src="~@/assets/svg/document-sign.svg" />
                    <h4>{{ $t('thankYouPage.sign') }}</h4>
                    <p class="text-dimmed">{{ $t('thankYouPage.sign.description') }}</p>
                </div>

                <div class="flex flex-col items-center gap-4 px-10">
                    <div class="step-icon mr-auto align-start flex items-center justify-center">
                        <h3 class="heading">2</h3>
                    </div>
                    <img class="application-icon" src="~@/assets/svg/document-apply.svg" />
                    <h4>{{ $t('thankYouPage.send') }}</h4>
                    <p class="text-dimmed">{{ $t('thankYouPage.sign.additional') }}</p>
                </div>
            </div>
        </div>

        <div v-if="isUserSelectPanel" class="discover-wrapper flex justify-center items-center flex-col gap-10 w-full py-16">
            <h3 class="color-main">{{ $t('thankYouPage.discoverLong') }}</h3>
            <a href="#swedbankLoan"><Button>{{ $t('thankYouPage.discover') }}<i class="pi pi-arrow-down ml-2"></i></Button></a>
        </div>
        
        <div v-if="isUserSelectPanel" class="swedbank-loan-gradient w-full" :class="$i18n.locale" />

        <div v-if="isUserSelectPanel" class="swedbank-loan-options w-full" :class="$i18n.locale" id="swedbankLoan">
            <div class="swedbank-loan-options-wrapper px-4">
                <h2 class="color-main mx-auto mb-10">{{ $t('loan.swedbank') }}</h2>
                <iframe ref="swedbankWidget" class="swedbank-widget" :src="widgetSrc" />
                <div v-if="swedbankAllowed">
                <h3 class="color-main flex justify-center mb-10">{{ $t('loan.swedbank') }}</h3>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mb-10">
                    <div class="flex gap-2">
                        <div class="step-icon swedbank flex-none align-start flex items-center justify-center">
                            <h3 class="heading">1</h3>
                        </div>
                        <p>{{ $t('loan.swedbank.generalInfo') }}</p>
                    </div>
                    <div class="flex gap-2">
                        <div class="step-icon swedbank flex-none align-start flex items-center justify-center">
                            <h3 class="heading">2</h3>
                        </div>
                        <p>{{ $t('loan.swedbank.fillLoanRequest') }}</p>
                    </div>
                    <div class="flex gap-2">
                        <div class="step-icon swedbank flex-none align-start flex items-center justify-center">
                            <h3 class="heading">3</h3>
                        </div>
                        <p>{{ $t('loan.swedbank.repayFree') }}</p>
                    </div>
                    <div class="flex gap-2">
                        <div class="step-icon swedbank flex-none align-start flex items-center justify-center">
                            <h3 class="heading">4</h3>
                        </div>
                        <p>{{ $t('loan.swedbank.noContribution') }}</p>
                    </div>
                    <div class="flex gap-2">
                        <div class="step-icon swedbank flex-none align-start flex items-center justify-center">
                            <h3 class="heading">5</h3>
                        </div>
                        <p>{{ $t('loan.swedbank.loanPeriod') }}</p>
                    </div>
                    <div class="flex gap-2">
                        <div class="step-icon swedbank flex-none align-start flex items-center justify-center">
                            <h3 class="heading">6</h3>
                        </div>
                        <p>{{ $t('loan.swedbank.fixedInterest') }}</p>
                    </div>
                </div>
                    <div class="swedbank-general-info px-4 py-3">
                        <p class="text-small">{{ $t('loan.swedbank.generalConditions') }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isUserSelectPanel" class="swedbank-loan-gradient reverse w-full" :class="$i18n.locale" />

        <div v-if="(lhvAllowed && isUserSelectPanel)" class="lhv-loan-options w-full">
            <div class="lhv-loan-options-wrapper">
                <h2 class="color-main flex justify-center mb-10">{{ $t('loan.lhv') }}</h2>
                <div class="grid grid-cols-1 md:grid-cols-3 gap-10 mb-10">
                    <div class="col-span-1 flex flex-col gap-6">
                        <p class="text-dimmed text-medium uppercase">LHV Loan</p>
                        <h3 class="color-main">{{ $t('loan.lhv.generalInfo') }}</h3>
                        <p class="text-dimmed text-small">You can pay for the goods or services in installments in Toode and see our best offers.</p>
                    </div>
                    <div class="col-span-2 ml-auto">
                        <iframe class="hidden md:block" src="//partners.lhv.ee/et/widget/hire-purchase/green-hire-purchase/horizontal//?code=EE-10330885&amp;price=&amp;interest=4.9&amp;info_url=&amp;price_based_interest=" width="540px" height="100px" frameborder="no" scrolling="no" id="iFrameResizer0" style="overflow: hidden; height: 233px;"></iframe>
                        <iframe class="mx-auto md:hidden" src="//partners.lhv.ee/et/widget/hire-purchase/green-hire-purchase/vertical//?code=EE-10330885&amp;price=&amp;interest=4.9&amp;info_url=&amp;price_based_interest=" height="400px" frameborder="no" scrolling="no" id="iFrameResizer0" style="overflow: hidden; height: 450px"></iframe>
                    </div>
                </div>
                <div class="grid grid-cols-2 md:grid-cols-3 gap-12 mb-10">
                    <div class="flex gap-2">
                        <div class="step-icon lhv flex-none align-start flex items-center justify-center">
                            <h3 class="heading"><i class="pi pi-check"></i></h3>
                        </div>
                        <p>{{ $t('loan.lhv.reply') }}</p>
                    </div>
                    <div class="flex gap-2">
                        <div class="step-icon lhv flex-none align-start flex items-center justify-center">
                            <h3 class="heading"><i class="pi pi-check"></i></h3>
                        </div>
                        <p>{{ $t('loan.lhv.downpayment') }}</p>
                    </div>
                    <div class="flex gap-2">
                        <div class="step-icon lhv flex-none align-start flex items-center justify-center">
                            <h3 class="heading"><i class="pi pi-check"></i></h3>
                        </div>
                        <p>{{ $t('loan.lhv.creditAmount') }}</p>
                    </div>
                    <div class="flex gap-2">
                        <div class="step-icon lhv flex-none align-start flex items-center justify-center">
                            <h3 class="heading"><i class="pi pi-check"></i></h3>
                        </div>
                        <p>{{ $t('loan.lhv.monthlyPayment') }}</p>
                    </div>
                    <div class="flex gap-2">
                        <div class="step-icon lhv flex-none align-start flex items-center justify-center">
                            <h3 class="heading"><i class="pi pi-check"></i></h3>
                        </div>
                        <p>{{ $t('loan.lhv.noManagementFee') }}</p>
                    </div>
                    <div class="flex gap-2">
                        <div class="step-icon lhv flex-none align-start flex items-center justify-center">
                            <h3 class="heading"><i class="pi pi-check"></i></h3>
                        </div>
                        <p>{{ $t('loan.lhv.freeReturn') }}</p>
                    </div>
                </div>
                <div class="lhv-general-info px-4 py-3">
                    <p class="text-small">{{ $t('loan.lhv.noBankAccount') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
import * as ApplicationService from '@/services/eleringApplication';
import { mapGetters, mapState } from 'vuex';

export default {
    name: "ThankYouPage",
    components: {
        Button,
    },
    methods: {
        downloadBlob() {
            if (this.applicationBlob) {
                ApplicationService.saveWordFile(this.applicationBlob);
            } else {
                this.$router.push(`/${this.$i18n.locale}/`);
            }
        },
    },
    computed: {
        ...mapGetters('houseBuilderModule', ['selectedProductType']),
        ...mapState('eleringApplicationModule', ['applicationBlob']),
        linkEnd() {
            return this.$i18n.locale === 'et' ? 'solar' : 'solar-eng';
        },
        lhvAllowed() {
            return ['en', 'et', 'lt'].includes(this.$i18n.locale);
        },
        swedbankAllowed() {
            return ['en', 'et', 'lt'].includes(this.$i18n.locale);
        },
        eleringSignature() {
            return ['en', 'et'].includes(this.$i18n.locale);
        },
        applicationExists() {
            return this.applicationBlob ? true : false;
        },
        widgetSrc() {
            if (this.$i18n.locale === 'et') {
                return 'https://www.swedbank.ee/embedded/ee/small-loan-calculator/solar.html'
            } else if (this.$i18n.locale === 'lv') {
                return 'https://www.swedbank.lv/embedded/lv/solar-loan-calculator/'
            } else {
                return 'https://www.swedbank.ee/embedded/ee/small-loan-calculator/solar-eng.html'
            }
        },
        isUserSelectPanel() {
            return ['solarPanels', 'roofWithPanels'].includes(this.selectedProductType)
        },
        isFromLiituPage() {
            return this.$router.currentRoute.value.hash === '#liitu';
        },
        isShowApplicationStepsWrapper() {
            const cond1 = (this.eleringSignature && this.isUserSelectPanel);
            const cond2 = this.isFromLiituPage;
            return cond1 || cond2;
        }
    },
}
</script>

<style lang="scss">
.application-steps-wrapper {
    max-width: 1058px;
    margin: auto auto 40px auto;
    .application-icon {
        height: 150px;
    }
}

.swedbank-loan-gradient {
    background: linear-gradient(#ffffff, #F9F2EB);
    height: 80px;

    &.reverse {
        background: linear-gradient(#F9F2EB, #F9F8F7);
    }
}

.swedbank-loan-gradient.lv {
    background: linear-gradient(#ffffff, #F4FBFD);
    &.reverse {
        background: linear-gradient(#F4FBFD, #F9F8F7);
    }
}

.swedbank-loan-options {
    background-color: #F9F2EB;
    padding: 12px;

    &.lv {
        background-color: #F4FBFD;
    }
    &-wrapper {
        max-width: 1058px;
        margin: auto;
    }
    .swedbank-general-info {
        border-radius: 4px;
        background-color: #FEE5CD;
        color: #FA7D05;
    }
}

.lhv-loan-options {
    background-color: #F9F8F7;
    padding: 0px 12px;
    &-wrapper {
        max-width: 1058px;
        margin: auto;
        padding-bottom: 64px;
    }
    .lhv-general-info {
        border-radius: 4px;
        background-color: #f0f0fa;
        color: #5d5fcb;
        width: fit-content;
        margin: 0 auto;
    }
}

.swedbank-widget {
    width: 100%;
    height: 400px;
    max-height: 1000px;
}

.thank-you-page, .discover {    
    padding: 0px;

    &-gradient {
        background: linear-gradient(#F2F2FB, #ffffff);
    }
    &-wrapper {
        max-width: 624px;
        margin: auto;
        text-align: center;
    }
}

.complete-icon {
    border: 1px solid #2F3192 !important;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    color: #2F3192;
    background-color: transparent;
}

.step-icon {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    color: #2F3192;
    background-color: #f0f0fa;

    &.swedbank {
        background-color: #FEE5CD;
        color: #FA7D05;
    }

    &.lhv {
        background-color: #f0f0fa;
        color: #5d5fcb;
    }
}
</style>

<style lang="scss" scoped>
button {
    width: fit-content;
}
:deep(.p-button) {
    font-family: Roboto;
    background: $primary-main;
    border: 1px solid $primary-main;
    color: #ffffff;
    border-radius: 4px;
    padding: 14px 32px;

    &:enabled:hover {
        background: $primary-dark;
        border: 1px solid $primary-dark;
    }
}
.h-screen-96 {
    height: 96vh;
}
</style>
