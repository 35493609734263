<template>
    <EleringFormItem :title="$t('eleringApplication.networkEquipment')" :class="{ 'form-valid' : isNetworkEquipmentValid}" class="hidden">
        <div class="flex flex-row gap-2">
            <div class="flex flex-col flex-1 mb-6 gap-2">     
                <label class="subheading-2" for="idCode">{{ $t('eleringApplication.deviceName') }}</label>
                <InputText
                    id="manufacturersName"
                    type="text"
                    :placeholder="$t('generic.name')"
                    :modelValue="defaultDevice.name"
                    disabled
                />
            </div>
            <div class="flex flex-col flex-initial w-32 mb-6 gap-2">     
                <label class="subheading-2" for="idCode">{{ $t('eleringApplication.quantity') }}</label>
                <div class="flex items-center gap-2">
                <div class="p-inputgroup">
                    <InputText
                        id="power"
                        type="number"
                        :modelValue="defaultDevice.quantity"
                        @update:modelValue="setDefaultDeviceQuantity($event)"
                    />
                    <span class="p-inputgroup-addon">
                        <span class="label">tk</span>
                    </span>
                </div>
                </div>
            </div>
        </div>


        <div v-for="(device, index) of networkEquipment" :key="index" class="flex flex-row gap-2">
            <div class="flex flex-col flex-1 mb-6 gap-2">     
                <label class="subheading-2" for="idCode">{{ $t('eleringApplication.deviceName') }}</label>
                <InputText
                    id="manufacturersName"
                    type="text"
                    :placeholder="$t('generic.name')"
                    :modelValue="device.name"
                    @update:modelValue="setDeviceName(index, $event)"
                />
            </div>
            <div class="flex flex-col flex-initial w-32 mb-6 gap-2">     
                <label class="subheading-2" for="idCode">{{ $t('eleringApplication.quantity') }}</label>
                <div class="flex items-center gap-2">
                <div class="p-inputgroup">
                    <InputText
                        id="power"
                        type="number"
                        :modelValue="device.quantity"
                        @update:modelValue="setDeviceQuantity(index, $event)"
                    />
                    <span class="p-inputgroup-addon">
                        <span class="label">tk</span>
                    </span>
                </div>
                <delete-button @click="deleteDevice(index)" />
                </div>
            </div>
        </div>
        <Button @click="addDevice" class="p-button-secondary w-full">{{ $t("genric.add") }}</Button>
    </EleringFormItem>
</template>

<script>
import InputText from "primevue/inputtext";
import EleringFormItem from "@/components/quoteForm/contentWrappers/EleringFormItem";
import Button from "primevue/button";
import DeleteButton from "@/components/generic/DeleteButton"
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    name: 'DeviceItem',
    components: {
        EleringFormItem,
        DeleteButton,
        InputText,
        Button,
    },
    computed: {
        ...mapState('eleringApplicationModule', [
            'networkEquipment',
        ]),
        ...mapGetters('eleringApplicationModule', [
            'isNetworkEquipmentValid',
            'capacityInProduction',
            'defaultDevice',
        ]),
    },
    methods: {
        ...mapActions('eleringApplicationModule', [
            'addNetworkEquipment',
            'removeNetworkEquipment',
            'updateNetworkDeviceName',
            'updateNetworkDeviceQuantity',
            'updateDefaultDeviceQuantity',
        ]),
        addDevice() {
            this.addNetworkEquipment({ 
                name: "",
                quantity: 1,
                power: this.capacityInProduction,
            })
        },
        deleteDevice(index) {
            this.removeNetworkEquipment(index);
        },
        setDeviceName(index, name) {
            this.updateNetworkDeviceName({ index, name });
        },
        setDeviceQuantity(index, quantity) {
            this.updateNetworkDeviceQuantity({ index, quantity });
        },
        setDefaultDeviceQuantity(quantity) {
            this.updateDefaultDeviceQuantity(quantity);
        }
    },
    props: {
        isHidden: {
            type: Boolean,
            default: false,
        }
    },
}
</script>
