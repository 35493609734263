<template>
  <div class="results">
    <h4 v-if="mode == 'pdf'" class="mb-2">{{ title }}</h4>
    <h3 v-else class="heading mb-5">{{ title }}</h3>
    
    <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div class="calculator__form shadow-md flex flex-col">
            <p class="subheading">
              {{ $t("solarCalculator.investmentNeeded")}}
              <tooltip
                position="right"
                class="information-tooltip"
                :text="$t('solarCalculator.investmentNeeded.tooltip')"
              >
                <i class="information-icon" />
                <template v-slot:tooltip-heading>
                  {{ $t("solarCalculator.investmentNeeded") }}
                </template>
              </tooltip>
            </p>
            <span class="value mt-auto">
                <h1>€ {{ divisionOfProduction.investmentNeeded }}</h1>
                <span class="filler"></span>
            </span>
        </div>

        <div class="calculator__form shadow-md flex flex-col">
            <p class="subheading">{{ $t("solarCalculator.annualConsumptionCost") }}</p>
            <span class="value mt-auto">
                <h1>€ {{ divisionOfProduction.annualConsumptionCost }}</h1>
                <p class="greyed">{{ $t("generic.year") }}</p>
            </span>
        </div>

        <div class="calculator__form shadow-md flex flex-col">
            <p class="subheading">{{ $t("solarCalculator.billAfterInvestment")
            }}<tooltip
              position="right"
              class="information-tooltip"
              :text="$t('solarCalculator.billAfterInvestment.tooltip')"
            >
              <i class="information-icon" />
              <template v-slot:tooltip-heading>
                {{ $t("solarCalculator.billAfterInvestment") }}
              </template>
            </tooltip></p>
            <span class="value mt-auto">
                <h1>€ {{ divisionOfProduction.billAfterInvestment }}</h1>
                <p class="greyed">{{ $t("generic.year") }}</p>
            </span>
        </div>
        
        <div v-if="supportedByLanguage" class="calculator__form shadow-md flex flex-col">
            <p class="subheading">{{ $t("solarCalculator.roi") }}</p>
            <span class="value mt-auto">
                <h1>{{ divisionOfProduction.roi }} %</h1>
                <p class="filler"></p>
            </span>
        </div>
        
        <div class="calculator__form shadow-md flex flex-col">
            <p class="subheading">{{ $t("solarCalculator.roiInYears") }}</p>
            <span class="value mt-auto">
                <h1>{{ divisionOfProduction.roiInYears }}</h1>
                <p class="greyed">{{ $t("generic.years") }}</p>
            </span>
        </div>
        
        <div class="calculator__form shadow-md flex flex-col">
            <p class="subheading">{{ $t("pdf.profitInYear") }}</p>
            <span class="value mt-auto">
                <h1>€ {{ divisionOfProduction.profitInYear }}</h1>
                <p class="greyed">{{ $t("generic.year") }}</p>
            </span>
        </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Tooltip from "../../generic/Tooltip.vue";

export default {
  name: "CalculationResults",
  components: {
    Tooltip,
  },
  props: {
    mode: {
      type: String,
      default: "web"
    },
    title: {
      type: String,
      default: "Results",
    },
  },
  computed: {
    ...mapGetters('solarPanelCalculatorModule', ["divisionOfProduction", "calculated"]),
    supportedByLanguage() {
        return ['et', 'en'].includes(this.$i18n.locale);
    },
  },
};
</script>
<style lang="scss" scoped>
.heading {
    color: #2F3192;
}

</style>

<style lang="scss">
@import "~@/assets/sass/base/variables";

.calculator__form--result-card {
  background-color: #ffffff;
}

td {
  padding-top: 24px;
  padding-bottom: 8px;
}
tr {
  border-bottom: 1px solid $neutral-grey-2;
}
td.flex {
  align-items: center;
}
td.value {
  text-align: right;
  b {
    font-size: 20px;
  }
  .greyed {
    color: #777777;
  }
}
.result-table {
  width: 100%;
}

p.calculator__vat {
  text-align: right;
  margin: 0;
  margin-top: 12px;
  font-style: italic;
  font-size: 12px;
}

.filler {
    display: block;
    height: 21px;
    width: 100%;
}
</style>
