
import { toSvg } from 'html-to-image';
import jsPDF from 'jspdf';
import firebase from 'firebase';

function imageToCanvas(image) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.canvas.width = image.width;
    context.canvas.height = image.height;
    context.drawImage(image, 0, 0, image.width, image.height);

    return canvas;
}

async function convertPagesToImages(pages, width, height) {
    const svgPromises = [];
    pages.forEach((page) => {
        // page has correct (recent) google map address img
        // console.log("MAP image:", page.querySelector(".pdf-general-information").querySelectorAll("section")[1]);
        svgPromises.push(toSvg(page, { backgroundColor: '#FFFFFF' }));
    });
    const svgDataURLs = await Promise.all(svgPromises);
    const jpegPromises = [];
    svgDataURLs.forEach((svgDataURL) => {
        jpegPromises.push(new Promise((resolve) => {
            const image = new Image();
            image.width = width;
            image.height = height;
            image.onload = () => {
                const canvas = imageToCanvas(image);
                resolve(canvas.toDataURL('image/jpeg'));
            };
            image.src = svgDataURL;
        }));
    });
    // old image data present (not flushed)
    return await Promise.all(jpegPromises);
}

export async function generateProjectBreakdownPDF({ pages = [], includePanel=true, includeRoof=true }) {
    if (!Array.isArray(pages) || !pages.length) {
        throw new Error('Pages list is empty');
    }
    const width = 1190;
    
    let height;
    if (includePanel && includeRoof) {
        height = 3600;
    } else {
        height = 2800;
    }
    
    const pageImages = await convertPagesToImages(pages, width, height);
    const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [height, width],
    });
    pageImages.forEach((pageImage, index) => {
        let page = pdf;
        if (index > 0) {
            page = pdf.addPage([height, width], 'portrait');
        }

        page.addImage(pageImage, 'JPEG', 0, 0, width, height, '', 'FAST');
    });
    const fileName = 'RFQ';
    pdf.save(fileName);
    var blob = pdf.output('blob');

    return blob
}

export async function sendLeadEmail({ to, subject, messageBody }) {
    var sendEmail = firebase.functions().httpsCallable('sendEmail');

    const data = {
        from: 'Toode RFQ <rfq@toode.ee>',
        to: to,
        subject: subject,
        html: messageBody, 
    }
    await sendEmail(data);
}

export async function sendProspectEmail({ blob, fileName, to, emailSubject }) {
    var sendEmail = firebase.functions().httpsCallable('sendEmail');
    var storageRef = firebase.storage().ref().child(fileName + '.docx');

    let meta = {
        contentType: blob.type,
    }

    storageRef.put(blob, meta).then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
            const data = {
                from: 'Toode RFQ <rfq@toode.ee>',
                to: to,
                subject: emailSubject,
                html: `<p>Teile on saadetud uus Elering liitumistaotlus. Täpsem info taotleja kohta on kättesaadav liitumistaotluses.<br><a href="${downloadURL}">Link taotlusele</a></p>` 
            }
            sendEmail(data);
        });
    });
}

export async function getDownloadUrl({ pdfBlob, fileName }) {
    var storageRef = firebase.storage().ref().child(fileName + '.pdf');

    try {
        const snapshot = await storageRef.put(pdfBlob)
        const downloadURL = await snapshot.ref.getDownloadURL();
        return downloadURL;
    } catch(e) {
        return '';
    }
}

export async function attachFile(rootFilename, file) {
    return new Promise((resolve) => {
        firebase.storage().ref().child(`${rootFilename}__${file.name}`).put(file).then((snapshot) => {
            snapshot.ref.getDownloadURL().then((downloadURL) => { resolve(downloadURL) })
        })
    })
}
