export default {
    setPanel(state, panel) {
        state.includePanel = panel
    },
    setRoofSlope(state, roofSlope) {
        state.roofSlope = roofSlope
    },
    setRoofDirection(state, roofDirection) {
        state.roofDirection = roofDirection
    },
    setElectricityPrice(state, price) {
        state.electricityPrice = price
    },
    setAnnualHouseholdConsumption(state, annualHouseholdConsumption) {
        state.annualHouseholdConsumption = annualHouseholdConsumption
        state.userInput.inverterPower = undefined
        state.userInput.nrOfPanels = undefined
    },
    setInverterPower(state, inverterPower) {
        state.userInput.inverterPower = inverterPower
        state.userInput.nrOfPanels = Math.ceil(state.userInput.inverterPower * 1000 / state.panelPower)
    },
    setNrOfPanels(state, amount) {
        state.userInput.nrOfPanels = amount
    },
    setArea(state, area) {
        state.areaNeededForPanels = area
    },
    setOwnConsumption(state, ownConsumption) {
        state.ownConsumption = ownConsumption
    },
    setGovernmentSupport(state, support) {
        state.governmentSupport = support
    },
}
