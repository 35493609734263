<template>
  <div class="calculator__form shadow-md calculations">
    <div class="grid grid-cols-1 md:grid-cols-2 bottom-border pb-2">
      <div class="self-center">
        <label class="subheading flex" for="own-consumption"
          >{{ $t('solarCalculator.assumedOwnConsumption') }}</label
        >
      </div>
      <div class="grid grid-cols-2 md:grid-cols-3 gap-2">
          <div class="p-inputgroup col-span-2 md:col-span-1">
            <InputText id="inputOwnConsumption" type="number" :modelValue="ownConsumption" @update:modelValue="inputOwnConsumption" />
            <span class="p-inputgroup-addon">
                <span class="label">%</span>
            </span>
          </div>
          <div class="p-inputgroup p-disabled">
            <InputText id="ownConsumptionPerHour" type="text" disabled :placeholder="calculated.ownConsumptionPerHour" />
            <span class="p-inputgroup-addon disabled">
                <span class="label">kWh</span>
            </span>
          </div>
          <div class="p-inputgroup p-disabled">
            <InputText id="ownConsumptionPrice" type="text" disabled :placeholder="calculated.ownConsumptionPrice" />
            <span class="p-inputgroup-addon disabled">
                <span class="label">EUR</span>
            </span>
          </div>
      </div>
    </div>

    <div v-if="!isLimitedByLanguage" class="grid grid-cols-1 md:grid-cols-2 bottom-border pb-2">
      <div class="self-center">
        <label class="subheading flex" for="area"
          >{{ $t('solarCalculator.assumedSales') }}<tooltip
              position="right"
              class="information-tooltip"
              :text="$t('solarCalculator.assumedSales.tooltip')"
            >
              <i class="information-icon" />
              <template v-slot:tooltip-heading>
                {{ $t('solarCalculator.assumedSales') }}
              </template>
            </tooltip></label
        >
      </div>
       <div class="grid grid-cols-2 md:grid-cols-3 gap-2">
          <div class="p-inputgroup p-disabled col-span-2 md:col-span-1">
            <InputText id="salesBackToNet" type="text" disabled :placeholder="calculated.salesBackToNet" />
            <span class="p-inputgroup-addon disabled">
                <span class="label">%</span>
            </span>
          </div>
          <div class="p-inputgroup p-disabled">
            <InputText id="salesBackToNetPerHour" type="text" disabled :placeholder="calculated.salesBackToNetPerHour" />
            <span class="p-inputgroup-addon disabled">
                <span class="label">kWh</span>
            </span>
          </div>
          <div class="p-inputgroup p-disabled">
            <InputText id="salesBackToNetPrice" type="text" disabled :placeholder="calculated.salesBackToNetPrice" />
            <span class="p-inputgroup-addon disabled">
                <span class="label">EUR</span>
            </span>
          </div>
       </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2" :class="{'bottom-border pb-2': isLimitedByLanguage}">
      <div class="self-center">
        <label class="subheading flex" for="area">{{ $t('solarCalculator.totalProduction') }}</label>
      </div>
      <div class="grid grid-cols-2 md:grid-cols-3 gap-2">
          <div class="p-inputgroup p-disabled col-span-2 md:col-span-1">
            <InputText id="total" type="text" disabled :placeholder="calculated.total" />
            <span class="p-inputgroup-addon disabled">
                <span class="label">%</span>
            </span>
          </div>
          <div class="p-inputgroup mr-2 p-disabled" disabled>
            <InputText id="totalPerHour" type="text" disabled :placeholder="calculated.totalPerHour" />
            <span class="p-inputgroup-addon disabled">
                <span class="label">kWh</span>
            </span>
          </div>
          <div class="p-inputgroup p-disabled">
            <InputText id="totalPrice" type="text" disabled :placeholder="calculated.totalPrice" />
            <span class="p-inputgroup-addon disabled">
                <span class="label">EUR</span>
            </span>
          </div>
      </div>
    </div>

    <div v-if="isLimitedByLanguage" class="grid grid-cols-1 md:grid-cols-2">
      <div class="self-center">
        <label class="subheading flex" for="area">{{ $t('solarCalculator.governmentSupport') }}</label>
      </div>
      <div class="grid grid-cols-2 md:grid-cols-3 gap-2">
          <div class="p-inputgroup col-span-2 md:col-span-3">
            <!-- <InputText id="inputOwnConsumption" type="number" :modelValue="governmentSupport" @update:modelValue="inputSupport" /> -->
            <Dropdown
                :modelValue="governmentSupport"
                @update:modelValue="inputSupport"
                :options="stateAid"
                :placeholder=" $t('genric.selectOne')"
                class="no-border" />
            <span class="p-inputgroup-addon">
                <span class="label">EUR</span>
            </span>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Tooltip from "../../generic/Tooltip.vue";
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: "CalculationField",
    mounted() {
        if (this.isLimitedByLanguage) {
            this.setOwnConsumption(100);
        }
    },
    computed: {
        ...mapState('solarPanelCalculatorModule', [
            'annualHouseholdConsumption',
            'panelPower',
            'ownConsumption',
            'governmentSupport'
        ]),
        ...mapGetters('solarPanelCalculatorModule', ['getOwnConsumption', 'calculated']),
        isLimitedByLanguage() {
            return ['lv', 'lt'].includes(this.$i18n.locale);
        }
    },
    methods: {
        ...mapActions('solarPanelCalculatorModule', ['setOwnConsumption', 'setGovernmentSupport']),
        inputOwnConsumption(e) {
            if (0 > e || e > 100) {
                return;
            }
            this.setOwnConsumption(Number(e));
        },
        inputSupport(e) {
            this.setGovernmentSupport(Number(e));
        },
    },
    data() {
        return {
            stateAid: [700, 1000, 1400, 1800, 2200, 2500, 2800, 3200, 3500, 3800, 4000]
        }
    },
    components: {
        InputText,
        Tooltip, Dropdown
    }
};
</script>
<style lang="scss" scoped>
.calculations {
    label {
        margin: 0 !important;
    }
}
.no-border {
    border: 0;
}
</style>
