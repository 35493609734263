<template>
  <div class="flex flex-col">
    <main-navbar />
    <custom-header />
    <div class="container-fluid">
      <slot />
    </div>
    <custom-footer />
  </div>
</template>

<script>
import MainNavbar from "@/components/navbar/MainNavbar";
import CustomFooter from "@/components/navbar/Footer";
import CustomHeader from "@/components/navbar/Header";
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('houseBuilderModule', ['selectedProductType']),
  },
  components: {
    MainNavbar,
    CustomFooter,
    CustomHeader,
  },
};
</script>
