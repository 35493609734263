export default {
    isSolarPanelIncluded: (state) => { return state.includeSolarPanel },
    rainSystemTypes: (state) => { return state.rainSystemTypes },
    selectedRainSystem: (state) => { return state.selectedRainSystem },
    contactName: (state) => { return state.contactName },
    contactEmail: (state) => { return state.contactEmail },
    phone: (state) => { return state.phone },
    address: (state) => { return state.address },
    getQuoteForm(state) {
        return {
            name: state.contactName,
            email: state.contactEmail,
            selectedRainSystem: state.selectedRainSystem,
            roofArea: state.roofArea == undefined ? 0 : state.roofArea,
            solarPanel: state.includeSolarPanel,
            address: state.address,
            phone: state.phone,
        }
    },
    latlng: (state) => {
        return {
            lat: state.lat,
            lng: state.lng,
        }
    },
};
