export default {
    setIsLiableForApplication({ commit }, isLiable) {
        commit('setIsLiableForApplication', isLiable);
    },
    setApplicantsIdCode({ commit }, idCode) {
        commit('setApplicantsIdCode', idCode);
    },
    setApplicantsContactAddress({ commit }, address) {
        commit('setApplicantsContactAddress', address);
    },
    setRepresentsItself({ commit }, representsItself) {
        commit('setRepresentsItself', representsItself);
    },
    setIsRegisteredAtCenter({ commit }, isRegisteredAtCenter) {
        commit('setIsRegisteredAtCenter', isRegisteredAtCenter);
    },
    setCadastralUnitName({ commit }, cadastralUnitName) {
        commit('setCadastralUnitName', cadastralUnitName);
    },
    setCadastralId({ commit }, cadastralId) {
        commit('setCadastralId', cadastralId);
    },
    setLestX({ commit }, x) {
        commit('setLestX', x);
    },
    setLestY({ commit }, y) {
        commit('setLestY', y);
    },
    setValidContractNr({ commit }, validContractNr) {
        commit('setValidContractNr', validContractNr);
    },
    setEicCode({ commit }, eicCode) {
        commit('setEicCode', eicCode);
    },
    setMeterCode({ commit }, meterCode) {
        commit('setMeterCode', meterCode);
    },
    setJoining({ commit }, joining) {
        commit('setJoining', joining);
    },
    setCapacityInConsumption({ commit }, capacity) {
        commit('setCapacityInConsumption', capacity);
    },
    setCapacityInProduction({ commit }, capacity) {
        commit('setCapacityInProduction', capacity);
    },
    addNetworkEquipment({ commit }, equipment) {
        commit('addNetworkEquipment', equipment);
    },
    removeNetworkEquipment({ commit }, index) {
        commit('removeNetworkEquipment', index);
    },
    updateNetworkDeviceName({ commit }, { index, name }) {
        commit('updateNetworkDeviceName', { index, name })
    },
    updateNetworkDeviceQuantity({ commit }, { index, quantity }) {
        commit('updateNetworkDeviceQuantity', { index, quantity })
    },
    updateDefaultDeviceQuantity({ commit }, quantity) {
        commit('updateDefaultDeviceQuantity', quantity)
    },
    setManufacturerName({ commit }, manufacturerName) {
        commit('setManufacturerName', manufacturerName);
    },
    setPower({ commit }, power) {
        commit('setPower', power);
    },
    setGeneratorType({ commit }, type) {
        commit('setGeneratorType', type);
    },
    setPrimaryEnergySource({ commit }, primaryEnergySource) {
        commit('setPrimaryEnergySource', primaryEnergySource);
    },
    setApplicationComments({ commit }, comments) {
        commit('setApplicationComments', comments);
    },
    setApplicationBlob({ commit }, blob) {
        commit('setApplicationBlob', blob);
    },
}
