import { createI18n } from 'vue-i18n';
import englishTranslations from './_english.json';
import estonianTranslations from './estonian.json';
// import latvianTranslations from './latvian.json';
// import lithuanianTranslations from './lithuanian.json';

const supported_locales = ['en', 'et'];
const getLocale = () => {
    let locale = window.location.pathname.replace(/^\/([^/]+).*/i,'$1');
    if (!supported_locales.includes(locale)) {
        locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'et';
    }
    return locale
}

const i18n = createI18n({
    locale: getLocale(),
    messages: {
        en: englishTranslations,
        et: estonianTranslations,
        // lv: latvianTranslations,
        // lt: lithuanianTranslations,
    },
    fallbackLocale: 'et', //et-EE
});

export default i18n;
