import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const state = {
    isLiableForApplication: true,
    applicantData: {
        idCode: '',
        contactAddress: '',
        representsItself: false,
        representative: 'AS Toode',
    },
    locationDetails: {
        isRegisteredAtCenter: false,
        cadastralUnitName: '',
        cadastralId: '',
        lestCoordinates: {
            x: null,
            y: null,
        },
        validContractNr: '',
        eicCode: '',
        meterCode: '',
    },
    desiredConnection: {
        joining: 'low',
        capacityInConsumption: null,
        capacityInProduction: null,
    },
    networkEquipment: [],
    defaultDeviceQuantity: 1,
    generationModuleData: {
        manufacturerName: '',
        power: null,
        type: 'energyPark',
        primaryEnergySource: 'sun',
    },
    comments: '',
    applicationBlob: null,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
