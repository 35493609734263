import { createStore } from 'vuex';
import HouseBuilderModule from './house-builder';
import SolarPanelCalculatorModule from './solar-panel-calculator';
import QuoteFormDataModule from './quote-form-data';
import EleringApplicationModule from './elering-application';

const store = createStore({
    modules: {
        houseBuilderModule: HouseBuilderModule,
        quoteFormModule: QuoteFormDataModule,
        solarPanelCalculatorModule: SolarPanelCalculatorModule,
        eleringApplicationModule: EleringApplicationModule,
    }
});

export default store;
