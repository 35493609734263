<template>
  <div class="house-breakdown" ref="houseBreakdown">
    <div class="page" v-bind:class="{ page__short: (!includePanel || !includeRoof) }">
      <div class="container pdf-header grid grid-cols-4 pl-3">
        <div class="header-text col-span-4">
          <h3>{{ $t("pdf.greet") }}, {{ contactName }}</h3>
          <p>{{ $t("pdf.intro") }}</p>
        </div>
        <div class="pdf-logo">
          <img src="/img/logo-footer.png" alt="Toode" />
        </div>
      </div>
      <div class="container pdf-general-information">
        <section>
          <h3 class="pdf-title mb-2">{{ $t("pdf.rfq") }}</h3>
          <p class="mb-4" v-if="!includePanel">
            {{ $t("pdf.rfqDescriptionRoofOnly") }}
          </p>
          <p class="mb-4" v-else>
            {{ $t("pdf.rfqDescription") }}
          </p>
          <div class="contact-field col-span-3">
            <table>
              <tr>
                <td class="faded">
                  <p>{{ $t("pdf.propertyAddress") }}</p>
                </td>
                <td class="contact-information">
                  <p>{{ address }}</p>
                </td>
              </tr>
              <tr>
                <td class="faded">
                  <p>{{ $t("generic.name") }}</p>
                </td>
                <td class="contact-information">
                  <p>{{ contactName }}</p>
                </td>
              </tr>
              <tr>
                <td class="faded">
                  <p>{{ $t("generic.phone") }}</p>
                </td>
                <td class="contact-information">
                  <p>{{ phone }}</p>
                </td>
              </tr>
              <tr>
                <td class="faded">
                  <p>{{ $t("generic.email") }}</p>
                </td>
                <td class="contact-information">
                  <p>{{ contactEmail }}</p>
                </td>
              </tr>
              <tr>
                <td class="faded">
                  <p>{{ $t("generic.date") }}</p>
                </td>
                <td class="contact-information">
                  <p>{{ todayDate }}</p>
                </td>
              </tr>
            </table>
          </div>
        </section>
        <section v-if="isCoordinateValid">
          <img :src="mapSource">
        </section>
      </div>
      <div class="container pdf-rfq" v-show="includeRoof">
        <div class="pdf-house-image">
          <house :optionsHidden="true" />
        </div>
        <div class="flex flex-wrap flex-row gap-x-4 gap-y-6">
          <!-- include solar panel -->
          <div class="choice-box">
            <div class="choice-box-header">
              <label>{{ $t("choiceBox.includeSolarPanels") }}</label>
            </div>
            <div class="choice-box-content">
              <h4>
                {{
                includePanel
                ? $t("generic.yes")
                : $t("generic.no")
                }}
              </h4>
            </div>
          </div>
          <!-- roof profile -->
          <div class="choice-box">
            <div class="choice-box-header">
              <label>
                {{ $t("visualEditor.roofType") }}:
                <span class="description">
                  {{ $t("roofProfiles." + roofTypeName) }}
                </span>
              </label>
            </div>
            <div class="choice-box-content">
              <img :src="'/img/roofTypes/' + roofTypeName + '.jpg'" style="filter: grayscale(100%)" />
            </div>
          </div>
          <!-- roof area -->
          <div class="choice-box">
            <div class="choice-box-header">
              <label>{{ $t("rfqForm.roofArea") }}</label>
            </div>
            <div class="choice-box-content">
              <h4>
                {{
                roofArea
                }} m2
              </h4>
            </div>
          </div>
          <!-- preferred RWS -->
          <div class="choice-box">
            <div class="choice-box-header">
              <label>{{ $t("pdf.preferredRST") }}</label>
            </div>
            <div class="choice-box-content">
              <h4 style="text-transform: capitalize;">{{ preferredRwsText }}</h4>
            </div>
          </div>
          <!-- roof color -->
          <div class="choice-box small" :style="{ 'background-color': roofColor }">
            <div class="choice-box-header">
              <label>{{ $t("visualEditor.roofColor") }}</label>
            </div>
            <div class="choice-box-content">
              <p :style="choiceBoxContentColor(roofColor)">
                {{ roofColorName }}
              </p>
            </div>
          </div>
          <!-- wall color -->
          <div class="choice-box small" :style="{ 'background-color': wallColor }">
            <div class="choice-box-header">
              <label>{{ $t("visualEditor.wallColor") }}</label>
            </div>
            <div class="choice-box-content">
              <p :style="choiceBoxContentColor(wallColor)">
                {{ wallColorName }}
              </p>
            </div>
          </div>
          <!-- RWS color -->
          <div class="choice-box small" :style="{ 'background-color': rwsColor }">
            <div class="choice-box-header">
              <label>{{ $t("visualEditor.rwsColor") }}</label>
            </div>
            <div class="choice-box-content">
              <p :style="choiceBoxContentColor(rwsColor)">
                {{ rwsColorName }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container pdf-rfq mb-6" v-show="includePanel">
        <div class="grid grid-cols-1 mt-2 mb-3">
          <div class="calculator-field">
            <calculation-results :title="$t('pdf.investmentOpportunity')" mode="pdf" />
          </div>
        </div>
        <div class="grid grid-cols-1 mt-2 mb-3">
          <div class="calculator-field">
            <calculation-charts mode="pdf" />
          </div>
        </div>
      </div>
      <div class="container pdf-footer">
        <div class="footer-content">
          <table style="width: 100%">
            <tr>
              <td>
                <p class="text-small">
                  <b>{{ $t("footer.informationLine1") }}</b>
                </p>
                <p class="text-small">
                  {{ $t("footer.informationLine2") }}
                </p>
                <p class="text-small">{{ $t("footer.informationLine3") }}</p>
                <p class="text-small">{{ $t("footer.informationLine4") }}</p>
                <br />
                <p class="text-small"><i>powered by Wenture.io</i></p>
              </td>
              <td>
                <p class="text-small">{{ $t("footer.openHours") }}</p>
                <p class="text-small">{{ $t("footer.productGiveout") }}</p>
              </td>
              <td style="text-align: right">
                <img class="footer-logo" src="/img/logo-2.png" alt="Toode" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import House from "@/components/visualEditor/House";
import CalculationResults from "@/components/solarPanelCalculator/elements/CalculationResults";
import CalculationCharts from "@/components/solarPanelCalculator/elements/CalculationCharts";

export default {
  name: "HouseBreakdown",
  components: {
    House,
    CalculationCharts,
    CalculationResults,
  },
  emits: ['houseBreakdownMounted'],
  computed: {
    ...mapState('quoteFormModule', ['contactName', 'contactEmail', 'address', 'roofArea', 'phone']),
    ...mapState('solarPanelCalculatorModule', [
      'includePanel',
    ]),
    ...mapGetters('quoteFormModule', ['selectedRainSystem', 'rainSystemTypes', 'latlng']),
    ...mapGetters('solarPanelCalculatorModule', [
      'getInverterPower',
      'getAnnualHouseholdConsumption',
      'getRoofSlope',
      'getNrOfPanels',
      'getArea',
      'getRoofDirection',
      'calculated',
      'divisionOfProduction',
    ]),
    ...mapGetters('houseBuilderModule', [
      'roofTypeName',
      'roofColor',
      'roofColorName',
      'rwsColor',
      'rwsColorName',
      'wallColor',
      'wallColorName',
      'includeRoof',
    ]),
    todayDate() {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      today.getDate();
      const currentMonth = today.getMonth() + 1;

      return (
        ("0" + today.getDate()).slice(-2) +
        "." +
        ("0" + currentMonth).slice(-2) +
        "." +
        today.getFullYear()
      );
    },
    createdAt() {
      if (this.date) {
        var date = new Date(this.date);
        if (!isNaN(date.getTime())) {
          let day = date.getDate().toString();
          let month = (date.getMonth() + 1).toString();

          return (
            (month[1] ? month : "0" + month[0]) +
            "." +
            (day[1] ? day : "0" + day[0]) +
            "." +
            date.getFullYear()
          );
        }
      }
      return null;
    },
    isCoordinateValid() {
      return this.latlng.lat != null && this.latlng.lng != null
    },
    mapSource() {
      const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap'
      let center = `center=${this.latlng.lat},${this.latlng.lng}`
      const zoom = 'zoom=18'
      let markers = `markers=color:red|${this.latlng.lat},${this.latlng.lng}`
      const size = 'size=522x652'
      const key = `key=${process.env.VUE_APP_FIREBASE_API_KEY}`
      return `${baseUrl}?${center}&${zoom}&${markers}&${size}&${key}&date=${(new Date().getTime())}`
    },
    preferredRwsText() {
      const rwsTypeName = this.rainSystemTypes[this.selectedRainSystem].name
      return this.$t(`pdf.preferredRST.${rwsTypeName}`)
    },
  },
  methods: {
    capitalize(value) {
      const str = String(value);

      return `${str[0].toUpperCase()}${str.substring(1)}`;
    },
    getPageNumber(productNumber) {
      let pageNumber = productNumber + 1;
      let pageQuantity = this.selectedProducts.length + 1;
      return pageNumber + "/" + pageQuantity;
    },
    choiceBoxContentColor(bgColor) {
      return {
        'color': ['#EEEEEE', "#C0C0C0"].includes(bgColor) ? '#000000' : '#EEEEEE',
        'text-transform': 'uppercase',
        'font-weight': 400,
        'font-size': '24px',
      }
    },
  },
  mounted() {
    this.$emit("houseBreakdownMounted");
  }
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0.5rem;
  font-size: 28px;
}

h3 {
  font-size: 48px !important;
}

h4 {
  font-size: 38px !important;
}

.footer-logo {
  height: 120px;
}

p.subtitle {
  font-weight: 400;
  font-size: 30px;
  text-align: center;
}

.faded {
  p {
    color: #777777;
  }
}

td.faded {
  width: 260px;
}

.project-breakdown {
  position: absolute;
  top: 0;
  left: -10000px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background: #ffffff;
}

.pdf-header {
  background-image: url(/img/pdf-header.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pdf-logo {
    position: absolute;
    right: 30px;

    img {
      height: 136px;
    }
  }
}

.header-text {
  color: white;
  width: 100%;
}

.pdf-general-information {
  padding: 32px;
  display: grid;
  grid-template-columns: 1.6fr 1.4fr;
  column-gap: 1rem;
}

.contact-field {

  td {
    padding: 0px;
    padding-bottom: 24px;
  }

  tr {
    border: none !important;
  }
}

.contact-information {
  color: #2f3192;
}

.date {
  margin-left: auto;
}

.page-number {
  position: absolute;
  float: right;
  right: 24px;
  font-size: 24px;
  color: white;
}

.page-number {
  top: 32px;
}

.pdf-rfq {
  padding: 16px;
}

.pdf-title {
  color: #2f3192;
}

.choice-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.choice-row__not-full {
  justify-content: flex-start;

  .choice-box {
    margin-right: 24px;
  }
}

.choice-box {
  width: 264px;
  height: 244px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgb(33 33 33 / 16%);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  label {
    margin-bottom: 0px;
    font-size: 24px;
  }
}

.choice-box.small {
  min-width: 152px;
  width: min-content;
}

.choice-box-header {
  width: 100%;
  height: 144px;
  padding: 0px 24px;
  background-color: #ffffff;
  border-radius: 8px;
  text-align: center;
  display: table;

  label {
    display: table-cell;
    vertical-align: middle;
    font-size: 20px;
    font-weight: 400;
  }

  p {
    display: table-row-group;
    vertical-align: middle;
  }

  .description {
    text-transform: capitalize;
    font-weight: 700
  }

  &.solar {
    background-color: #2f3192;
  }
}

.choice-box-content {
  flex: 1;
  padding: 0 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-height: 140px;
  overflow: auto;
  border-radius: 4px;

  img {
    margin-top: -40px;
    width: 60%;
  }

  .rws-image-pdf {
    height: 100%;
    width: auto;
  }
}

.page {
  display: flex;
  flex-direction: column;
  width: 1190px;
  height: 3600px;
  position: relative;
}

.page__short {
  height: 2800px;
}

.pdf-footer {
  position: absolute;
  bottom: 0;
  width: 100%;

  .footer-content {
    position: relative;
    border-top: 1px solid #d2d2d2;
    margin: 32px;
    padding-top: 20px;

    p.text-small {
      font-size: 20px !important;
      color: #777777;
      margin-bottom: 6px;
    }

    td {
      padding: 0px;
      //   padding-bottom: 12px;
    }

    tr {
      border: none !important;
    }
  }
}

.pdf-house-image {
  margin-bottom: 28px;

  .image-wrapper {
    height: auto;
    filter: drop-shadow(0px 2px 8px rgba(33, 33, 33, 0.32));
  }
}

.pdf-map-view {
  width: 522px;
  height: 652px;
}

.calculator-field {
  div {
    border-radius: 4px;
  }

  .calculator__form {
    background-color: $neutral-white;
    padding: 64px;
  }

  margin-top: .5rem;
  text-align: left;
}

.height-wrap {
  position: relative;
  height: 100%;
}

.maps-wrapper {
  display: flex;
  height: inherit;
}
</style>
