import { COLOR_MAP, WALL_COLORS, ROOF_TYPES } from './constants';

export default {
    house: (state) => { return state.houseTypes[state.selectedHouse] },
    houseTypes: (state) => { return state.houseTypes },
    colorMap: () => { return COLOR_MAP },
    colorMapWall: () => { return WALL_COLORS },
    roofTypes: () => { return ROOF_TYPES },
    currentOption: (state) => { return state.currentOption },
    roofColor: (state) => { return state.roofColor == null ? 'none' : COLOR_MAP[state.roofColor].color },
    roofColorName: (state) => { return state.roofColor == null ? 'Default' : COLOR_MAP[state.roofColor].name },
    roofType: (state) => { return ROOF_TYPES[state.roofType].url },
    roofTypeName: (state) => { return ROOF_TYPES[state.roofType].name },
    rwsColor: (state) => { return state.rwsColor == null ? 'none' : COLOR_MAP[state.rwsColor].color },
    rwsColorName: (state) => { return state.rwsColor == null ? 'Default' : COLOR_MAP[state.rwsColor].name },
    wallColor: (state) => { return state.wallColor == null ? 'none' : WALL_COLORS[state.wallColor].color },
    wallColorName: (state) => { return state.wallColor == null ? 'Default' : WALL_COLORS[state.wallColor].name },
    selectedProductType: (state) => { return state.selectedProductType },
    includeRoof: (state) => { return state.includeRoof },
}
