<template>
  <svg
    v-if="type == 'type3'"
    :class="layer"
    width="3840"
    height="2160"
    viewBox="0 0 3840 2160"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M935 372.5H950.5L2580.5 526.5V572.5C2613.3 602.1 2642.83 602.833 2653.5 599.5L2658.5 535.5C2729.5 541.667 2882.2 555.8 2925 563C2933.8 579.8 2955 584.333 2964.5 584.5L3007 593.5H3019.5L3007 616.5L3048.5 644L3526.5 925.5L3516 939.5L1450 1079.5L935 420L926 403V391V380.5L935 372.5Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1104.5 674.5L1083.5 644H963.5V653.5L716 943V966L970 674.5H1104.5Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-if="type == 'type4'"
    :class="layer"
    width="3840"
    height="2160"
    viewBox="0 0 3840 2160"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1712 699.5L1327 668L1325.5 660.5L1321.5 665L1048.5 422.5L846 709L843 695L1053.5 396.5L1394 448.137M1712 699.5L2032 951.5L3414 944H3417.5L3418.5 939.5C3418.67 929 3417.4 905.6 3411 896L3408 890L3343.5 854.5L2992.5 660.5L2878 639.5L2880.5 636L2905 634L2908.5 629L2909 628L2580.5 567.5V575.5H2578.5H2577.5V649.5V651L2634.5 684.5L2635.5 685.5L2640.5 698.5L2625 690H2622L2625 696.5L2595.5 694L2582.5 685.5H2579.5L2582.5 691L2568 690L2357 562V557L2363 555.5L2435.5 566.5L2446.5 572.5L2458 580L2488 599L2489.5 600V574L1331.5 372.5L1330.5 374L1329.5 380.5L1327.5 384V390.5V392L1296 432M1712 699.5L1394 448.137M1296 432L1310 434L1335 401.5L1394 448.137M1296 432L1394 448.137"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3437 944H3423.5C3423.5 909.6 3418.83 896.333 3416.5 894L3431.5 902C3437.9 908 3437.83 929.5 3437 939.5V944Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3493 945L3443 944C3444.6 923.6 3441.67 909.167 3440 904.5L3500.5 935L3497 939C3496.17 938.667 3494.2 938.5 3493 940.5C3491.8 942.5 3492.5 944.333 3493 945Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-if="type == 'type1'"
    :class="layer"
    width="3840"
    height="2160"
    viewBox="0 0 3840 2160"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M698 99V74.5L1495 262.5L1503 263.5L1506.5 264.5L1513.5 273H1503L1527.5 310L1575.5 319.5L1578.5 321.5L1577 313.5L1583 321.5L1636 332.5L1617 304.5V290L1711 313.5L1466 401V407.5L1447.5 413.5L1457.5 422L1491.5 410L1493.5 412L1666 670.5L1674.5 683.5L1709.5 687L1710 677.5L1717 687L1785.5 695.5V687L1793.5 697.5L1858.5 705.5V696L1866.5 707L1928 714V705L1935 714.5L1994 722.5V713.5L2000 721L2023.5 723.5V726L2034 727.5L2034.5 470.5L2109.5 453L2111 450.5L2114 439L2069.5 397.5L2142.5 415.5L2616 947.5V948.5L2614 950.5L2612.5 952.5L2566.5 949.5L2563.5 948.5L2560.5 949.5L2507 946.5L2506.5 946L2502.5 945L2502 946L2486 945L2485 944L2482 943.5L2480.5 944.5L2402 940.5L2400.5 938.5H2397.5L2396 940L2313.5 935L2312 933.5H2310.5H2308.5L2307.5 934.5L2217 929.5L2216.5 928.5L2216 927.5H2214L2212.5 928L2212 929L2114.5 922.5L2113.5 921.5H2110.5L2109.5 922.5L2004.5 916.5L2002.5 914.5H1999L1998.5 915.5L1885.5 909.5L1884 908H1880L1879 909.5L1756.5 901.5L1755 900L1750 899.5L1749.5 901L1616.5 893L1614.5 891H1609.5L1609 892L1463 883.5L1461.5 882H1455.5L1455 883L1296.5 873.5L1295 871.5L1288 871L1287 872.5L1112.5 862.5V861.5L1111.5 861H1104V861.5L1081.5 860.5V862.5L1065 865.5L698 99Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1665 353L1458.5 420.5L1448 412.5L1466.5 406.5V401L1712 313L1903.5 249L2114 439.5L2110 452L1908 269V267V265V262.5L1905.5 265L1903.5 262.5H1901V265L1665 349V353Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-if="type == 'type2'"
    :class="layer"
    width="3840"
    :fill="fill"
    height="2160"
    viewBox="0 0 3840 2160"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1695 261.5L1577 238H1576L1575 242H1572H1568.5L1489.5 283.5L1474.5 281.5L1473.5 289L1472.5 290L1445 304.5L1430.5 301V313L1365.5 347H1364.5H1359.5L1358 350.5L108 934.5L107 940.5L132.5 933L140.5 930V928L145 927V928L155 925L156.5 922H159L160 923.5L170.5 920L171.5 919V917H173.5L175 918H176L185 915V913.5L187.5 911.5H190.5H193L193.5 912L202.5 908L204.5 905.5H209L211.5 906.5L224 903V901L227.5 898.5H231L232.5 899.5L249 893.5L251 891L253.5 889.5H256L258.5 891L277.5 884.5L280 881.5L282 880H285.5L287 881.5L311 873.5L312.5 871L317.5 869L322 870L350.5 860.5L352.5 858.5L354 857L356.5 856H358.5L361.5 857L397 846L401 841H404.5H408.5L407.5 842.5L454.5 827L456 824.5L458.5 821.5H462L464 822.5H466H470H478L480 824.5H512.5L518 818.5L514.5 810.5H516L523 818.5H527.5V813L3711.5 940.5V938.5L3702.5 933L3710 931.5L3708 928.5L3560 872.5H3566.5L3569 870V867.5H3560V864.5L3545 857.5L3537 864.5L2172 352.5L2170 354L1853 292.5V350.5L1666 316.5L1611 276L1629.5 267L1695 279.5V261.5Z"
    />
    <path d="M527.5 825V819V813L3711 941H3714V946.5L527.5 825Z" />
  </svg>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "RoofContour",
  props: ["type", "fill", "layer"],
};
</script>
<style lang="">
</style>
