<template>
    <section>
        <!-- Applicant Details -->
        <EleringFormItem :open="true" :title="$t('eleringApplication.applicantInfo')"
            :class="{ 'form-valid': isApplicantDetailsValid }">
            <!-- ID Code or Company reg nr. -->
            <div class="flex flex-col mb-6 gap-2">
                <label class="subheading-2" for="idCode">{{ $t('eleringApplication.idCode') }}</label>
                <InputText id="idCode" type="text" :placeholder="$t('eleringApplication.idCode')"
                    :class="{ 'p-invalid': !isIdCodeValid }" :modelValue="applicantData.idCode"
                    @update:modelValue="updateIdCode" @blur="validateIdCode" />
            </div>
            <!-- Name -->
            <div class="flex flex-col mb-6 gap-2">
                <label class="subheading" for="name">{{ $t('generic.name') }}</label>
                <InputText id="name" type="text" :placeholder="$t('generic.fullName')"
                    :class="{ 'p-invalid': !isContactNameValid }" :modelValue="contactName"
                    @update:modelValue="updateContactName" @blur="validateContactName" />
            </div>
            <div class="grid grid-cols-2 gap-4">
                <!-- Email -->
                <div class="flex flex-col mb-6 gap-2">
                    <label class="subheading" for="email">{{ $t('generic.email') }}</label>
                    <InputText id="email" type="text" :placeholder="$t('generic.email')"
                        :class="{ 'p-invalid': !isContactEmailValid }" :modelValue="contactEmail"
                        @update:modelValue="updateContactEmail" @blur="validateContactEmail" />
                </div>
                <!-- Phone -->
                <div class="flex flex-col mb-6 gap-2">
                    <label class="subheading" for="phone">{{ $t('generic.phone') }}</label>
                    <InputText id="phone" type="text" :placeholder="$t('generic.phone')"
                        :class="{ 'p-invalid': !isContactPhoneValid }" :modelValue="phone"
                        @update:modelValue="updateContactPhone" @blur="validateContactPhone" />
                </div>
            </div>
            <!-- Contact Address -->
            <div class="p-field flex flex-col mb-6 gap-2">
                <label class="subheading" for="contact-address">{{ $t('generic.houseAddress') }}</label>
                <InputText
                    id="contact-address"
                    type="text"
                    :placeholder="$t('generic.houseAddress')"
                    :modelValue="address"
                    @update:modelValue="updateContactPlace"
                />
            </div>
            <!-- Object Address Option -->
            <div id="object-address-option" class="flex flex-col mb-6 gap-2">
                <label class="subheading-2" for="object-address-option">{{ $t('eleringApplication.sameAddress')
                }}</label>
                <div class="flex flex-row gap-8">
                    <div class="flex flex-row gap-1">
                        <RadioButton name="isContactAndObjectAddressSame" :value="true"
                            v-model="isContactAndObjectAddressSame" @change="toggleObjectAddress(true)" />
                        <label class="text-medium" for="yes">{{ $t('generic.yes') }}</label>
                    </div>
                    <div class="flex flex-row gap-1">
                        <RadioButton name="isContactAndObjectAddressSame" :value="false"
                            v-model="isContactAndObjectAddressSame" @change="toggleObjectAddress(false)" />
                        <label class="text-medium" for="no">{{ $t('generic.no') }}</label>
                    </div>
                </div>
            </div>
            <!-- Object Address -->
            <div v-if="!isContactAndObjectAddressSame" class="p-field flex flex-col mb-6 gap-2">
                <label class="subheading" for="object-address">{{ $t('eleringApplication.contactAddress') }}</label>
                <InputText
                    id="object-address"
                    type="text"
                    :placeholder="$t('eleringApplication.contactAddress')"
                    :modelValue="applicantData.contactAddress"
                    @update:modelValue="updateObjectPlace"
                />
            </div>
            <!-- Representative -->
            <div class="flex flex-col gap-2">
                <label class="subheading-2" for="solar-panel">{{ $t('eleringApplication.representative') }}</label>
                <div class="grid grid-cols-2 gap-6">
                    <div class="radio-button-wrapper col-span-1 flex flex-row gap-3 cursor-pointer"
                        :class="{ 'selected': !applicantData.representsItself }" @click="updateRepresentation(false)">
                        <RadioButton name="representative" :value="false" v-model="applicantData.representsItself"
                            @change.prevent="updateRepresentation(false)" />
                        <label class="text-medium" for="yes">AS Toode</label>
                    </div>
                    <div class="radio-button-wrapper col-span-1 flex flex-row gap-3 cursor-pointer"
                        :class="{ 'selected': applicantData.representsItself }" @click="updateRepresentation(true)">
                        <RadioButton name="representative" :value="true" v-model="applicantData.representsItself"
                            @change.prevent="updateRepresentation(true)" />
                        <label class="text-medium" for="no">{{ $t('eleringApplication.self') }}</label>
                    </div>
                </div>
            </div>
        </EleringFormItem>

        <!-- Location Details -->
        <EleringFormItem :title="$t('eleringApplication.locationDetails')"
            :class="{ 'form-valid': isLocationDetailsValid }">
            <div class="flex flex-row gap-2">
                <!-- measuring point eic code -->
                <div class="flex flex-col mb-6 gap-2">
                    <label class="subheading-2 flex">
                        {{ $t('eleringApplication.eic') }}
                        <tooltip position="right" class="information-tooltip"
                            :text="$t('eleringApplication.eicCode.tooltip')">
                            <i class="information-icon" />
                            <template v-slot:tooltip-heading>
                                {{ $t('eleringApplication.eic') }}
                            </template>
                        </tooltip>
                    </label>
                    <InputText type="text" :placeholder="$t('eleringApplication.eic')"
                        :modelValue="locationDetails.eicCode" @update:modelValue="updateEicCode" />
                </div>
                <!-- cadastral id -->
                <div class="flex flex-col mb-6 gap-2">
                    <label class="subheading-2">{{ $t('eleringApplication.cadastralId') }}</label>
                    <InputText type="text" class="w-full" :placeholder="$t('eleringApplication.cadastralId')"
                        :modelValue="locationDetails.cadastralId" @update:modelValue="updateCadastralId" />
                </div>
            </div>
        </EleringFormItem>
        
        <!-- Connection Power -->
        <EleringFormItem :title="$t('eleringApplication.networkPower')" :class="{ 'form-valid': isInverterPowerValid }">
            <div class="flex flex-row gap-2">
                <div class="flex flex-col mb-6 gap-2">
                    <label class="subheading-2">{{ $t('eleringApplication.inverterPower') }}</label>
                    <div class="p-inputgroup">
                        <InputText id="networkPower" type="number" :modelValue="getInverterPower"
                            @input="updateInverterPower" />
                        <span class="p-inputgroup-addon">
                            <span class="label">kW</span>
                        </span>
                    </div>
                </div>
            </div>
        </EleringFormItem>
        
        <!-- Desired Connection -->
        <EleringFormItem :title="$t('eleringApplication.desiredConnection')"
            :class="{ 'form-valid': isDesiredConnectionValid }">
            <div class="flex flex-col mb-6 gap-2 hidden">
                <label class="subheading-2" for="solar-panel">{{ $t('eleringApplication.joining') }}</label>
                <div class="grid grid-cols-2 gap-6">
                    <div class="radio-button-wrapper col-span-2 flex flex-row gap-3 cursor-pointer"
                        :class="{ 'selected': desiredConnection.joining === 'low' }" @click="updateJoining('low')">
                        <RadioButton id="low" name="joining" value="low" v-model="desiredConnection.joining"
                            @change="updateJoining('low')" />
                        <label class="text-medium" for="low">{{ $t('eleringApplication.lowVoltage') }}</label>
                    </div>
                </div>
            </div>
            <div class="flex flex-row gap-2">
                <div class="flex flex-col gap-2">
                    <label class="subheading-2 flex">
                        {{ $t('eleringApplication.capacityConsuming') }}
                        <tooltip position="right" class="information-tooltip"
                            :text="$t('eleringApplication.capacityInConsumption.tooltip')">
                            <i class="information-icon" />
                            <template v-slot:tooltip-heading>
                                {{ $t('eleringApplication.capacityConsuming') }}
                            </template>
                        </tooltip>
                    </label>
                    <div class="p-inputgroup">
                        <InputText id="capacityConsuming" type="number" :placeholder="$t('generic.insertNumber')"
                            :modelValue="desiredConnection.capacityInConsumption"
                            @update:modelValue="updateCapacityInConsumption" />
                        <span class="p-inputgroup-addon">
                            <span class="label">A</span>
                        </span>
                    </div>
                </div>
                <div class="flex flex-col gap-2 justify-between">
                    <label class="subheading-2">{{ $t('eleringApplication.capacityProducing') }}</label>
                    <div class="p-inputgroup">
                        <InputText id="capacityProducing" type="number" :placeholder="$t('generic.insertNumber')"
                            :modelValue="capacityInProduction" @update:modelValue="updateCapacityInProduction" />
                        <span class="p-inputgroup-addon">
                            <span class="label">kWh</span>
                        </span>
                    </div>
                </div>
            </div>
        </EleringFormItem>

        <DeviceItem :isHidden="true" />

        <!-- Production Module Data -->
        <EleringFormItem :title="$t('eleringApplication.productionModuleData')"
            :class="{ 'form-valid': isGenerationModuleValid }">
            <div class="flex flex-row gap-2">
                <div class="flex flex-col gap-2 mb-6">
                    <label class="subheading-2" for="manufacturersName">{{ $t('eleringApplication.manufacturersName')
                    }}</label>
                    <InputText id="manufacturersName" type="text" :placeholder="$t('generic.name')"
                        :modelValue="generationModuleData.manufacturersName"
                        @update:modelValue="updateManufacturersName" />
                </div>
                <div class="flex flex-col mb-6 gap-2">
                    <label class="subheading-2" for="power">{{ $t('eleringApplication.power') }}</label>
                    <div class="p-inputgroup">
                        <InputText id="power" type="number" :modelValue="capacityInProduction"
                            @update:modelValue="updateCapacityInProduction" />
                        <span class="p-inputgroup-addon">
                            <span class="label">kWh</span>
                        </span>
                    </div>
                </div>
            </div>

            <div class="flex flex-col mb-6 gap-2 hidden">
                <label class="subheading-2" for="solar-panel">{{ $t('eleringApplication.type') }}</label>
                <div class="grid grid-cols-2 gap-6">
                    <div class="radio-button-wrapper col-span-2 flex flex-row gap-3 cursor-pointer"
                        :class="{ 'selected': generationModuleData.type === 'energyPark' }"
                        @click="updateGeneratorType('energyPark')">
                        <RadioButton id="energyPark" value="energyPark" v-model="generationModuleData.type"
                            @change="updateGeneratorType('energyPark')" />
                        <label class="text-medium" for="energyPark">{{ $t('eleringApplication.energyParkMode')
                        }}</label>
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-2 hidden">
                <label class="subheading-2" for="solar-panel">{{ $t('eleringApplication.primarySource') }}</label>
                <p>{{ $t('eleringApplication.sun') }}</p>
            </div>
        </EleringFormItem>

        <!-- Comments -->
        <div class="w-full flex flex-col gap-2 mt-6 mb-8">
            <label class="subheading-2" for="comments">{{ $t('eleringApplication.additionalNotes') }}</label>
            <Textarea id="comments" type="text" :autoResize="true" rows="4"
                :placeholder="$t('eleringApplication.additionalNotesAndComments')" :modelValue="comments"
                @update:modelValue="updateComment" />
        </div>

        <!-- Submit Button -->
        <div class="flex justify-end mb-10">
            <Button :class="{ 'p-disabled': !isFormValid }" @click="generateApplication">
                <span v-if="!generatingApplication">{{ $t('generic.submit') }}</span>
                <span v-else class="lds-dual-ring"></span>
            </Button>
        </div>
    </section>
</template>

<script>
import * as ApplicationService from '@/services/eleringApplication';
import Button from "primevue/button";
import DeviceItem from "@/components/quoteForm/contentWrappers/DeviceItem";
import EleringFormItem from "@/components/quoteForm/contentWrappers/EleringFormItem";
import InputText from "primevue/inputtext";
import RadioButton from "primevue/radiobutton";
import Textarea from 'primevue/textarea';
import Tooltip from "@/components/generic/Tooltip";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "ApplyForm",
    components: {
        Button,
        DeviceItem,
        EleringFormItem,
        InputText,
        RadioButton,
        Textarea,
        Tooltip,
    },
    data() {
        return {
            isContactAddressValid: true,
            isContactAndObjectAddressSame: true,
            isContactEmailValid: true,
            isContactNameValid: true,
            isContactPhoneValid: true,
            isIdCodeValid: true,
            isObjectAddressValid: true,
            generatingApplication: false,
        }
    },
    computed: {
        ...mapGetters('eleringApplicationModule', [
            'applicantData',
            'capacityInProduction',
            'comments',
            'desiredConnection',
            'generationModuleData',
            'isDesiredConnectionValid',
            'isGenerationModuleValid',
            'isLocationDetailsValid',
            'isNetworkEquipmentValid',
            'locationDetails',
            'applicationData',
        ]),
        ...mapGetters('solarPanelCalculatorModule', [
            'getInverterPower',
            'isInverterPowerValid',
        ]),
        ...mapGetters('quoteFormModule', [
            'address',
            'contactEmail',
            'contactName',
            'phone',
        ]),
        isApplicantDetailsValid() {
            const idCodeValid = this.applicantData.idCode != null && this.applicantData.idCode != '';
            // console.log('idCodeValid', idCodeValid)
            const nameValid = this.contactName != null && this.contactName != '' && this.isContactNameValid;
            // console.log('nameValid', nameValid)
            const emailValid = this.contactEmail != null && this.contactEmail != '' && this.isContactEmailValid;
            // console.log('emailValid', emailValid)
            const contactAddressValid = this.address != '';
            // console.log('contactAddressValid', contactAddressValid)
            const objectAddressValid = this.applicantData.contactAddress != '';
            // console.log('objectAddressValid', objectAddressValid)
            return (idCodeValid && nameValid && emailValid && contactAddressValid && objectAddressValid);
        },
        isFormValid() {
            return (
                this.isApplicantDetailsValid
                && this.isDesiredConnectionValid
                && this.isGenerationModuleValid
                && this.isLocationDetailsValid
                && this.isInverterPowerValid
                && this.isNetworkEquipmentValid
            )
        }
    },
    methods: {
        ...mapActions('eleringApplicationModule', [
            'setApplicantsContactAddress',
            'setApplicantsIdCode',
            'setApplicationComments',
            'setCadastralId',
            'setCapacityInConsumption',
            'setCapacityInProduction',
            'setEicCode',
            'setGeneratorType',
            'setJoining',
            'setManufacturerName',
            'setRepresentsItself',
        ]),
        ...mapActions('solarPanelCalculatorModule', [
            'setInverterPower',
        ]),
        ...mapActions('quoteFormModule', [
            'setAddress',
            'setEmail',
            'setLatLng',
            'setName',
            'setPhone',
        ]),
        ...mapActions('houseBuilderModule', ['setSelectedProductType']),
        toggleObjectAddress(value) {
            if (value) {
                this.setApplicantsContactAddress(this.address);
                this.isContactAndObjectAddressSame = true;
            } else {
                this.setApplicantsContactAddress('');
                this.isContactAndObjectAddressSame = false;
            }
        },
        updateIdCode(idCode) {
            this.setApplicantsIdCode(idCode);
        },
        updateContactName(contactName) {
            if (typeof contactName == 'string') {
                contactName = contactName.trim();
            }
            this.setName(contactName);
        },
        updateContactEmail(contactEmail) {
            this.setEmail(contactEmail);
        },
        updateContactPhone(contactPhone) {
            this.setPhone(contactPhone);
        },
        updateContactPlace(address) {
            this.setAddress(address);
            if (this.isContactAndObjectAddressSame) {
                this.setApplicantsContactAddress(address)
            }
        },
        updateObjectPlace(address) {
            this.setApplicantsContactAddress(address)
        },
        updateRepresentation(representsItself) {
            this.setRepresentsItself(representsItself);
        },
        updateCadastralId(id) {
            this.setCadastralId(id);
        },
        updateEicCode(eicCode) {
            this.setEicCode(eicCode);
        },
        updateJoining(joining) {
            this.setJoining(joining);
        },
        updateCapacityInConsumption(capacity) {
            this.setCapacityInConsumption(capacity);
        },
        updateCapacityInProduction(capacity) {
            this.setCapacityInProduction(capacity);
        },
        updateInverterPower(e) {
            this.setInverterPower(e.target.value);
        },
        updateManufacturersName(name) {
            this.setManufacturerName(name);
        },
        updateGeneratorType(type) {
            this.setGeneratorType(type);
        },
        updateComment(comment) {
            this.setApplicationComments(comment);
        },
        validateIdCode() {
            if (this.applicantData.idCode == null || this.applicantData.idCode == '') { this.isIdCodeValid = false; }
            else { this.isIdCodeValid = true; }
        },
        validateContactName() {
            if (this.contactName == null || this.contactName == '') { this.isContactNameValid = false; }
            else { this.isContactNameValid = true; }
        },
        validateContactEmail() {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.isContactEmailValid = re.test(this.contactEmail);
        },
        validateContactPhone() {
            this.isContactPhoneValid = /^\d+$/.test(this.phone);
        },
        validateContactAddress() {
            if (this.address == null || this.address == '') { this.isContactAddressValid = false; }
            else { this.isContactAddressValid = true; }
        },
        validateObjectAddress() {
            if (this.applicantData.contactAddress == null || this.applicantData.contactAddress == '') { this.isObjectAddressValid = false; }
            else { this.isObjectAddressValid = true; }
        },
        async generateApplication() {
            const dataset = this.applicationData;
            this.generatingApplication = true;
            ApplicationService.createApplication(dataset, '/files/liitumistaotlus.docx');
            setTimeout(() => {
                this.generatingApplication = false;
                this.$router.push({ path: `/${this.$i18n.locale}/thank-you`, hash: '#liitu' });
            }, 3000);
        },
    },
    mounted() {
        this.setSelectedProductType('');
        this.setApplicantsContactAddress(this.address);
    },
}
</script>

<style lang="scss" scoped>
.address-search {
    padding: 14px 16px;
    border-radius: 4px;

    &:hover {
        border: none;
        border: 1px solid #2f3192;
    }

    box-shadow: 0px 2px 8px rgba(76, 80, 85, 0.16);
}

.input-invalid {
    border: 1px solid #f44336;
}

.radio-button-wrapper {
    border: 1px solid #D2D2D2;
    padding: 12px 16px;
    border-radius: 4px;

    &.selected {
        background-color: #F0F0FA;
        border-color: #5D5FCB;
    }
}

.p-inputgroup {
    border-radius: 4px !important;

    .p-inputtext {
        border-radius: 4px 0px 0px 4px !important;
        border-right: 0px;
        height: 100%;
        padding-right: 0 !important;
    }

    .p-disabled,
    .p-component:disabled {
        opacity: 1;
        background: #efefef;
        border-color: #ced4da;
    }

    .p-inputgroup-addon {
        background-color: #ffffff;
        font-size: 14px;

        .label {
            padding: 3px 8px;
            color: #2F3192;
            border-radius: 4px;
            background: #F0F0FA;
        }
    }

    .p-inputgroup-addon.disabled {
        background-color: #efefef !important;

        .label {
            color: #777777;
            background: #D2D2D2;
        }
    }
}

.p-inputgroup:not(.p-disabled):hover {
    transition: 0.2s;
    border-color: #2f3192;
}

.p-inputgroup.p-disabled {
    background: #efefef;
    border-color: #efefef;
    opacity: 1;
}

.p-inputgroup {
    border: 1px solid #ced4da;
    transition: 0.2s;

    .p-inputtext,
    .p-inputgroup-addon,
    .p-inputgroup-addon:last-child {
        border: none;
    }
}

.p-inputgroup:focus {
    box-shadow: 0 0 0 0.2rem rgba(#2f3192, 0.4) !important;
    border-color: #2f3192 !important;
}

.p-inputtext {
    height: auto !important;
    border-radius: 4px !important;
    padding: .8rem !important;
}

.p-inputgroup-addon {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.p-inputtext {
    border: 1px solid #ced4da;
}

.p-inputtext:enabled:hover {
    border-color: #2f3192 !important;
}

.p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.2rem rgba(#2f3192, 0.4) !important;
    border-color: #2f3192 !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #2f3192 !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.2rem rgba(#2f3192, 0.4) !important;
    border-color: #2f3192 !important;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: 0 0 0 0.2rem rgba(#2f3192, 0.4) !important;
    border-color: #2f3192 !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #2f3192 !important;
    background: #2f3192 !important;
}

.p-button {
    color: #ffffff;
    width: 102px;
    height: 54px;
}

.lds-dual-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 48px;
    height: 48px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring.purple:after {
    border: 6px solid #2f3192;
    border-color: #2f3192 transparent #2f3192 transparent;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>