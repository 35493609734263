<template>
    <div>
        <div v-if="!showAll">
            <FAQItem
                v-for="(qa, index) of faq.slice(0, 5)"
                :key="index" :class="{ 'mt-2' : index !== 0 }"
                :title="$t(`faq.${qa.translationKey}.question`)"
                :content="$t(`faq.${qa.translationKey}.answer`)" />
        </div>
        <div v-else>
            <FAQItem
                v-for="(qa, index) of faq"
                :key="index"
                :class="{ 'mt-2' : index !== 0 }"
                :title="$t(`faq.${qa.translationKey}.question`)"
                :content="$t(`faq.${qa.translationKey}.answer`)" />
        </div>
        <div class="flex justify-center mt-4">
            <button v-if="!showAll" class="link underline cursor-pointer lowercase" @click="toggleShow">{{ $t('generic.showMore') }}</button>
            <button v-else class="link underline cursor-pointer lowercase" @click="toggleShow">{{ $t('generic.showLess') }}</button>
        </div>
    </div>
</template>

<script>
import FAQItem from '@/components/quoteForm/contentWrappers/FAQItem';
import FAQService from '@/services/faq';

export default {
    name: "FAQWrapper",
    components: {
        FAQItem,
    },
    methods: {
        toggleShow() {
            this.showAll = !this.showAll;
        }
    },
    data() {
        return {
            faq: [],
            showAll: false,
        }
    },
    created() {
        this.faq = FAQService.getFAQTranslations().filter((key) => this.$t(`faq.${key.translationKey}.question`) !== '-');
    },
}
</script>
