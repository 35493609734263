<template>
    <div class="cardinal-directions">
        <svg width="248" height="248" viewBox="0 0 248 248" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M124.218 189.171C106.81 189.171 90.4442 182.393 78.136 170.082C65.8279 157.774 59.0469 141.406 59.0469 124C59.0469 106.594 65.8256 90.2261 78.136 77.918C90.4465 65.6098 106.812 58.8289 124.218 58.8289C141.624 58.8289 157.992 65.6075 170.3 77.918C182.608 90.2261 189.389 106.594 189.389 124C189.389 141.406 182.611 157.774 170.3 170.082C157.992 182.393 141.626 189.171 124.218 189.171ZM124.218 65.3894C108.562 65.3894 93.845 71.486 82.7746 82.5565C71.7041 93.6269 65.6074 108.344 65.6074 124C65.6074 139.656 71.7041 154.373 82.7746 165.444C93.845 176.514 108.562 182.611 124.218 182.611C139.874 182.611 154.591 176.514 165.662 165.444C176.732 154.373 182.829 139.656 182.829 124C182.829 108.344 176.732 93.6269 165.662 82.5565C154.591 71.486 139.874 65.3894 124.218 65.3894Z" fill="#EFEFEF"/>
            <path @click="changeDirection('n')" d="M130.999 104.318H117.001L124 32.1482L130.999 104.318Z" fill="#D1D2F1" class="compass-option" :class="{ selected: getRoofDirection == 'n' }"/>
            <path @click="changeDirection('s')" d="M130.999 143.682H117.001L124 215.852L130.999 143.682Z" fill="#D1D2F1" class="compass-option" :class="{ selected: getRoofDirection == 's' }"/>
            <path @click="changeDirection('ne')" d="M142.866 115.031L132.969 105.134L188.948 59.0516L142.866 115.031Z" class="compass-option" fill="#D1D2F1" :class="{ selected: getRoofDirection == 'ne' }"/>
            <path @click="changeDirection('sw')" d="M115.031 142.866L105.134 132.969L59.0515 188.948L115.031 142.866Z" class="compass-option" fill="#D1D2F1" :class="{ selected: getRoofDirection == 'sw' }"/>
            <path @click="changeDirection('e')" d="M143.682 130.999V117.001L215.852 124L143.682 130.999Z" fill="#D1D2F1" class="compass-option" :class="{ selected: getRoofDirection == 'e' }"/>
            <path @click="changeDirection('w')" d="M104.318 130.999V117.001L32.1481 124L104.318 130.999Z" fill="#D1D2F1" class="compass-option" :class="{ selected: getRoofDirection == 'w' }"/>
            <path @click="changeDirection('se')" d="M132.969 142.866L142.866 132.969L188.948 188.948L132.969 142.866Z" class="compass-option" fill="#D1D2F1" :class="{ selected: getRoofDirection == 'se' }"/>
            <path @click="changeDirection('nw')" d="M105.134 115.031L115.031 105.134L59.0515 59.0516L105.134 115.031Z" class="compass-option" fill="#D1D2F1" :class="{ selected: getRoofDirection == 'nw' }"/>
            <path d="M124.218 147.838C137.383 147.838 148.056 137.165 148.056 124C148.056 110.835 137.383 100.162 124.218 100.162C111.053 100.162 100.38 110.835 100.38 124C100.38 137.165 111.053 147.838 124.218 147.838Z" fill="#2F3193"/>
            <path d="M124.218 142.807C134.605 142.807 143.025 134.387 143.025 124C143.025 113.613 134.605 105.193 124.218 105.193C113.831 105.193 105.411 113.613 105.411 124C105.411 134.387 113.831 142.807 124.218 142.807Z" fill="white"/>
            <path d="M124.218 137.996C131.948 137.996 138.214 131.73 138.214 124C138.214 116.27 131.948 110.004 124.218 110.004C116.488 110.004 110.222 116.27 110.222 124C110.222 131.73 116.488 137.996 124.218 137.996Z" fill="#2F3193"/>
            <path :class="{ selected: getRoofDirection == 'n' }" @click="changeDirection('n')" d="M129.302 22.8573H126.611L121.373 14.2646V22.8573H118.682V9.79599H121.373L126.62 18.4071V9.79599H129.302V22.8573Z" fill="#777777"/>
            <path :class="{ selected: getRoofDirection == 'e' }" @click="changeDirection('e')" d="M237.678 123.69H232.511V127.206H238.576V129.366H229.822V116.307H238.56V118.489H232.514V121.6H237.68V123.69H237.678Z" fill="#777777"/>
            <path :class="{ selected: getRoofDirection == 's' }" @click="changeDirection('s')" d="M126.292 232.755C126.292 232.247 126.113 231.857 125.754 231.584C125.396 231.313 124.749 231.026 123.816 230.723C122.884 230.422 122.145 230.123 121.6 229.829C120.117 229.028 119.375 227.949 119.375 226.592C119.375 225.887 119.575 225.257 119.972 224.704C120.37 224.151 120.941 223.719 121.685 223.409C122.429 223.099 123.265 222.943 124.193 222.943C125.125 222.943 125.956 223.113 126.687 223.45C127.417 223.788 127.982 224.265 128.386 224.881C128.79 225.496 128.992 226.197 128.992 226.98H126.301C126.301 226.383 126.113 225.917 125.736 225.586C125.359 225.255 124.829 225.088 124.149 225.088C123.49 225.088 122.98 225.228 122.615 225.505C122.25 225.783 122.069 226.151 122.069 226.605C122.069 227.03 122.282 227.386 122.709 227.673C123.137 227.96 123.766 228.229 124.597 228.481C126.129 228.943 127.242 229.513 127.943 230.195C128.643 230.877 128.992 231.726 128.992 232.741C128.992 233.871 128.565 234.757 127.709 235.4C126.854 236.043 125.702 236.365 124.255 236.365C123.249 236.365 122.335 236.181 121.511 235.814C120.686 235.446 120.057 234.943 119.623 234.303C119.189 233.662 118.973 232.92 118.973 232.077H121.674C121.674 233.52 122.535 234.238 124.257 234.238C124.898 234.238 125.396 234.107 125.754 233.848C126.113 233.588 126.292 233.223 126.292 232.755Z" fill="#777777"/>
            <path :class="{ selected: getRoofDirection == 'w' }" @click="changeDirection('w')" d="M20.6965 125.601L22.4555 116.307H25.1376L22.2396 129.369H19.53L17.4036 120.631L15.2773 129.369H12.5676L9.672 116.307H12.3541L14.1222 125.582L16.2761 116.307H18.5541L20.6965 125.601Z" fill="#777777"/>
        </svg>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: "Compass",
    methods: {
        ...mapActions('solarPanelCalculatorModule',
            ['setRoofDirection']
        ),
        changeDirection(direction) {
            this.setRoofDirection(direction)
        }
    },
    computed: {
        ...mapGetters('solarPanelCalculatorModule', [
            'getRoofDirection'
        ])
    },
    data() {
        return {
            currentDirection: this.getRoofDirection
        }
    }
}
</script>
<style lang="scss">
@import "~@/assets/sass/base/variables";

path.selected {
    fill: $primary-main;
}

path.compass-option {
    cursor: pointer;
}
    
</style>
