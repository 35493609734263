export const SLOPE_IMPACT_MAP = {
    "0": 0.95,
    "15": 0.8,
    "30": 0.95,
    "45": 0.99,
    "60": 0.8,
    "90": 0.7
}

export const DIRECTION_IMPACT_MAP = {
    "s": 1,
    "n": 0.5,
    "e": 0.8,
    "w": 0.8,
    "ne": 0.65,
    "nw": 0.65,
    "se": 0.9,
    "sw": 0.9
}
