<template>
  <div class="calculator__form roof">
    <h4 class="heading">{{ $t('solarCalculator.mainSection') }}</h4>
    <div>
      <label class="subheading">{{ $t('solarCalculator.roofSlope') }}</label>
      <slope-angle-options class="mt-2 mb-6" />
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
      <div class="flex flex-col justify-between">
        <label class="subheading mb-2" for="ahc">{{ $t('rfqForm.roofArea') }}</label>
        <div class="p-inputgroup">
          <InputText
            id="roofArea"
            type="number"
            :value="roofArea"
            @input="inputRoofArea"
          />
          <span class="p-inputgroup-addon"><span class="label">m2</span></span>
        </div>
      </div>
    </div>
    <div>
      <label class="subheading mb-2"
        >{{ $t('solarCalculator.roofDirection') }}</label
      >
      <compass class="mb-4" />
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
      <div class="flex flex-col justify-between">
        <label class="subheading mb-2" for="ahc">{{ $t('solarCalculator.annualConsumption') }}</label>
        <div class="p-inputgroup">
          <InputText
            id="ahc"
            type="number"
            :value="getAnnualHouseholdConsumption"
            @input="inputHouseholdConsumption"
          />
          <span class="p-inputgroup-addon"><span class="label">kWh</span></span>
        </div>
      </div>
      <div class="flex flex-col justify-between">
        <label class="subheading flex mb-2" for="price">
            {{ $t('solarCalculator.electricityPrice') }}<tooltip position="right" class="information-tooltip" :text="$t('solarCalculator.electricityPrice.tooltip')">
            <i class="information-icon" />
            <template v-slot:tooltip-heading>
                {{ $t('solarCalculator.electricityPrice') }}
            </template>
        </tooltip>
        </label>
        <div class="p-inputgroup">
          <InputText
            id="price"
            type="number"
            class="p-as-end"
            :value="getElectricityPrice"
            @input="inputPrice"
          />
          <span class="p-inputgroup-addon"><span class="label">EUR</span></span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
      <div class="flex flex-col justify-between">
        <label class="subheading mb-2" for="invertedPower">{{ $t('solarCalculator.inverterPower') }}</label>
        <div class="p-inputgroup">
          <InputText
            id="invertedPower"
            type="number"
            :value="getInverterPower"
            @input="inputInverterPower"
          />
          <span class="p-inputgroup-addon">
              <span class="label">kW</span>
            </span>
        </div>
      </div>
      <div class="flex flex-col justify-between">
        <label class="subheading mb-2" for="panelPower">{{ $t('solarCalculator.panelPower') }}</label>
        <div class="p-inputgroup p-disabled">
          <InputText
            id="panelPower"
            type="number"
            disabled
            :placeholder="getPanelPower"
          />
          <span class="p-inputgroup-addon disabled">
              <span class="label">W</span>
          </span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
      <div class="flex flex-col justify-between">
        <label class="subheading mb-2" for="nrOfPanels">{{ $t('solarCalculator.noOfPanels') }}</label>
        <div class="p-inputgroup">
          <InputText
            id="nrOfPanels"
            type="number"
            :value="getNrOfPanels"
            @input="inputPanelAmount"
          />
          <span class="p-inputgroup-addon">
              <span class="label">tk</span>
          </span>
        </div>
      </div>
      <div class="flex flex-col justify-between">
        <label class="subheading mb-2" for="area">{{ $t('solarCalculator.areaNeeded') }}</label>
        <div class="p-inputgroup p-disabled">
          <InputText id="area" type="number" disabled :placeholder="getArea" />
          <span class="p-inputgroup-addon disabled">
              <span class="label">m2</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputText from "primevue/inputtext";
import SlopeAngleOptions from "./SlopeAngleOptions";
import Compass from "./Compass.vue";
import Tooltip from "../../generic/Tooltip.vue"
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "RoofField",
  props: {
    modelValue: Boolean,
  },
  components: {
    InputText,
    SlopeAngleOptions,
    Compass,
    Tooltip
  },
  computed: {
    ...mapState('solarPanelCalculatorModule', ["annualHouseholdConsumption", "panelPower"]),
    ...mapState('quoteFormModule', ['roofArea']),
    ...mapGetters('solarPanelCalculatorModule', [
      "getAnnualHouseholdConsumption",
      "getPanelPower",
      "getInverterPower",
      "getNrOfPanels",
      "getArea",
      "getElectricityPrice",
    ]),
  },
  methods: {
    ...mapActions('solarPanelCalculatorModule', [
      "setAnnualHouseholdConsumption",
      "setInverterPower",
      "setNrOfPanels",
      "setElectricityPrice",
    ]),
    ...mapActions('quoteFormModule', ['setRoofArea']),
    inputHouseholdConsumption(e) {
      this.setAnnualHouseholdConsumption(e.target.value);
    },
    inputInverterPower(e) {
      this.setInverterPower(e.target.value);
    },
    inputPrice(e) {
      this.setElectricityPrice(e.target.value);
    },
    inputPanelAmount(e) {
      this.setNrOfPanels(e.target.value);
    },
    inputRoofArea(e) {
        this.setRoofArea(e.target.value);
    }
  },
};
</script>
<style lang="scss">
</style>
