<template>
    <div class="calculator__form shadow-md results">
     <div class="result__section p-pt-6 p-pb-4 text-center">
          <h4 class="heading">
            {{ $t("solarCalculator.productionDivision") }}
          </h4>
          <div class="flex flex-wrap justify-around">
            <div>
              <gauge
                :amount="calculated.totalPerHour"
                color="#194F15"
                min="0"
                :max="calculated.totalPerHour * 1.2"
                val="kWh"
                :label="$t('solarCalculator.totalProduction')"
              />
            </div>
            <div v-if="supportedByLanguage">
              <gauge
                :amount="calculated.salesBackToNetPerHour"
                color="#5D5FCB"
                min="0"
                :max="calculated.totalPerHour"
                val="kWh"
                :label="$t('solarCalculator.salesBackToNetwork')"
              />
            </div>
            <div>
              <gauge
                :amount="divisionOfProduction.roi"
                color="#F59B1C"
                min="0"
                max="100"
                :label="$t('solarCalculator.roi')"
                val="%"
              />
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Gauge from "./Gauge.vue";

export default {
    name: 'CalculationCharts',
    components: {
        Gauge,
    },
    computed: {
        ...mapGetters('solarPanelCalculatorModule', ["divisionOfProduction", "calculated"]),
        supportedByLanguage() {
            return ['et', 'en'].includes(this.$i18n.locale);
        }
    },
    props: {
      mode: {
        type: String,
        default: 'web',
      }
    }
}
</script>