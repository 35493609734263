<template>
  <div v-if="!hideOverlay" class="editor-overlay" @scroll.prevent>
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
    <div v-show="hideOverlay" class="container-fluid limit-height">
      <house @loadingFinished="removeOverlay()" />
    </div>
    <div
      class="container-fluid flex justify-between items-center options-row"
    >
      <div class="option-picker-wrapper">
        <OptionPicker />
      </div>
      <div class="flex gap-16 my-4">
        <RainWaterSystemSelector />
      </div>
    </div>
</template>
<script>
import OptionPicker from "@/components/visualEditor/OptionPicker";
import House from "@/components/visualEditor/House";
import RainWaterSystemSelector from "@/components/visualEditor/RainWaterSystemSelector";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "VisualEditor",
  components: {
    OptionPicker,
    House,
    RainWaterSystemSelector,
  },
  data() {
    return {
      includeSolarPanel: true,
      load: true,
      hideOverlay: false,
    };
  },
  methods: {
    ...mapActions(["setSolarPanel"]),
    removeOverlay() {
      this.load = false;
    },
  },
  watch: {
    load: function (o, n) {
      if (n != o) {
        this.hideOverlay = true;
      }
    },
  },
  computed: {
    ...mapGetters(["isSolarPanelIncluded"]),
  },
};
</script>
<style lang="scss">
.limit-height {
  max-height: 80vh;
  position: relative;
  overflow: hidden;
}
.option-picker-wrapper {
  display: flex;
  overflow-x: auto;
  margin: 20px 0px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.editor-overlay {
  height: 80vh;
  width: 100%;
  //   position: absolute;
  background: #ffffff;
  z-index: 40;
  display: flex;
  align-items: center;
  overflow: hidden;

  .lds-ring {
    display: flex;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #2f3192;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #2f3192 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
