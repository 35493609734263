export const COLOR_MAP =
    [{
        name: 'RR11',
        color: '#004800'
    },
    {
        name: 'RR22',
        color: '#EEEEEE'
    },
    {
        name: 'RR23',
        color: '#4F4F4F'
    },
    {
        name: 'RR29',
        color: '#970000'
    },
    {
        name: 'RR32',
        color: '#473832'
    },
    {
        name: 'RR33',
        color: '#000000'
    },
    {
        name: 'RR37',
        color: '#245B29'
    },
    {
        name: 'RR750',
        color: '#943912'
    }
    ]

export const WALL_COLORS = [
    {
        name: 'RR24',
        color: '#F7DB00'
    },
    {
        name: 'RR25',
        color: '#FCBE2E'
    },
    {
        name: 'RR30',
        color: '#705950'
    },
    {
        name: 'RR20',
        color: '#EEEEEE'
    },
    {
        name: 'RR29',
        color: '#970000'
    },
    {
        name: 'RR750',
        color: '#943912'
    },

    {
        name: 'RR31',
        color: '#473832'
    },
    {
        name: 'RR21',
        color: '#C0C0C0'
    },
    {
        name: 'RR23',
        color: '#4F4F4F'
    },
    {
        name: 'RR34',
        color: '#97C6EC'
    },
    {
        name: 'RR36',
        color: '#87A460'
    }
]

export const ROOF_TYPES = [
    {
        name: 'classic',
        url: 'CLASSIC_ROOF',
    },
    {
        name: 'ss',
        url: 'STANDING_SEAM_ROOF'
    },
    {
        name: 'tiled',
        url: 'TILED_ROOF'
    },
    {
        name: 'tp20',
        url: 'TP20_ROOF'
    }
]
