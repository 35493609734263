<template>
  <div>
    <div :class="['roof', 'solarPanels', 'roofWithPanels'].includes(selectedProductType) ? 'px-2 mt-32' : 'hidden'">
      <SegmentedButtons v-model:buttons="segmentedButtons" @update:segmentedButtons="onUpdateSegmentedButtons" />
    </div>
    <div
      :class="['roof', 'roofWithPanels'].includes(selectedProductType) ? 'row visual-editor px-2 lg:visual-editor-padding mt-12' : 'visual-editor-hidden'">
      <visual-editor />
    </div>
    <div
      :class="['solarPanels', 'roofWithPanels'].includes(selectedProductType) ? 'row solar-panel-calculator mt-12' : 'solar-hidden'">
      <solar-panel-calculator />
    </div>
    <div :class="['roof', 'solarPanels', 'roofWithPanels'].includes(selectedProductType) ? 'row rfq-form' : 'hidden'">
      <quote-form :key="mountNumbers" @startFromBeginning="onStartFromBeginning" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MetaInfo from "@/mixins/MetaInfo";
import VisualEditor from "@/components/visualEditor/VisualEditor";
import SolarPanelCalculator from "@/components/solarPanelCalculator/SolarPanelCalculator";
import QuoteForm from "@/components/quoteForm/QuoteForm";
import SegmentedButtons from "@/components/generic/SegmentedButtons.vue";

export default {
  name: "Home",
  mixins: [MetaInfo],
  components: {
    VisualEditor,
    SolarPanelCalculator,
    QuoteForm,
    SegmentedButtons,
  },
  data() {
    return {
      title: "Welcome to Homepage",
      meta: [{ description: "this is meta description" }],
      skeletonData: [{ name: "...loading...", items: [] }],
      segmentedButtons: [
        {
          name: "roof",
          oldName: "roof",
          text: this.$t('landing.solarCalculator.roof'),
          id: 1,
          selected: false,
        },
        {
          name: "fullSolution",
          oldName: "roofWithPanels",
          text: this.$t('landing.solarCalculator.fullSolution'),
          id: 2,
          selected: true,
        },
        {
          name: "panels",
          oldName: "solarPanels",
          text: this.$t('landing.solarCalculator.panels'),
          id: 3,
          selected: false,
        },
      ],
      mountNumbers: 0,
    };
  },
  methods: {
    ...mapActions('houseBuilderModule', ['setSelectedProductType', 'setRoof']),
    ...mapActions('solarPanelCalculatorModule', ['setPanel']),
    ...mapActions('quoteFormModule', ['resetQuoteFormState']),
    onUpdateSegmentedButtons() {
      const selectedButton = this.segmentedButtons.find(btn => btn.selected);
      this.showProductModule(selectedButton.oldName);
    },
    onStartFromBeginning() {
      // TODO: reset SVG cache for services/pdf-generation/index.js:toSvg()
      // this.resetQuoteFormState();
      this.mountNumbers++;
    },
    setSegmentedButtonState(productType) {
      this.segmentedButtons.forEach(btn => {
        btn.selected = btn.oldName === productType ? true : false;
      });
    },
    showProductModule(productType) {
      switch (productType) {
        case "roofWithPanels":
          this.setPanel(true);
          this.setRoof(true);
          break;
        case "roof":
          this.setPanel(false);
          this.setRoof(true);
          break;
        case "solarPanels":
          this.setPanel(true);
          this.setRoof(false);
          break;
      }
      this.$router.push({ path: `/${this.$route.params.locale}/${productType}` })
    },
  },
  computed: {
    ...mapGetters('solarPanelCalculatorModule', ['includePanel']),
    ...mapGetters('houseBuilderModule', ['selectedProductType']),
    pageLoading() {
      return (
        this.houseList.length == 0 || this.roofList.length == 0
      );
    },
  },
  mounted() {
    let productType = this.$route.params.productType;
    
    if (typeof productType === 'string') {
      productType = productType.replace(/-[a-z]/g, m => m.toUpperCase()).replaceAll("-", "")
    }
      this.setSelectedProductType(productType);
      this.setSegmentedButtonState(productType);
      // fullSolution
      if (productType === "roofWithPanels") {
        this.setPanel(true);
        this.setRoof(true);
      }
      // roof
      else if (productType === "roof") {
        this.setPanel(false);
        this.setRoof(true);
      }
      // panels
      else if (productType === "solarPanels") {
        this.setPanel(true);
        this.setRoof(false);
      }
      // landing page
      else {
        this.setPanel(false);
        this.setRoof(false)
      }
      window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";

.row.solar-panel-calculator {
  margin-left: auto;
  margin-right: auto;
  background-color: $neutral-grey-0;
  padding-top: 60px;
  padding-bottom: 60px;
  padding: 60px 14px;
}

.visual-editor-padding {
  padding-left: 160px;
  padding-right: 160px;
}

.visual-editor {
  margin-left: auto;
  margin-right: auto;
}

.visual-editor-hidden,
.solar-hidden {
  display: flex;
  height: 0;
  position: relative;
  overflow: hidden;
}

.rfq-form {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

h4 {
  font-family: "Roboto";
  text-align: center;
}
</style>
