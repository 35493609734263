<template>
    <div class="faq-section container-fluid py-6 px-4 shadow-md mt-6">
        <h3 class="heading mb-3">{{ $t('faq.frequentlyAskedQuestions') }}</h3>
        <p class="mb-4">{{ $t('faq.frequentlyAskedQuestions') }}</p>
        <FAQWrapper />
    </div>
</template>

<script>
import FAQWrapper from '@/components/quoteForm/contentWrappers/FAQWrapper';

export default {
    name: 'FAQSection',
    components: {
        FAQWrapper
    }
}
</script>

<style lang="scss" scoped>
.faq-section {
    border-radius: 6px;
    height: fit-content;
}
</style>
