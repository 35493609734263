<template>
  <div v-if="text !== null" class="tooltip-box" @mouseleave="closeTooltip">
    <span @mouseenter="openTooltip">
      <slot />
    </span>
    <div ref="tooltip" class="tooltip" :class="positionClass">
      <div class="tooltip-content">
        <p class="tooltip-heading"><slot name="tooltip-heading" /></p>
        <p>{{ text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    position: {
      type: String,
      default: "right",
    },
    text: {
      type: String,
    },
  },
  data() {
    return {
      show: false,
      overflow: false,
    };
  },
  computed: {
    positionClass() {
      return {
        top: this.position == "top",
        bottom: this.position == "bottom" || this.overflow,
        left: this.position == "left",
        right: this.position == "right" && !this.overflow,
        hidden: this.show == false,
      };
    },
  },
  methods: {
    openTooltip() {
      this.tooltipOverflow();
      this.show = true;
    },
    closeTooltip() {
      this.tooltipOverflow();
      this.show = false;
    },
    tooltipOverflow() {
      if (
        this.$refs.tooltip.getBoundingClientRect().right > window.innerWidth
      ) {
        this.overflow = true;
      } else {
        this.overflow = false;
      }
    },
  },
};
</script>

<style lang="scss">
.tooltip-box {
  position: relative;
  display: inline-block;
  margin-left: 4px;
}

.tooltip {
  position: absolute;
  cursor: default;
  display: flex;
  flex-direction: column;
  z-index: 20;
  align-items: center;
  min-height: 20px;
  width: max-content;
  max-width: 250px;
  background-color: #ffffff;
  border-radius: 4px;
  font-size: 12px;
  font-family: "Roboto";
  color: #4c5055;
  box-shadow: 0px 2px 8px rgba(76, 80, 85, 0.16);
  opacity: 1;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
}

.tooltip.hidden {
  visibility: hidden;
  opacity: 0;
}

.tooltip-heading {
  font-weight: bold;
}

.tooltip-heading i.close-tooltip {
  display: flex;
  cursor: pointer;
  width: 14px;
  height: 14px;
  /* background-image: url('../../assets/icons/singular/cross_light.svg'); */
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 3px;
}

.tooltip-content {
  padding: 12px 8px;
  position: relative;
  border: 1px solid rgba(#4c5055, 0.1);
  border-radius: 2px;
  width: 100%;
}

.tooltip.top {
  bottom: 100%;
  left: 50%;
  margin-left: -30px;
  margin-bottom: 5px;
}

.tooltip.bottom {
  top: 100%;
  right: 0%;
  margin-right: -150px;
  margin-top: 3px;
}

.tooltip.right {
  top: -60%;
left: 100%;
margin-left: 7px;
}

.tooltip.left {
  bottom: 0%;
  right: 100%;
  margin-right: 5px;
}

.tooltip.right .tooltip-content::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0px;
  margin-left: -12px;
  top: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-right-color: rgba(#4c5055, 0.1);
}

.tooltip.right .tooltip-content::after {
  content: " ";
  display: block;
  position: absolute;
  margin-left: -18px;
  top: 11px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-right-color: #ffffff;
}

.information-tooltip {
  .information-icon {
    // visibility: hidden;
    display: flex;
    cursor: pointer;
    width: 15px;
    height: 15px;
    background-image: url("../../assets/info_outline-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
}
</style>
