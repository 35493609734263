<template>
    <div class="segmentedButtons">
        <div v-for="button in buttons" :key="button.id" :class="[{ buttonActive: button.selected }, 'button']">
            <button @click="onButtonClick(button.name)">{{ button.text }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "SegmentedButtons",
    props: {
        buttons: {
            type: Array,
            required: true,
        },
    },
    emits: ["update:segmentedButtons"],
    methods: {
        onButtonClick(btnName) {
            this.buttons.forEach(btn =>
                btn.selected = btn.name === btnName ? true : false
            );
            this.$emit("update:segmentedButtons", this.buttons);
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
.segmentedButtons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;

    border-radius: 80px;
    background-color: $primary-main;
    margin: auto;
    max-width: 46rem;
    height: 3rem;
}

.button {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    width: 9rem;
    align-items: center;

    border-radius: 80px;
    height: 2rem;
    background-color: $primary-main;
    color: $neutral-white;
}

.button button {
    width: 100%;
    cursor: pointer;
}
.button:first-child {
    margin-left: 0.6rem;
}

.button:last-child {
    margin-right: 0.6rem;
}

.buttonActive {
    background-color: $primary-background;
    color: $neutral-grey-4;
    width: 9rem;
}
</style>