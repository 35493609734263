export default {
    setPanel({ commit }, panel) {
        commit('setPanel', panel)
    },
    setRoofSlope({ commit }, roofSlope) {
        commit('setRoofSlope', roofSlope)
    },
    setRoofDirection({ commit }, roofDirection) {
        commit('setRoofDirection', roofDirection)
    },
    setElectricityPrice({ commit }, price) {
        commit('setElectricityPrice', price)
    },
    setInverterPower({ commit }, inverterPower) {
        commit('setInverterPower', inverterPower)
    },
    setNrOfPanels({ commit }, amount) {
        commit('setNrOfPanels', amount)
    },
    setAnnualHouseholdConsumption({ commit }, annualHouseholdConsumption) {
        commit('setAnnualHouseholdConsumption', annualHouseholdConsumption)
    },
    setOwnConsumption({ commit }, ownConsumption) {
        commit('setOwnConsumption', ownConsumption)
    },
    setDefaultOwnConsumption({ commit }, locale) {
        if (['lv', 'lt'].includes(locale)) {
            commit('setOwnConsumption', 100);
        } else {
            commit('setOwnConsumption', 40)
        }
    },
    setGovernmentSupport({ commit }, support) {
        commit('setGovernmentSupport', support)
    }
}
