<template>
    <div id="quoteForm" class="container mx-auto mt-10 px-2">
        <h3 class="heading mb-5">{{ $t('rfqForm.title') }}</h3>
        <ProgressBar :currentStep="currentStep" :steps="accessibleSteps" />
        <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
            <LeadForm v-if="currentStep === 1" class="col-span-1" @nextStep="nextStep()" />
            <InterestForm v-if="currentStep === 2" class="col-span-1" @nextStep="nextStep()" @previousStep="previousStep()" />
            <ProspectForm v-if="currentStep === 3" class="col-span-1" @nextStep="nextStep()" @previousStep="previousStep()" />
            <FAQSection v-if="currentStep === 3" class="col-span-1" />
        </div>
    </div>
</template>

<script>
import ProgressBar from './ProgressBar.vue';
import LeadForm from './steps/LeadForm.vue';
import InterestForm from './steps/InterestForm.vue';
import ProspectForm from './steps/ProspectForm.vue';
import FAQSection from './steps/FAQSection.vue';
import { mapState } from 'vuex';

export default {
    name: 'QuoteForm',
    components: {
        ProgressBar,
        LeadForm,
        InterestForm,
        ProspectForm,
        FAQSection,
    },
    emits: ['startFromBeginning'],
    methods: {
        nextStep() {
            const step = this.accessibleSteps.find((step) => step.index === this.currentStep);
            if (!step) {
                return;
            }
            step.complete = true;
            
            const stepNext = this.accessibleSteps.find((step) => step.index === this.currentStep + 1);
            if (!stepNext) {
                this.$router.push(`/${this.$i18n.locale}/thank-you`);
                return;
            }

            this.currentStep += 1;
        },
        previousStep() {
            this.currentStep -= 1;
            const step = this.steps.find((step) => step.index === this.currentStep);
            if (!step) {
                return;
            }
            if (this.currentStep === 1)
                this.$emit("startFromBeginning");

            step.complete = false;
        },
    },
    computed: {
        ...mapState('solarPanelCalculatorModule', ['includePanel']),
        accessibleSteps() {
            if (!this.includePanel || ['lv', 'lt'].includes(this.$i18n.locale)) {
                return this.steps.filter((item) => item.index !== 3);
            }
            return this.steps;
        }
    },
    data() {
        return {
            currentStep: 1,
            steps: [
                {
                    index: 1,
                    title: this.$t('rfqForm.requirements'),
                    complete: false,
                },
                {
                    index: 2,
                    title: this.$t('rfqForm.receiveQuote'),
                    complete: false,
                },
                {
                    index: 3,
                    title: this.$t('rfqForm.technicalApplication'),
                    complete: false,
                    accessibleFor: ['et', 'en']
                }
            ],
        }
    }
}
</script>

<style lang="scss" scoped>
.heading {
    color: #2F3192;
}

.rfq-form > .container {
  text-align: center;
  max-width: 1140px !important;
}
</style>
