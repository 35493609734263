export default {
    isApplicantDataValid(state) {
        return state.applicantData.idCode !== '';
    },
    isLocationDetailsValid(state) {
        return state.locationDetails.eicCode;
    },
    isDesiredConnectionValid() {
        return true;
    },
    isNetworkEquipmentValid(state) {
        const invalidDevice = state.networkEquipment.find((device) => device.name === '');

        if (invalidDevice) {
            return false;
        }
        return true;
    },
    isGenerationModuleValid(state) {
        return state.generationModuleData.manufacturerName !== ''
    },
    capacityInProduction(state, _getters, _rootState, rootGetters) {
        const power = Number(rootGetters['solarPanelCalculatorModule/getInverterPower']) * 1.1;
        if (power > 15) {
            return 15;
        } else {
            return power.toFixed(1);
        }
    },
    defaultDevice(state, getters, _rootState, rootGetters) {
        return {
            name: `Huawei Sun 2000 ${rootGetters['solarPanelCalculatorModule/getInverterPower']}KTL-M2`,
            quantity: state.defaultDeviceQuantity,
            power: getters.capacityInProduction,
        }
    },
    networkEquipment(state, getters) {
        const equipment = [getters.defaultDevice];

        return equipment.concat(state.networkEquipment);
    },
    applicantData(state) { return state.applicantData },
    locationDetails(state) { return state.locationDetails },
    desiredConnection(state) { return state.desiredConnection },
    generationModuleData(state) { return state.generationModuleData },
    comments(state) { return state.comments },
    applicationData(state, getters, rootState, rootGetters) {
        return {
            ...state,
            quoteData: rootGetters['quoteFormModule/getQuoteForm'],
            joining: 'madal pingel',
            capacityInConsumption: {
                value: state.desiredConnection.capacityInConsumption || '',
                unit: state.desiredConnection.capacityInConsumption ? 'A' : '',
            },
            capacityInProduction: getters.capacityInProduction,
            generationModule: {
                type: 'Energiapargi moodul',
                power: getters.capacityInProduction,
                manufacturerName: state.generationModuleData.manufacturerName,
                primaryEnergySource: 'päike',
            },
            networkEquipment: getters.networkEquipment,
        }
    }
}
