<template>
  <div class="container solar-panel-calculator mx-auto" ref="solarPanelCalculator">
    <div class="p-col-12 solar-panel__description">
      <h2 class="mb-6">{{ $t('solarCalculator.title') }}</h2>
      <p class="mb-6">
        {{ $t('solarCalculator.description') }}
      </p>
      <a href="#quoteForm"><Button>{{ $t('rfqForm.title') }}</Button></a>
    </div>
    <div class="grid lg:grid-cols-2 md:grid-cols-1 gap-2">
      <div class="w-full calculator-field">
        <roof-field class="shadow-md" />
      </div>
      <div class="w-full calculator-field shadow-md hidden md:block" >
        <XGiz v-if="$i18n.locale==='et'" />
        <Map v-else />
      </div>
    </div>

    <div class="grid grid-cols-1 mt-2 mb-3">
      <div class="calculator-field">
        <calculation-field />
      </div>
    </div>

    <div class="grid grid-cols-1 mt-2 mb-3">
      <div class="calculator-field">
        <calculation-results mode="web" />
      </div>
    </div>

    <div class="grid grid-cols-1 mt-2">
      <div class="calculator-field">
        <calculation-charts mode="web" />
      </div>
    </div>
  </div>
</template>
<script>
import CalculationField from "./elements/CalculationField.vue";
import CalculationResults from "./elements/CalculationResults";
import CalculationCharts from "./elements/CalculationCharts";
import RoofField from "./elements/RoofField.vue";
import Map from './elements/Map.vue';
import XGiz from './elements/XGiz';
import Button from 'primevue/button';


export default {
  name: "SolarPanelCalculator",
  components: {
    CalculationResults,
    CalculationCharts,
    CalculationField,
    RoofField,
    Button,
    Map,
    XGiz,
  },
};
</script>
<style lang="scss">
@import "~@/assets/sass/base/variables";
.container.solar-panel-calculator {
  text-align: center;
  max-width: 1140px !important;
}

.solar-panel__description {
    text-align: center;
    margin-bottom: 56px;
}
.p-inputgroup {
    border-radius: 4px !important;
  .p-inputtext {
    border-radius: 4px 0px 0px 4px !important;
    border-right: 0px;
    height: 100%;
    padding-right: 0 !important;
  }
  .p-disabled,
  .p-component:disabled {
    opacity: 1;
    background: #efefef;
    border-color: #ced4da;
  }
  .p-inputgroup-addon {
    background-color: #ffffff;
    font-size: 14px;
    .label {
        padding: 3px 8px;
        color: #2F3192;
        border-radius: 4px;
        background: #F0F0FA;
    }
  }
  .p-inputgroup-addon.disabled {
    background-color: #efefef !important;
    .label {
        color: #777777;
        background: #D2D2D2;
    }
  }
}

.p-inputgroup:not(.p-disabled):hover {
    transition: 0.2s;
    border-color: #2f3192;
}

.p-inputgroup.p-disabled {
    background: #efefef;
    border-color: #efefef;
    opacity: 1;
}

.p-inputgroup {
    border: 1px solid #ced4da;
    transition: 0.2s;
    .p-inputtext, .p-inputgroup-addon, .p-inputgroup-addon:last-child {
        border: none;
    }
}

.p-inputgroup:focus {
    box-shadow: 0 0 0 0.2rem rgba(#2f3192, 0.4) !important;
    border-color: #2f3192 !important;
}

.p-inputtext {
  height: auto !important;
  border-radius: 4px !important;
  padding: .8rem !important;
}
.p-inputgroup-addon {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.p-inputtext:enabled:hover {
    border-color: #2f3192 !important;
}

.p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.2rem rgba(#2f3192, 0.4) !important;
    border-color: #2f3192 !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #2f3192 !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.2rem rgba(#2f3192, 0.4) !important;
    border-color: #2f3192 !important;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: 0 0 0 0.2rem rgba(#2f3192, 0.4) !important;
    border-color: #2f3192 !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #2f3192 !important;
    background: #2f3192 !important;
}

.calculator-field {
  div {
    border-radius: 4px;
  }
  .calculator__form {
    background-color: $neutral-white;
    padding: 32px;

    @media screen and (max-width: 767px) {
      padding: 5px;
    }
  }
  margin-top: .5rem; 
  text-align: left;
}

.calculator__form {
  .heading {
    margin-bottom: 2rem;
  }
}

.bottom-border {
  border-bottom: 1px solid $neutral-grey-2;
  margin-bottom: 8px;
  border-radius: 0 !important;
}

.calculator__map {
  height: 100%;
}

.inAadressLogo {
  display: none;
}

#inAadressMap {
  height: 100%;
}

#inAadressMapMap {
  height: 100% !important;
  position: absolute !important;
  width: 100% !important;
}

.inaadressSearchContainer {
  position: absolute !important;
  z-index: 4000;
  top: 4px !important;
  right: 10px;
  width: 50%;
  display: flex;
  .inaadressSearch {
    width: 100% !important;
  }
  .ui-input-search input {
    padding-left: 2.5em;
  }
  .ui-input-text input,
  .ui-input-search input {
    margin: 0;
    min-height: 2.5em;
  }
  .ui-input-search {
    font-size: 14px;
  }
  .ui-focus {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border: 1px solid #2f3192;
  }
  .ui-input-search:after {
    left: 0.7em;
  }
  .inads_spinner {
    top: 18px;
  }
}
</style>
