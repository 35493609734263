import { saveAs } from 'file-saver';
import docxtemplater from 'docxtemplater';
import store from '@/store/';
import * as PDFGenerationService from "@/services/pdf-generation";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";

function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
}

export function createApplication(data, url, params = { locale: "ET", emailSubject: "[Application] Uus liitumistaotlus" }) {
    loadFile(url, async function(
            error,
            content
        ) {
        if (error) {
            throw error;
        }
        const zip = new PizZip(content);
        const doc = new docxtemplater(zip);
        let dataset = transformData(data);
        doc.setData(dataset);

        try {
            doc.render();
        } catch (error) {
            let e = {
                message: error.message,
                name: error.name,
                stack: error.stack,
                properties: error.properties,
            }
            console.log(JSON.stringify({error: e}));
            throw error;
        }
        const out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });
        saveWordFile(out);
        store.dispatch('eleringApplicationModule/setApplicationBlob', out);
        await sendEmail(out, data.applicantData.idCode, params);
      });
    }

export function saveWordFile(blob) {
    saveAs(blob, `[Elering]Technical-application.docx`);
}

async function sendEmail(blob, id, params = { locale: "ET", emailSubject: "[Application] Uus liitumistaotlus" }) {
    var d = new Date();
    const fileName = "Elering_" + id + "_" + d.getTime();

    const to = {
        'PRODUCTION': process.env.VUE_APP_CONTACT_EMAIL_ET,
        'STAGING': process.env.VUE_APP_CONTACT_EMAIL_STAGING,
        'DEV': process.env.VUE_APP_CONTACT_EMAIL_TEST,
    }[process.env.VUE_APP_ENV]

    await PDFGenerationService.sendProspectEmail({ blob: blob, fileName: fileName, to: to, emailSubject: params.emailSubject });
}

function transformData(data) {
    const transformed = {
        "contact": {
            "fullName": data.quoteData.name,
            "address": data.quoteData.address,
            "phone": data.quoteData.phone,
            "email": data.quoteData.email,
        },
        "representative": {
            "fullName": data.representsItself ? data.quoteData.name : 'Toode AS',
            "phone": data.representsItself ? data.quoteData.phone : '6599400',
            "email": data.representsItself ? data.quoteData.email : 'solar@toode.ee',
        },
        "applicantData": {
            "idCode": data.applicantData.idCode,
            "contactAddress": data.applicantData.contactAddress,
        },
        "objectAddress": data.quoteData.address,
        "cadastralUnit": {
            "id": data.locationDetails.cadastralId,
        },
        "eicCode": data.locationDetails.eicCode,
        "joining": data.joining,
        "capacityInConsumption": data.capacityInConsumption,
        "capacityInProduction": data.capacityInProduction,
        "networkDevices": data.networkEquipment,
        "generationModuleData": {
            "manufacturerName": data.generationModule.manufacturerName,
            "type": data.generationModule.type,
            "power": data.generationModule.power,
            "primaryEnergySource": data.generationModule.primaryEnergySource,
        },
        "comments": data.comments,
    }
    return transformed;
}

