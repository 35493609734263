<template>
    <div class="flex gap-3">
        <Button
            v-for="(editOption, i) in editOptions"
            v-bind:class="{ 'selected': current != i}"
            :key="i"
            :label="editOption"
            @click="setCurrent(i)"
            class="option-btn" />
    </div>
</template>
<script>
import Button from 'primevue/button';
import { mapActions } from 'vuex';

export default {
    name: "OptionPicker",
    computed: {
        editOptions() {
            return [this.$i18n.t('visualEditor.houseType'), this.$i18n.t('visualEditor.roofType'), this.$i18n.t('visualEditor.roofColor'), this.$i18n.t('visualEditor.rwsColor'), this.$i18n.t('visualEditor.wallColor')]}
    },
    data() {
        return {
            current: 0
        }
    },
    methods: {
        ...mapActions('houseBuilderModule', ["setCurrentOption"]),
        setCurrent(i) {
            this.current = i;
            this.setCurrentOption(i);
        }
    },
    components: {
        Button
    },
}
</script>
<style lang="scss" scoped>
</style>
