<template>
    <div class="container faq-wrapper p-4">
        <div class="flex justify-between items-center cursor-pointer" :class="showAnswer ? 'expand' : 'collapse'" @click="expandOrCollapseAnswer">
            <h5 class="faq-title">{{ title }}</h5>
            <i class="pi pi-chevron-down" />
        </div>
        <div class="flex" :class="{ 'hidden': !showAnswer }">
            <p class="text-medium faq-answer mt-4">
                {{ content }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FAQItem',
    props: {
        title: {
            type: String,
            required: false,
        },
        content: {
            type: String,
            required: false,
        },
    },
    data() {
        return  {
            showAnswer: false,
        }
    },
    methods: {
        expandOrCollapseAnswer() {
            this.showAnswer = !this.showAnswer;
        },
    },
}
</script>

<style lang="scss" scoped>
.faq-wrapper {
    background-color: #EFEFEF;
    border-radius: 8px;

    .faq-title {
        color: #474747;
    }

    .faq-answer {
        color: #777777;
    }
}

:deep(.pi-chevron-down) {
    font-size: 14px;
}

.collapse{
    :deep(.pi-chevron-down) {
        transform: rotate(0deg);
        transition: transform .2s linear;
    }
}

.expand {
    :deep(.pi-chevron-down) {
        transform: rotate(-180deg);
        transition: transform .2s linear;
    }
}
</style>
