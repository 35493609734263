<template>
    <div class="flex flex-wrap">
        <div
            v-for="(slopeOption, i) in slopeOptions"
            :key="i"
            v-bind:class="{ 'active': getRoofSlope == slopeOption }"
            @click="changeRoofSlope(slopeOption)" 
            class="slope-option-btn mr-3 mb-3">
            <img :src="'/img/angles/' + slopeOption + '.svg'"/>
            <span>{{ slopeOption + '°' }}</span>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: "SlopeAngleOptions",
    methods: {
        ...mapActions('solarPanelCalculatorModule',
            ['setRoofSlope']
        ),
        changeRoofSlope(slope) {
            this.setRoofSlope(slope)
        }
    },
     computed: {
        ...mapGetters('solarPanelCalculatorModule', [
            'getRoofSlope'
        ])
    },
    data() {
        return {
            slopeOptions: ['0', '15', '30', '45', '60', '90'],
        }
    },
    components: {
    },
}
</script>
<style lang="scss">
@import "~@/assets/sass/base/variables";

.slope-option-btn, .rainsystem-option-btn {
    align-items: center;
    color: $neutral-grey-2;
    border: 1px solid $neutral-grey-2;
    border-radius: 4px;
    flex-direction: column;
    display: flex;
    cursor: pointer;
}

.slope-option-btn {
    padding: 8px 16px;
}

.rainsystem-option-btn {
    padding: 0px;
    border: 1px solid $neutral-grey-2;
    width: 64px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slope-option-btn.active, .rainsystem-option-btn.active {
    font-weight: bold;
    color: $primary-main;
    border: 1px solid $primary-main;
}

.rainsystem-option-btn.active {
    border: 1px solid $primary-main;
}

</style>
