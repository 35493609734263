<template>
<div class="height-wrap">
    <GMapAutocomplete
        :placeholder="$t('generic.houseAddress')"
        @place_changed="setPlace"
        class="address-search"
        >
    </GMapAutocomplete>
    <div class="maps-wrapper">
        <GMapMap
            :center="center"
            :zoom="14"
            class="mapz"
            ref="myMapRef"
            :disableDefaultUI="false"
        >
            <GMapMarker
                :position="markerPosition"
                :clickable="false"
            />
        </GMapMap>
    </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'App',
  data() {
    return {
        markerPosition: {
            lat: 0,
            lng: 0,
        },
        center: { lat: 0, lng: 0 },
    }
  },
  created() {
    const loc = this.$i18n.locale;

    if (['en', 'et'].includes(loc)) {
        this.center = { lat: 59.4370, lng: 24.7536 };
        this.markerPosition = { lat: 59.4370, lng: 24.7536 };
    } else if (loc === 'lv') {
        this.center = { lat: 56.9496, lng: 24.1052 };
        this.markerPosition = { lat: 56.9496, lng: 24.1052 };
    } else if (loc === 'lt') {
        this.center = { lat: 54.6872, lng: 25.2797 };
        this.markerPosition = { lat: 54.6872, lng: 25.2797 };
    } else {
        this.center = { lat: 59.4370, lng: 24.7536 };
        this.markerPosition = { lat: 59.4370, lng: 24.7536 };
    }
  },
  methods: {
    ...mapActions('quoteFormModule', ['setAddress', 'setLatLng']),
    setPlace(place) {
        this.markerPosition = place.geometry.location;
        this.center = place.geometry.location;
        this.setAddress(place.formatted_address);
        this.setLatLng(place.geometry.location);
    }
  }
}
</script>

<style lang="scss" scoped>
.height-wrap {
    position: relative;
    height: 100%;
}
.maps-wrapper {
    display: flex;
    height: inherit;
}
.mapz {
    width: 100%;
}

.address-search {
    position: absolute;
    z-index: 1000;
    bottom: 24px;
    left: 12px;
    padding: 14px 16px;
    border-radius: 4px;
    width: 360px;
    &:hover, &:active, &:focus {
        border: none;
        border: 1px solid #2f3192;
    }
    box-shadow: 0px 2px 8px rgba(76, 80, 85, 0.16);
}
</style>
