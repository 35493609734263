<template>
  <div class="image-wrapper">
    <div v-show="loading" class="loading">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <img
      :src="'/img/house/' + house + '/OVERALL.png'"
      class="main-house"
      v-show="showHouse"
      v-cloak
      @load="
        {
          showHouse = true;
          showRoof = true;
          $emit('loadingFinished');
        }
      "
      @loadstart="
        {
          showHouse = false;
        }
      "
    />
    <div class="color-changer">
      <div class="colors" v-bind:class="isFirefox ? 'no-svg' : ''">
        <img
          :src="
            '/img/house/' + house + '/roofs/' + roofType + solarShadow + '.png'
          "
          class="roof-layer"
          v-show="showRoof"
          @load="loading = false"
        />

        <roof-contour
          :fill="roofColor != 'none' ? '#ffffff' : 'none'"
          :type="house"
          layer="underlay"
        />
        <roof-contour :fill="roofColor" :type="house" />

        <wall-contour
          :fill="wallColor != 'none' ? '#ffffff' : 'none'"
          :type="house"
          layer="underlay"
        />
        <wall-contour :fill="wallColor" :type="house" />

        <r-w-s-contour
          contourClass="underlay"
          :fill="rwsColor != 'none' ? '#ffffff' : 'none'"
          :type="house"
        />
        <r-w-s-contour :fill="rwsColor" :type="house" />
      </div>
      <img
        v-if="includePanel && showRoof"
        :src="'/img/house/' + house + '/SP.png'"
        class="roof-layer"
        @load="loading = false"
      />
    </div>

    <div v-if="!optionsHidden">
      <div v-if="currentOption == 0" class="option-carousel">
        <div
          v-for="(type, i) in houseTypes"
          :key="i"
          class="visual-editor-option"
        >
          <img
            :src="'/img/house/' + type + '/thumb.png'"
            @click="setHouse(i)"
            v-bind:class="{ 'active-option': type == house }"
            v-cloak
          />
        </div>
      </div>
      <div v-else-if="currentOption == 1" class="option-carousel">
        <div
          v-for="(type, i) in roofTypes"
          :key="i"
          class="visual-editor-option"
        >
          <div
            class="generic-picker"
            @click="setRoofType(i)"
            v-bind:class="{ 'active-option': roofType == type.url }"
          >
            <img
              style="filter: grayscale(100%)"
              :src="'/img/roofTypes/' + type.name + '.jpg'"
            />
          </div>
        </div>
      </div>
      <div v-else-if="currentOption == 2" class="option-carousel">
        <div
          v-for="(color, i) in colorMap"
          :key="i"
          class="visual-editor-option"
        >
          <div
            :style="{ 'background-color': color.color }"
            class="color-picker"
            @click="setRoofColor(i)"
            v-bind:class="{ 'active-option': roofColor == color.color }"
          />
        </div>
      </div>
      <div v-else-if="currentOption == 3" class="option-carousel">
        <div
          v-for="(color, i) in colorMap"
          :key="i"
          class="visual-editor-option"
        >
          <div
            :style="{ 'background-color': color.color }"
            class="color-picker"
            @click="setRWSColor(i)"
            v-bind:class="{ 'active-option': rwsColor == color.color }"
          />
        </div>
      </div>
      <div v-else-if="currentOption == 4" class="option-carousel">
        <div
          v-for="(color, i) in colorMapWall"
          :key="i"
          class="visual-editor-option"
        >
          <div
            :style="{ 'background-color': color.color }"
            class="color-picker"
            @click="setWallColor(i)"
            v-bind:class="{ 'active-option': wallColor == color.color }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RoofContour from "./svg/RoofContour.vue";
import RWSContour from "./svg/RWSContour.vue";
import WallContour from "./svg/WallContour.vue";

export default {
  components: { RoofContour, RWSContour, WallContour },
  name: "House",
  props: ["optionsHidden"],
  data() {
    return {
      showHouse: false,
      showRoof: false,
      loading: true,
    };
  },
  created() {
    this.loading = true;
  },
  computed: {
    ...mapGetters('solarPanelCalculatorModule', [
        'includePanel',
    ]),
    ...mapGetters('houseBuilderModule', [
      "colorMapWall",
      "colorMap",
      "roofTypes",
      "currentOption",
      "roofColor",
      "roofType",
      "rwsColor",
      "wallColor",
      "house",
      "houseTypes",
    ]),
    solarShadow() {
      if (this.includePanel) {
        return "_SP";
      }
      return "";
    },
    isFirefox() {
      let f = navigator.userAgent.search("Firefox");
      if (f > -1) {
        return true;
      }
      return false;
    },
  },
  emits: ["loadingFinished"],
  methods: {
    ...mapActions('houseBuilderModule', [
      "setRoofColor",
      "setRoofType",
      "setRWSColor",
      "setWallColor",
      "setHouse",
    ]),
  },
  watch: {
    house: function (o, n) {
      if (n != o) {
        this.showRoof = false;
        this.loading = true;
      }
    },
    includePanel: function (o) {
      if (o == true) {
        this.loading = true;
      }
    },
  },
};
</script>
<style lang="scss">
.visual-editor-option {
  width: 130px;
  margin-right: 12px;
  cursor: pointer;
}

.generic-picker {
  width: 130px;
  height: 80px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  img {
    max-height: 100%;
  }
}

.option-carousel {
  width: 100%;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  display: flex;
  overflow-x: auto;
  z-index: 30;
  padding: 20px;
  align-items: center;
  overflow-y: hidden;
  border-radius: 0 0 4px 4px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.options-row {
  flex-wrap: wrap;
}

.roof-layer {
  z-index: 12;
  top: 0;
  left: 0;
  position: absolute;
}

.loading-screen {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 30;
}

.loading {
  position: absolute;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 30;
  .lds-ring {
    display: flex;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.image-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
//   position: relative;
  img {
    max-width: 100%;
    border-radius: 4px;
  }
}

.colors {
  svg {
    top: 0;
    position: absolute;
    width: 100%;
    height: auto;
    z-index: 12;
    mix-blend-mode: soft-light;

    &.rws {
      mix-blend-mode: overlay;
    }
  }
}
.breakdown-wrapper {
  .no-svg {
    svg {
      display: none !important;
    }
  }
}

.color-picker {
  width: 72px;
  height: 80px;
  border-radius: 4px;
}

.active-option {
  border: 4px solid #ffffff;
  &.generic-picker {
    border-color: #2f3192;
  }
}
</style>
