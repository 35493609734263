<template>
<default-layout>
    <router-view :key="$route.fullPath" />
</default-layout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout"

export default {
    metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Default Title',
      // all titles will be injected into this template
      titleTemplate: '%s | My Awesome Webapp'
    },
    components: {
        DefaultLayout
    }
}
</script>
<style>
#app {
  font-family: Roboto !important;
  text-align: center;
  color: #474747;
  overflow-x: hidden;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #474747;
}

#nav a.router-link-exact-active {
  color: #474747;
}
</style>
